<template>
  <div>
    <div ref="addBtn" v-if="deferredPrompt" class="alert-i-modal">
      <div class="d-flex">
        <p class="mr-15 flex-fill text-truncate">Install Digital Dry Cleaning App</p>
        <button @click="clickCallback">Install</button>
        <button @click="closeModal" class="btn-close"><i class="fa fa-times"></i> </button>
      </div>
    </div>
    <PWAPrompt :delay="1500" :permanentlyHideOnDismiss="false"></PWAPrompt>
  </div>

</template>

<script>
//https://github.com/acepoblete/vue2-ios-pwa-prompt
import PWAPrompt from "vue2-ios-pwa-prompt";
export default {
  name: "InstallPopUp",
  components:{
    PWAPrompt,
  },
  data: () => ({
    deferredPrompt: null,
    show_dialog:true,
    isIOSDevice:false,
  }),
  computed:{

  },
  mounted() {


  },
  methods: {
    checkToken(){
      const user = JSON.parse(localStorage.getItem("user"));

      return ((user !== null) ? (Object.keys(user).length) : false);
    },
    iOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    captureEvent() {
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
      if (this.iOS() && !isInStandaloneMode()){
        this.isIOSDevice = true;
        this.show_dialog = true;

      }
      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('beforeinstallprompt');
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.

        this.deferredPrompt = e
      })
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Call another function?
        }
        this.show_dialog = false;
        this.deferredPrompt = null
      })
    },
    closeModal(){
      this.show_dialog = false;
    },
  },
}
</script>

