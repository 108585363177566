<template>
  <div class="blogs">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Blog</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li><router-link to="/blog">Blog</router-link></li>
                <li>{{formatted_slug}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Blog Section Start -->
    <div v-if="blog_content._content_loaded" class="blog-section section pb-60 pt-55">
      <div class="container">
        <div class="row no-gutters">

          <div class="col-lg-8">
            <div class="blog-wrapper">
              <div class="row">

                <div v-for="(blog, idx) in blog_content._content" class="col-12">
                  <!-- Single Blog Start -->
                  <div class="single-blog-post mb-90 mb-xs-50">
                    <div class="blog-image">
                      <router-link :to="'/blog/detail/' + blog.bc_slug">
                        <img :src="blog.bc_image" :alt="blog.bc_title">
                      </router-link>
                    </div>
                    <div class="blog-content">
                      <ul class="post-meta">
                        <li><i class="lnr lnr-user"></i><a href="#">By Admin</a></li>
                        <li><i class="lnr lnr-tag"></i><router-link :to="'/blog/category/' + blog.cat_slug" v-html="blog.cat_name"></router-link></li>
                        <li><i class="lnr lnr-clock"></i><router-link :to="'/blog/archive/' + blog.blog_date_slug" v-html="blog.blog_date"></router-link></li>
                      </ul>
                      <h4 class="title">
                        <router-link :to="'/blog/detail/' + blog.bc_slug" v-html="blog.bc_title"></router-link>
                      </h4>
                      <p class="blog-desc" v-html="blog.bc_detail">

                      </p>
                      <router-link :to="'/blog/detail/' + blog.bc_slug" class="ht-btn theme-btn theme-btn-two">View more <i class="lnr lnr-chevron-right"></i></router-link>
                    </div>
                  </div>
                  <!-- Single Blog End -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4" id="sticky-sidebar">
            <div class="sidebar-wrapper">
              <div class="common-sidebar-widget d-none">
                <div class="sidebar-search-form">
                  <form action="#">
                    <input type="text" name="search" placeholder="Search...">
                    <button type="submit" class="ht-btn search-btn"><i class="fa fa-search"></i></button>
                  </form>
                </div>
              </div>
              <div v-if="blog_category._content_loaded" class="common-sidebar-widget">
                <h2 class="sidebar-title">Categories</h2>
                <ul class="sidebar-list">
                  <li v-for="(category, idx) in blog_category._list"><router-link :to="'/blog/category/' + category.bc_slug" v-html="category.bc_title + ' ('+category.tot_blog_post+')'"></router-link></li>

                </ul>
              </div>
              <div v-if="blog_recent_post._content_loaded" class="common-sidebar-widget">
                <h2 class="sidebar-title">Recent Post</h2>
                <div v-for="(item, idx) in blog_recent_post._list" :class="'sidebar-blog'  + ((idx == 0) ? ' pt-0' : '')">
                  <router-link :to="'/blog/detail/' + item.bc_slug" class="image"><img :src="item.bc_image" :alt="item.bc_title"></router-link>
                  <div class="content">
                    <span v-html="item.blog_date"></span>
                    <h5><router-link :to="'/blog/detail/' + item.bc_slug" v-html="item.bc_title"></router-link></h5>
                  </div>
                </div>
              </div>

              <div v-if="blog_archive._content_loaded" class="common-sidebar-widget">
                <h2 class="sidebar-title">Archives</h2>
                <ul class="sidebar-list">
                  <li v-for="(item, idx) in blog_archive._list"><router-link :to="'/blog/archive/' + item.blog_date_slug" v-html="item.blog_date"></router-link></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Blog Section End -->
  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "BlogCategory",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      cat_slug:"",
      formatted_slug :"",
      page_content:{
        category_blog:{},
        category_menu:{},
        recent_blog_menu:{},
        archive_menu:{},
      },
      blog_content:{
        _content_loaded: false,
        _content:[],
      },
      blog_category:{
        _content_loaded : false,
        _list:[]
      },
      blog_archive:{
        _content_loaded : false,
        _list:[],
      },
      blog_recent_post:{
        _content_loaded : false,
        _list: [],
      },
      web_page_head:{
        page_title:"Blog",
        meta_title:"Blog",
        meta_description:"Blog"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  created() {

  },
  computed:{

  },
  methods:{
    doAjax(catSlug){
      UserService.getBlogByCategorySlug(catSlug).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.blog_content !== 'undefined'){
                this.blog_content._content_loaded = true;
                this.blog_content._content = jsonResponse.data.blog_content;
              }
              if (typeof jsonResponse.data.blog_category !== 'undefined'){
                this.blog_category._content_loaded = true;
                this.blog_category._list = jsonResponse.data.blog_category;
                for (let k in this.blog_category._list){
                  if (this.blog_category._list[k].bc_slug == catSlug){
                    this.formatted_slug = this.blog_category._list[k].bc_title;
                  }
                }
              }
              if (typeof jsonResponse.data.blog_archive !== 'undefined'){
                this.blog_archive._content_loaded = true;
                this.blog_archive._list = jsonResponse.data.blog_archive;
              }
              if (typeof jsonResponse.data.blog_recent_post !== 'undefined'){
                this.blog_recent_post._content_loaded = true;
                this.blog_recent_post._list = jsonResponse.data.blog_recent_post;
              }


              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.cat_slug = this.$route.params.category_slug;
    this.doAjax(this.cat_slug);
  },
  watch: {
    '$route.params.category_slug': function(newValue, oldValue){
      if (newValue != oldValue){
        this.doAjax(newValue);
      }
    },
  }
}
</script>

<style scoped>

</style>