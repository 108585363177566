<template>
  <div id="main-wrapper">
    <div v-if="app_page_loader" class="loader d-flex justify-content-center align-content-center">
      <div class="d-flex justify-content-center align-content-center">
        <div class="d-flex align-items-center"><clip-loader :loading="app_page_loader" :color="color" :size="size"></clip-loader></div>
      </div>
    </div>
    <div :class="((app_page_loader) ? 'd-none' : 'd-block')">
      <offer-banner :offer-banner-data="offer_banner" v-if="checkOfferBannerAvailable(offer_banner)"></offer-banner>
      <Header :header_web_settings="website_defaults" v-if="header_footer"></Header>
      <router-view/>
      <Footer :footer_web_settings="website_defaults" :footer_social_link="web_social_links" v-if="header_footer"></Footer>
    </div>
    <install-pop-up></install-pop-up>
    <vue-cookie-accept-decline v-if="!app_page_loader"
                               ref="ddcCookie"
                               @status="cookieStatus"
                               elementId="ddcCookie" type="bar" position="bottom"
                               :showPostponeButton="false"
                               :disableDecline="true"
                               :forceCookies="true"
                               @clicked-accept="cookieClickedAccept"
    >
      <template #message>
        We use cookies to ensure you get the best experience on our website. To find out more, read our policy
        <a href="https://digitaldrycleaners.co.uk/#/privacy-policy" target="_blank">here</a>.
      </template>


      <template #acceptContent><i class="fa fa-check"></i> Accept & Close</template>
    </vue-cookie-accept-decline>
  </div>
</template>
<script>
import User from '@/models/user';
import UserService from "@/services/user.service";
import ClipLoader from "vue-spinner/src/ClipLoader"
import OfferBanner from "@/components/OfferBanner";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import EventBus from "@/common/EventBus";
import InstallPopUp from "@/components/InstallPopUp";
export default {
  name: "Layout",
  components:{
    OfferBanner,
    Header,
    Footer,
    ClipLoader,
    InstallPopUp
  },
  data(){
    return{
      _cookie_status:null,
      layout_page_loader:true,
      loading:true,
      color:"#000000",
      size:"36px",
      website_defaults:{
        company_name:"",
        map_embed_code:"",
        email:"",
        address:"",
        phone:"",
        mobile:"",
        website:"",
      },
      user: new User('', ''),
      web_social_links:[],
      message:"",
      offer_banner:{},
      header_footer:false,
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
    if (!this.loggedIn){
      //this.handleLogin();
    }else{
      this.getWebInfo();
    }

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      //this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch('auth/device', this.user).then(
          (response) => {
            setTimeout(() => {
              console.log('response auth/device', response);
              //window.location.href = '/';
            }, 500);


          },
          error => {
            this.loading = false;
            this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );

    },
    getWebInfo(){
      UserService.getWebsiteInformation().then(
          (r) => {
            let jsonResponse = r.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.web_settings !== 'undefined'){
                this.website_defaults = jsonResponse.data.web_settings;
              }
              if (typeof jsonResponse.data.social_links !== 'undefined'){
                this.web_social_links = jsonResponse.data.social_links;
              }
              if (typeof jsonResponse.data.offer_banner !== 'undefined'){
                this.offer_banner = jsonResponse.data.offer_banner;
              }
            }
          },
          (e) => {

          }
      );
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      //this.$router.push('/login');
    },
    checkOfferBannerAvailable(obj){
      let isShow = false;
      if (typeof obj !== 'undefined'){
        if (Object.keys(obj).length){
          isShow = true;
        }
      }
      return isShow;
    },
    cookieStatus(status){
      //this.$refs.myPanel1.init();
      this.$refs.ddcCookie.removeCookie();
    },
    cookieRemovedCookie () {
      this.status = null;
      this.$refs.ddcCookie.init();
    },
    cookieClickedAccept () {
      this._cookie_status = 'accept';
    },
    cookieClickedPostpone () {
      this._cookie_status = 'postpone';
    },
    showHeaderFooter(){
      setTimeout(()=>{
        this.header_footer = true;
      }, 600);
    },
  },
  watch:{
    '$route' (to, from){
        this.app_page_loader = true;
        this.header_footer = false;
    },
    'app_page_loader':function(){
      if (!this.app_page_loader){
        this.showHeaderFooter();
      }
    }
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
  beforeRouteLeave(to, from, next) {

// called when the route that renders this component is about to
// be navigated away from.
// has access to `this` component instance.
  }
}
</script>