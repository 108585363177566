<template>
  <div id="app">
    <div>
      <div v-if="$data._current_page_loader" class="loader d-flex justify-content-center align-content-center">
        <div class="d-flex justify-content-center align-content-center">
          <div class="d-flex align-items-center"><clip-loader :loading="app_page_loader" :color="color" :size="size"></clip-loader></div>
        </div>
      </div>
      <layout v-if="!$data._current_page_loader"></layout>
    </div>

  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader"
import Layout from "@/components/Layout";
import EventBus from "./common/EventBus";
import User from "@/models/user";

export default {
  components:{
    Layout,
    ClipLoader,
  },
  data(){
    return{
      _current_page_loader:true,
      color:"#000000",
      size:"36px",
      user: new User('', ''),
    }
  },
  created() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      //this.$router.push('/login');
    },
    handleLogin() {
      this.$store.dispatch('auth/device', this.user).then(
          (response) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
              this.$data._current_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          error => {
            this.loading = false;
            this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
    if (!this.loggedIn){
      this.handleLogin();
    }else{
      setTimeout(() => {
        this.$parent.app_page_loader = false;
        this.$data._current_page_loader = false;
      }, process.env.VUE_APP_LOADER_HIDE_DELAY);
    }

  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
};
</script>