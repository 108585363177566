<template>
  <div class="blogs">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Blog</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li><router-link to="/blog">Blog</router-link></li>
                <li>{{formatted_slug}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Blog Section Start -->
    <div v-if="blog_content._content_loaded" class="blog-section section pb-60 pt-55">
      <div class="container">
        <div class="row">

          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="row">

              <div class="col-12">
                <!-- Single Blog Start -->
                <div class="single-blog-post mb-25 mb-xs-25 text-center">
                  <div class="blog-image text-center">
                    <img :src="blog_content._content.bc_image" :alt="blog_content._content.bc_title">
                  </div>
                  <div class="blog-content">
                    <ul class="post-meta">
                      <li><i class="lnr lnr-user"></i><a href="#">By Admin</a></li>
                      <li><i class="lnr lnr-tag"></i><router-link :to="'/blog/category/' + blog_content._content.cat_slug" v-html="blog_content._content.cat_name"></router-link></li>
                      <li><i class="lnr lnr-clock"></i><router-link :to="'/blog/archive/' + blog_content._content.blog_date_slug" v-html="blog_content._content.blog_date"> </router-link></li>
                    </ul>
                    <p v-html="blog_content._content.bc_detail"></p>
                  </div>

                  <div v-for="(item) in blog_content._content.multi_pera" class="mt-25">
                    <div v-if="item.bc_image != ''" class="blog-image text-center">
                      <img :src="item.bc_image" :alt="item.bc_title">
                    </div>
                    <h3 v-if="item.bc_title != ''" v-html="item.bc_title"></h3>
                    <p v-if="item.bc_detail != ''" v-html="item.bc_detail"></p>
                  </div>
                </div>
                <!-- Single Blog End -->
              </div>
              <div v-if="blog_related._content_loaded" class="col-12">
                <div class="related-post mt-25">
                  <h3 class="title">Related Post</h3>
                  <div v-if="blog_related._list.length > 1" class="row">
                    <div v-for="(item) in blog_related._list" class="col-md-6">
                      <!-- Single Blog Start -->
                      <div class="single-blog-post mb-30">
                        <div class="blog-image mb-15">
                          <a href="#">
                            <img :src="item.bc_image" :alt="item.bc_title">
                          </a>
                        </div>
                        <div class="blog-content">
                          <ul class="post-meta mb-15">
                            <li><i class="lnr lnr-user"></i><a href="#">By Admin</a></li>
                            <li><i class="lnr lnr-tag"></i><router-link :to="'/blog/category/' + item.cat_slug" v-html="item.cat_name"></router-link></li>
                            <li><i class="lnr lnr-clock"></i><router-link :to="'/blog/archive/' + item.blog_date_slug" v-html="item.blog_date"></router-link></li>
                          </ul>
                          <h5 class="title-two">
                            <router-link :to="'/blog/detail/' + item.bc_slug" v-html="item.bc_title"></router-link>
                          </h5>
                        </div>
                      </div>
                      <!-- Single Blog End -->
                    </div>
                  </div>
                  <div v-if="blog_related._list.length == 1" class="row">
                    <div v-for="(item) in blog_related._list" class="col-md-6 offset-md-3">
                      <!-- Single Blog Start -->
                      <div class="single-blog-post mb-30">
                        <div class="blog-image mb-15">
                          <a href="#">
                            <img :src="item.bc_image" :alt="item.bc_title">
                          </a>
                        </div>
                        <div class="blog-content">
                          <ul class="post-meta mb-15">
                            <li><i class="lnr lnr-user"></i><a href="#">By Admin</a></li>
                            <li><i class="lnr lnr-tag"></i><router-link :to="'/blog/category/' + item.cat_slug" v-html="item.cat_name"></router-link></li>
                            <li><i class="lnr lnr-clock"></i><router-link :to="'/blog/archive/' + item.blog_date_slug" v-html="item.blog_date"></router-link></li>
                          </ul>
                          <h5 class="title-two">
                            <router-link :to="'/blog/detail/' + item.bc_slug" v-html="item.bc_title"></router-link>
                          </h5>
                        </div>
                      </div>
                      <!-- Single Blog End -->
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Blog Section End -->
  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "BlogDetail",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      cat_slug:"",
      formatted_slug :"",
      blog_content:{
        _content_loaded: false,
        _content:{multi_pera:[]},
      },
      blog_related:{
        _content_loaded: false,
        _list:[],
      },
      web_page_head:{
        page_title:"Blog",
        meta_title:"Blog",
        meta_description:"Blog"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  created() {

  },
  computed:{

  },
  methods:{
    doAjax(catSlug){
      UserService.getBlogDetailBySlug(catSlug).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.blog_content_detail !== 'undefined'){
                this.blog_content._content_loaded = true;
                this.blog_content._content = jsonResponse.data.blog_content_detail;
                this.formatted_slug = this.blog_content._content.bc_title;
              }
              if (typeof jsonResponse.data.blog_related !== 'undefined'){
                this.blog_related._list = jsonResponse.data.blog_related;
                if (jsonResponse.data.blog_related.length){
                  this.blog_related._content_loaded = true;
                }
              }
              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.cat_slug = this.$route.params.blog_slug;
    this.doAjax(this.cat_slug);
  },
  watch: {
    '$route.params.category_slug': function(newValue, oldValue){
      if (newValue != oldValue){
        this.doAjax(newValue);
      }
    },
  }
}
</script>

<style scoped>

</style>