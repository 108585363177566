<template>
  <div class="about">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Sitemap</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>Sitemap</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-content-section section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <ul class="sitemap">
              <li><router-link to="/">Home</router-link> </li>
            </ul>
            <ul class="sitemap">
              <li><a href="#">Web Content</a>
                <ul>
                  <li><router-link to="/">Order</router-link> </li>
                  <li><router-link to="/about-us">About</router-link> </li>
                  <li><router-link to="/pricing">Pricing</router-link> </li>
                  <li><router-link to="/blog">Blog</router-link> </li>
                  <li><router-link to="/disclaimer">Disclaimer</router-link> </li>
                  <li><router-link to="/privacy-policy">Privacy Policy</router-link> </li>
                  <li><router-link to="/best-dry-cleaning-london">Best Dry Cleaning In London</router-link></li>
                  <li><router-link to="/contact">Contact</router-link></li>
                </ul>
              </li>
            </ul>
          </div>

          <div v-if="list_of_services._content_loaded" class="col-lg-4">
            <ul class="sitemap">
              <li><a href="#">Services</a>
              <ul>
                <li  v-for="(item) in list_of_services._list"><router-link :to="'/service/' + item.srv_slug" v-html="item.srv_title"></router-link></li>
              </ul>
              </li>

            </ul>
          </div>
          <div v-if="list_of_areas._content_loaded" class="col-lg-4">
            <ul class="sitemap">
              <li><router-link to="">Areas</router-link>
                <ul>
                  <li  v-for="(item) in list_of_areas._list"><router-link :to="'/area/' + item.area_slug" v-html="item.area_name"></router-link></li>
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "Sitemap",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      web_page_head:{
        page_title:"Sitemap",
        meta_title:"Sitemap",
        meta_description:"Sitemap"
      },
      list_of_services:{
        _content_loaded:  false,
        _list:[]
      },
      list_of_areas:{
        _content_loaded:false,
        _list: [],
      }
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getSitemap().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }

              if (typeof jsonResponse.data.services !== 'undefined'){
                if (typeof jsonResponse.data.services.services_list !== 'undefined'){
                  this.list_of_services._list = jsonResponse.data.services.services_list;
                  if (this.list_of_services._list.length){
                    this.list_of_services._content_loaded = true;
                  }
                }
              }
              if (typeof jsonResponse.data.areas !== 'undefined'){
                this.list_of_areas._list = jsonResponse.data.areas;
                if (this.list_of_areas._list.length){
                  this.list_of_areas._content_loaded = true;
                }
              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>