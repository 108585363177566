<template>
  <div class="my-orders">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">My Orders</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>My Orders</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user_orders._order_loaded" class="orders-section section pt-55 pb-50">
      <div class="container">
      <div class="table-responsive">
        <table class="table table-striped table-my-orders">
          <thead class="thead-dark">
          <tr>
            <th>S.No</th>
            <th>Order No</th>
            <th style="width: 340px;">Address</th>
            <th style="width: 170px;">Pick Up</th>
            <th style="width: 170px;">Drop Off</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in user_orders._orders">
            <td>{{idx + 1}}</td>
            <td>{{item.order_no}}</td>
            <td>{{item.order_address}}<span class="text-uppercase">, {{item.order_address_descp}}</span></td>
            <td>{{item.pick_up}}</td>
            <td>{{item.drop_off}}</td>
            <td class="text-center"><span :class="'badge ' + getBadgeClass(item.order_status)">{{item.order_status_text}}</span></td>
            <td class="text-center"><router-link :to="'/my-orders/order/' + item.order_no" class="ht-btn">View Order</router-link></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import TokenService from "@/services/token.service";
import UserService from "@/services/user.service";
export default {
  name: "MyOrders",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      user_orders:{
        _order_loaded: false,
        _orders: [],
      },
      web_page_head:{
        page_title:"My Orders",
        meta_title:"My Orders",
        meta_description:"My Orders"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (Object.keys(localUser).length){
        if (Object.keys(localUser.order).length && Object.keys(localUser.user)){
          /*let order = localUser.order;
          let user = localUser.user;
          this.order_content._content_loaded = true;
          this.order_content._content.order_message = "Thanks "+user.first_name + " " + user.last_name+". Your order has been placed.";*/
        }
      }
    }
  },
  methods:{
    doAjax(){
      UserService.getMyOrders().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.orders !== 'undefined'){
                this.user_orders._order_loaded = true;
                this.user_orders._orders = jsonResponse.data.orders;

              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    getBadgeClass(orderStatus){
      let badgeClass = 'badge-info';
      switch (parseInt(orderStatus)){
        case 1: case 2:
          badgeClass = 'badge-danger';
          break;
        case 3:
          badgeClass = 'badge-info'
          break
        default:
          badgeClass = 'badge-info';
          break;
      }
      return badgeClass;

    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>
<style scoped>

</style>