import axiosInstance from "@/services/api";
import TokenService from "@/services/token.service";
let refreshRequestCount = 0;
const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                 config.headers["Accesstoken"] = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            refreshRequestCount++;
            if (typeof originalConfig !== 'undefined'){
                if (originalConfig.url !== "/auth/signin" && err.response) {
                    // Access Token was expired
                    originalConfig._retry = (refreshRequestCount > 0);
                    localStorage.removeItem("user");
                    //window.location.href = "/?u=e";
                    let refresh_token = TokenService.getLocalRefreshToken();
                    if (err.response.status === 401 && !originalConfig._retry && refresh_token !== null) {
                        try {
                            const rs = await axiosInstance.post("/auth/refresh-token", {
                                Refreshtoken: refresh_token,
                            });
                            let jsonResponse = rs.data;
                            if (jsonResponse.status){
                                const { accessToken } = jsonResponse.data;
                                store.dispatch('auth/refreshToken', accessToken);
                                TokenService.updateLocalAccessToken(accessToken);
                                window.location.href = "/";
                            }else{
                                window.location.href = "/";
                            }
                            return axiosInstance(originalConfig);
                        } catch (_error) {
                            localStorage.removeItem("user");
                            //window.location.href = "/?v=401";
                            return Promise.reject(_error);
                        }
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;