<template>
<div>
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase"> Contact</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="contact_content._content_loaded" class="contact-section section pt-55 pb-60">
    <div class="container contact-wrapper pt-0 pb-0">
      <div class="row row-30">
        <div class="col-lg-5">
          <div class="contact-info mb-0">
            <h2 class="title text-uppercase">Contact info</h2>
            <div class="contact-information">
              <div class="info pb-10">
                <span class="ico"><i class="lnr lnr-map-marker"></i></span>
                <span class="text" v-html="contact_content._content_info.address"></span>
              </div>
              <div class="info pb-10">
                <span class="ico"><i class="lnr lnr-phone"></i></span>
                <span class="text phone" v-html="contact_content._content_info.phone"></span>
              </div>
              <div class="info pb-10">
                <span class="ico"><i class="lnr lnr-envelope"></i></span>
                <span class="text" v-html="contact_content._content_info.email"></span>
              </div>
              <div v-if="contact_content._content_link.length" class="info mt-5">
                <span class="ico"></span>
                <ul class="social-icon">
                  <li v-for="(item, idx) in contact_content._content_link">
                    <a :href="item.link" target="_blank" :class="item.class"><i :class="item.icon"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-xl-none d-lg-none d-md-none d-sm-none pt-30"></div>
        <div class="col-lg-7">
          <div class="contact-info mb-30">
            <h2 class="title text-uppercase">Location</h2>
            <div class="contact-map-area">
              <iframe :src="contact_content._content_info.map_embed_code" allowfullscreen="allowfullscreen" loading="lazy" class="contact-map"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "Contact",
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      contact_content:{
        _content_loaded: false,
        _content_info:{},
        _content_link:[],
      },
      web_page_head:{
        page_title:"Contact",
        meta_title:"Contact",
        meta_description:"Contact"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getContactUsContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.web_settings !== 'undefined'){
                this.contact_content._content_info = jsonResponse.data.web_settings;
                this.contact_content._content_loaded = true;
              }
              if (typeof jsonResponse.data.social_links !== 'undefined'){
                this.contact_content._content_link = jsonResponse.data.social_links;
                this.contact_content._content_loaded = true;
              }

            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );

    },

  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>