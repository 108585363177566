class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.Refreshtoken;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user === null || typeof user === 'undefined'){
            return '';
        }
        return user?.Accesstoken;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.Accesstoken = token;
        localStorage.setItem("user", JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }
    updateUserLocation(location){
        let user = JSON.parse(localStorage.getItem("user"));
        user.location = location;
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        let user = JSON.parse(localStorage.getItem("user"));
        user.user_id = 0;
        localStorage.setItem("user", JSON.stringify(user));
    }
}

export default new TokenService();