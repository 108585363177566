<template>
  <div class="about">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">All Areas</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>All Areas</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="all-areas-section section pt-55 pb-60">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <tabs :options="{ defaultTabHash: 'most&nbsp;popular' }" ref="myTabs">
              <tab v-for="(item, idx) in areas_content._areas" :key="idx" :ref="'refTab' + idx" :id="'tab' + idx" :name="idx">
                <div hidden class="section-title-two mt-30 mb-30 mb-xs-30 d-none">
                  <h3>{{idx}}</h3>
                </div>
                <div v-if="idx != 'All'" class="row">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                    <div class="rounded-circle">{{idx}}</div>
                  </div>
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-9">
                    <ul v-if="item.length">
                      <li v-for="(sub_item, i) in item">
                        <router-link :to="'/area/' + sub_item.area_slug" v-html="sub_item.area_name"></router-link>
                      </li>
                    </ul>
                    <div v-if="!item.length" class="d-flex align-items-center justify-content-center">
                      <p  class="text-center text-danger">No area added.</p>
                    </div>

                  </div>
                </div>
                <ul v-if="item.length && idx == 'All'">
                  <li v-for="(sub_item, i) in item">
                    <router-link :to="'/area/' + sub_item.area_slug" v-html="sub_item.area_name"></router-link>
                  </li>
                </ul>

                <p v-if="!item.length && idx == 'All'" class="text-center text-danger">No area added.</p>
              </tab>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import {Tabs, Tab} from 'vue-tabs-component';

export default {
  name: "AllAreas",
  components:{
    Tab,
    Tabs,
  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      areas_content:{
        _content_loaded: false,
        _areas:[],
      },
      tab_id:101,
      web_page_head:{
        page_title:"Areas",
        meta_title:"Areas",
        meta_description:"Areas"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getAllAreas().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.all_areas !== 'undefined'){
                this.areas_content._areas = jsonResponse.data.all_areas;
                this.areas_content._content_loaded = true;
                setTimeout(()=>{
                  let els = document.querySelectorAll("a[href='#tabAll']");
                  if (typeof els[0] !== 'undefined'){
                    els[0].click();
                  }
                }, parseInt(process.env.VUE_APP_LOADER_HIDE_DELAY) - 100);
              }
              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    setDynamicId(){
      let i = this.tab_id;

      i++;
      this.tab_id = i;
      return this.tab_id;
    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>