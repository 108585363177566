import api from "./api";
import TokenService from "./token.service";

class AuthService {

    device_login({ username, password }) {
        return api
            .post("/auth/device", {
                username,
                password
            })
            .then((response) => {
                if (response.data.status) {
                    TokenService.setUser(response.data.data);
                }
                return response.data;
            });
    }

    login({ username, password }) {
        return api
            .post("/user-login", {
                username,
                password
            })
            .then((response) => {
                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    register({ username, email, password }) {
        return api.post("/auth/signup", {
            username,
            email,
            password
        });
    }

}

export default new AuthService();