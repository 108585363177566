<template>
  <div class="position-relative">
    <!--Header section start-->
    <header class="header-absolute black-logo-version header-sticky sticky-white no-padding d-none d-lg-block pt-25 pb-25">
      <div class="main-header">
        <div class="container pl-15 pl-md-0 pr-15 pr-md-0">
          <div class="row align-items-center no-gutters">
            <!--Logo start-->
            <div class="col-xl-2 col-lg-2 col-12">
              <div class="logo pl-15 pr-15">
                <router-link to="/"><img src="../assets/img/logo-white.png" :alt="header_web_settings.company_name"></router-link>
              </div>
            </div>
            <!--Logo end-->

            <!--Menu start-->
            <div class="col-xl-7 col-lg-7 col-12">
              <nav class="main-menu padding-16">
                <ul>
                  <li><router-link to="/">Order</router-link></li>
                  <li><router-link to="/about-us">About</router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <li><router-link to="/pricing">Pricing</router-link></li>
                  <li><router-link to="/areas">Areas</router-link></li>
                  <li><router-link to="/blog">Blog</router-link></li>
                  <li><router-link to="/contact">Contact</router-link></li>
                </ul>
              </nav>
            </div>
            <!--Menu end-->

            <!-- Cart & Search Area Start -->
            <div class="col-xl-3 col-lg-3 col-12">
              <div class="header-btn-action d-flex justify-content-end">
                <div class="btn-action-wrap d-flex">
                  <div class="jp-author item mr-0">
                    <a :href="'tel:' + header_web_settings.mobile">
                      <i class="lnr lnr-smartphone"></i>
                      <span>{{header_web_settings.mobile}}</span>
                    </a>
                  </div>
                  <div v-if="!customer_logged_in" class="jp-author-action-two item">
                    <router-link class="ht-btn header-btn" to="/login"><i class="lnr lnr-user"></i> Login</router-link>
                  </div>
                  <div v-if="customer_logged_in" class="jp-author-action-two item user-dropdown position-relative">
                    <a class="ht-btn header-btn" href="#"><i class="lnr lnr-user"></i> My Account</a>
                    <ul>
                      <li><router-link to="/my-orders">Orders</router-link> </li>
                      <li><router-link to="/profile">Profile</router-link> </li>
                      <li><a @click="headerLogout" href="#">Logout</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- Cart & Search Area End -->
          </div>

        </div>
      </div>
    </header>
    <!--Header section end-->

    <!--Header Mobile section start-->
    <header class="header-mobile bg_color-grey d-block d-lg-none">
      <div class="header-bottom menu-right">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="header-mobile-navigation d-block d-lg-none">
                <div class="row align-items-center">
                  <div class="col-3 col-md-3">
                    <div class="mobile-navigation text-right">
                      <div class="header-icon-wrapper">
                        <ul class="icon-list justify-content-start">
                          <li class="popup-mobile-click">
                            <a href="https://api.whatsapp.com/send?l=en&amp;phone=+4407459807440&amp;text=Hi,%0a ..." class="header-whatsapp"></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6">
                    <div class="header-logo text-center">
                      <router-link to="/">
                        <img src="../assets/img/logo-white.png" style="max-height: 50px;" class="img-fluid" alt="">
                      </router-link>
                    </div>
                  </div>
                  <div class="col-3 col-md-3">
                    <div class="mobile-navigation text-right">
                      <div class="header-icon-wrapper">
                        <ul class="icon-list justify-content-end">
                          <li>
                            <div class="header-cart-icon">
                              <router-link v-if="!customer_logged_in" to="/login" class="header-user"><i class="lnr lnr-user"></i></router-link>
                              <router-link v-if="customer_logged_in" to="/my-orders" class="header-user"><i class="lnr lnr-user"></i></router-link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header>
    <!--Header Mobile section end-->

    <!-- Start Popup Menu -->
    <div class="popup-mobile-manu popup-mobile-visiable">
      <div class="inner">
        <div class="mobileheader">
          <div class="logo">
            <router-link to="/">
              <img src="../assets/img/logo-white.png" alt="Multipurpose">
            </router-link>
          </div>
          <a class="mobile-close" href="#"></a>
        </div>
        <div class="menu-content">
          <ul class="menulist object-custom-menu">
            <li class="has-mega-menu1"><a href="index.php"><span>Order</span></a></li>
            <li class="has-mega-menu1"><a href="about.php"><span>About</span></a></li>
            <li class="has-mega-menu1"><a href="services.php"><span>Services</span></a></li>
            <li class="has-mega-menu1"><a href="pricing.php"><span>Pricing</span></a></li>
            <li class="has-mega-menu1"><a href="contact.php"><span>Contact</span></a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Popup Menu -->

    <!-- Bottom Navbar Mobile Start -->
    <div class="bottom-navbar-mobile section d-block d-lg-none">
      <nav>
        <ul class="list-actions">
          <li>
            <router-link class="toggle-btn" to="/">
              <span><i class="lnr lnr-home"></i></span>
              <span class="text">Order</span>
            </router-link>
          </li>
          <li>
            <router-link class="toggle-btn toggle-btn-js" data-target="#job-list-mobile-id" to="/about-us">
              <span><i class="lnr lnr-apartment"></i></span>
              <span class="text">About</span>
            </router-link>
          </li>
          <li>
            <router-link class="toggle-btn toggle-btn-js" data-target="#job-list-mobile-id" to="/services">
              <span><i class="lnr lnr-shirt"></i></span>
              <span class="text">Services</span>
            </router-link>
          </li>
          <li>
            <router-link to="/pricing">
              <span><i class="lnr lnr-tag"></i></span>
              <span class="text">Pricing</span>
            </router-link>
          </li>
          <li>
            <router-link to="/contact">
              <span><i class="lnr lnr-phone"></i></span>
              <span class="text">Contact</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- Bottom Navbar Mobile End -->

    <!-- Bottom Navbar Mobile Popup Start -->
    <div class="mobile-popup">
      <div class="job-list-mobile" id="job-list-mobile-id">
        <div class="heading">
          <div class="title">
            <i class="lnr lnr-list"></i>
            <h3>All Service list</h3>
          </div>
          <a class="view-all" href="#">See all services</a>
        </div>
        <div class="content-popup-scroll">
          <ul class="list-item">
            <li><a href="#">ALTERATIONS & REPAIRS </a></li>
            <li><a href="#">CURTAIN CLEANING </a></li>
            <li><a href="#">DRY CLEANING </a></li>
            <li><a href="#">DUVET AND BED LINEN </a></li>
            <li><a href="#">HOUSE HOLD TEXTILES </a></li>
            <li><a href="#">IRONING </a></li>
            <li><a href="#">LAUNDRY SERVICES </a></li>
            <li><a href="#">LEATHER, FUR AND SUEDE </a></li>
            <li><a href="#">SHIRT SERVICE </a></li>
            <li><a href="#">SHOE REPAIRS </a></li>
            <li><a href="#"></a> SPECIALIST ITEMS</li>
            <li><a href="#"></a> TRAINER CLEANING</li>
            <li><a href="#"></a> WEDDING DRESSES</li>
          </ul>
        </div>
      </div>
      <div hidden class="notifications-mobile" id="notifications-mobile-id">
        <div class="heading">
          <div class="title">
            <i class="lnr lnr-list"></i>
            <h3>All Notifications</h3>
          </div>
          <a class="view-all" href="#">See all services</a>
        </div>
        <div class="content-popup-scroll">
          <ul class="list-item">
            <li><a href="#"><i class="lnr lnr-book"></i><span><b class="highlight">Register now</b> .</span> </a></li>
            <li><a href="#"><i class="lnr lnr-book"></i><span><b class="highlight">Services</b> you might be interested based on your profile.</span> </a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Bottom Navbar Mobile Popup End -->
  </div>
</template>

<script>
import TokenService from "@/services/token.service";
export default {
  name: "Header",
  props: {
    header_web_settings: Object,
  },
  data(){
    return{
      customer_logged_in:false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (parseInt(localUser.user_id) > 0 && Object.keys(localUser.user).length){
        this.customer_logged_in = true;
      }
    }
  },
  methods:{
    headerLogout(){
      this.$store.dispatch('auth/logout');
      window.location.href = "/";
    }
  },
  mounted() {

  },
}
</script>

<style scoped>

</style>