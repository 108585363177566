<template>
  <div class="about">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Best Dry Cleaning In London</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>Best Dry Cleaning In London</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="about-content-section section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h2 class="title">We are ready to collect in London</h2>
              <h3 class="title text-capitalize">Urgent Dry Cleaning Services In London</h3>
            </div>
            <div class="about-content">
              <p class="normal-text pb-15 text-left border-0">
                We provide free pick up and delivery for customers based in London and the Greater London Area! Our friendly and professional drivers are able to collect your dry cleaning the same day and have it ready for you within 24 hours. Our flexible drivers are able to collect your dry cleaning and laundry orders as early or as late as you prefer. We operate throughout the week and are able to collect Sunday - Monday without any hassle.
              </p>
              <h3 class="text-center">Cheapest Dry Cleaners In London</h3>
              <p>Our prices are unmatched and we are proud to be the cheapest on-demand dry cleaning service based in London. Our prices for dry cleaning in London start from only £2 and can vary depending on the clothing you would like to order. For more information about the exact pricing for each product, please check out our <router-link to="/pricing" class="font-weight-bold">dry cleaning price list.</router-link> </p>
              <h3 class="text-center">High-Quality Dry Cleaning In London</h3>
              <p>We have been working in London for over 5 years and are experts in providing high-quality dry cleaning services throughout various areas in London. Unlike other brands, we complete all services in-house which allows us to maintain a high standard of service for our customers. We have our dedicated cleaning centres whereas other online dry cleaning companies are simply middlemen working with local dry cleaning companies. </p>
              <h3 class="text-center">London Dry Cleaning Collection and Delivery Service</h3>
              <p>We operate in various areas around London and can be at your door very soon! Some popular areas where we are known for our dry cleaning services include Ealing, Wembley, Acton, Hammersmith, Fulham, Chelsea, Richmond Upon Thames, Sutton, Kingston, Hounslow, Merton and more! </p>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "BestDryCleaning",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:true,
        banner_image:"assets/img/london.jpg",
        banner_color:"",
      },
      about_content:{
        _content_loaded: true,
        _content:{content_multiple:[]},
      },
      web_page_head:{
        page_title:"Best Dry Cleaning",
        meta_title:"Best Dry Cleaning",
        meta_description:"Best Dry Cleaning"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getAboutContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
              }
              if (typeof jsonResponse.data.about_content !== 'undefined'){
                this.about_content._content = jsonResponse.data.about_content;
                this.about_content._content_loaded = true;
              }

            }
          },
          (error) => {}
      );
    },
  },
  mounted() {

    setTimeout(() => {
      this.$parent.app_page_loader = false;
    }, process.env.VUE_APP_LOADER_HIDE_DELAY);
  }
}
</script>

<style scoped>

</style>