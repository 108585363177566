<template>
<div class="customer-profile">
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase">My Account</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Account Setting</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="profile-section section pt-55 pb-60">
    <form>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
                <div class="section-title-two mb-45 mb-xs-30">
                  <h3 class="title">Profile</h3>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>First Name<span class="required">*</span></label>
                      <input type="text" v-model="form_profile.first_name._value" placeholder="First Name" name="first_name">
                      <span class="required-message" v-if="form_profile.first_name._validation_show && !form_profile.first_name._validate && form_profile._validation_show" v-html="form_profile.first_name._message"></span>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>Last Name<span class="required">*</span></label>
                      <input type="text" v-model="form_profile.last_name._value" placeholder="Last Name" name="last_name">
                      <span class="required-message" v-if="form_profile.last_name._validation_show  && !form_profile.last_name._validate && form_profile._validation_show" v-html="form_profile.last_name._message"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>Email<span class="required">*</span></label>
                      <input type="text" v-model="form_profile.email_address._value" readonly="readonly" placeholder="Email Address" name="email_address" style="background: rgb(241, 241, 241);">
                      <span class="required-message"></span>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>Mobile No<span class="required">*</span></label>
                      <input type="text" v-model="form_profile.mobile_no._value" placeholder="Mobile No" name="mobile_no">
                      <span class="required-message" v-if="form_profile.mobile_no._validation_show && !form_profile.mobile_no._validate && form_profile._validation_show" v-html="form_profile.mobile_no._message"></span>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-sm-12 col-md-12 col-xl-12 mt-15">
                    <div class="col-sm-4 offset-sm-4">
                      <button type="button" v-if="form_profile.button_submit._visible && !form_profile.button_loader._visible" @click="updateCustomerProfile" :class="'ht-btn fz-18' + ((form_profile.button_submit._disabled) ? ' disabled' : '')" :disabled="form_profile.button_submit._disabled" v-html="form_profile.button_submit._text"></button>
                      <button type="button" v-if="!form_profile.button_submit._visible && form_profile.button_loader._visible" :class="'ht-btn fz-18' + ((form_profile.button_loader._disabled) ? ' disabled' : '')" :disabled="form_profile.button_loader._disabled" v-html="form_profile.button_loader._text"></button>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="form_profile.alert_message._show">
                  <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2">
                    <div role="alert" :class="'alert col-md-12 mt-15 ' + ((form_profile.alert_message._type != '') ? form_profile.alert_message._type : 'alert-warning')" v-html="form_profile.alert_message._text"></div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-10 col-xl-10 offset-md-1 offset-xl-1 mt-30 mb-30">
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
                <div class="section-title-two mb-45 mb-xs-30">
                  <h3 class="title">Change Password</h3>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15 offset-sm-3 offset-md-3 offset-xl-3">
                    <div class="single-input">
                      <label>Old Password<span class="required">*</span></label>
                      <input type="password" v-model="form_change_password.old_password._value" placeholder="Old Password" name="name">
                      <span class="required-message" v-if="form_change_password.old_password._validation_show && !form_change_password.old_password._validate && form_change_password._validation_show" v-html="form_change_password.old_password._message"></span>
                      <span class="required-message"></span>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>New Password<span class="required">*</span></label>
                      <input type="password" v-model="form_change_password.new_password._value" placeholder="New password" name="name">
                      <span class="required-message" v-if="form_change_password.new_password._validation_show && !form_change_password.new_password._validate && form_change_password._validation_show" v-html="form_change_password.new_password._message"></span>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                    <div class="single-input">
                      <label>New Password Confirmation<span class="required">*</span></label>
                      <input type="password" v-model="form_change_password.confirm_password._value" placeholder="New Password Confirmation" name="name">
                      <span class="required-message" v-if="form_change_password.confirm_password._validation_show && !form_change_password.confirm_password._validate && form_change_password._validation_show" v-html="form_change_password.confirm_password._message"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-xl-12 mt-15">
                    <div class="col-sm-4 offset-sm-4">
                      <button type="button" v-if="form_change_password.button_submit._visible && !form_change_password.button_loader._visible" @click="updateCustomerPassword" :class="'ht-btn fz-18' + ((form_change_password.button_submit._disabled) ? ' disabled' : '')" :disabled="form_change_password.button_submit._disabled" v-html="form_change_password.button_submit._text"></button>
                      <button type="button" v-if="!form_change_password.button_submit._visible && form_change_password.button_loader._visible" :class="'ht-btn fz-18' + ((form_change_password.button_loader._disabled) ? ' disabled' : '')" :disabled="form_change_password.button_loader._disabled" v-html="form_change_password.button_loader._text"></button>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="form_change_password.alert_message._show">
                  <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2">
                    <div role="alert" :class="'alert col-md-12 mt-15 ' + ((form_change_password.alert_message._type != '') ? form_change_password.alert_message._type : 'alert-warning')" v-html="form_change_password.alert_message._text"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-10 col-xl-10 offset-md-1 offset-xl-1 mt-30 mb-30">
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
                <div class="section-title-two mb-45 mb-xs-30">
                  <h3 class="title">Payment Card</h3>
                </div>
                <div class="row">
                  <div v-if="!customer_credit_card_change && customer_card_number != ''" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-1">
                    <div class="single-input">
                      <label>CREDIT OR DEBIT CARD <span class="required">*</span><br/>
                        <small>(We won’t charge you until after delivery)</small></label>
                      <div class="clearfix"></div>
                      <div class="invisible-card" @click="showStripeCardForm" >
                        <span>****</span>
                        <span>****</span>
                        <span>****</span>
                        <span>{{customer_card_number}}</span>
                      </div>
                    </div>


                  </div>
                  <div v-if="customer_credit_card_change" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-1">
                    <stripe-card-elements ref="elmStripeCard" :reset_all="true"></stripe-card-elements>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-xl-12 mt-15">
                    <div class="col-sm-4 offset-sm-4">
                      <button type="button" v-if="card_form.button_submit._visible && !card_form.button_loader._visible" @click="updateCustomerStripeCard" :class="'ht-btn fz-18' + ((card_form.button_submit._disabled) ? ' disabled' : '')" :disabled="card_form.button_submit._disabled" v-html="card_form.button_submit._text"></button>
                      <button type="button" v-if="!card_form.button_submit._visible && card_form.button_loader._visible" :class="'ht-btn fz-18' + ((card_form.button_loader._disabled) ? ' disabled' : '')" :disabled="card_form.button_loader._disabled" v-html="card_form.button_loader._text"></button>
                    </div>
                  </div>
                  <div v-if="card_form.alert_message._show" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-1">
                    <div role="alert" :class="'alert mt-30 ' + card_form.alert_message._type" v-html="card_form.alert_message._text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>



</div>
</template>

<script>
import TokenService from "@/services/token.service";
import UserService from "@/services/user.service";
import StripeCardElements from "@/components/StripeCardElements";

export default {
  name: "CustomerProfile",
  components:{
    StripeCardElements,
  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      form_profile:{
        first_name:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        last_name:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        email_address:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        mobile_no:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Save &amp; Update", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      form_change_password:{
        old_password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        new_password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        confirm_password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Change Password", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      user_orders:{
        _order_loaded: false,
        _orders: [],
      },
      customer_credit_card_change:false,
      customer_card_number:"",
      card_form:{
        stripe_token:{
          _value:null,
        },
        button_submit:{
          _text:"Update Card", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
      },
      web_page_head:{
        page_title:"Profile",
        meta_title:"Profile",
        meta_description:"Profile"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (Object.keys(localUser).length){
        if (Object.keys(localUser.user)){
          let order = localUser.order;
          let user = localUser.user;
          this.form_profile.first_name._value = user.first_name;
          this.form_profile.last_name._value = user.last_name;
          this.form_profile.email_address._value = user.email_address;
          this.form_profile.mobile_no._value = user.mobile_no;
          if (typeof user.card !== 'undefined'){
            this.customer_card_number = user.card;
            this.customer_credit_card_change = false;
            //customer_card_number
            if (this.customer_card_number == ''){
              this.card_form.button_submit._text = "Add Card";
            }

          }

        }
      }
    }

  },
  methods:{
    doAjax(){
      UserService.getMyAccount().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.orders !== 'undefined'){
                this.user_orders._order_loaded = true;
                this.user_orders._orders = jsonResponse.data.orders;

              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    customerProfileValidation(){
      let field_count = 0;
      if (this.form_profile.first_name._value.trim().length < 1){
        this.form_profile.first_name._validation_show = true;
        field_count++;
      }
      if (this.form_profile.last_name._value.trim().length < 1){
        this.form_profile.last_name._validation_show = true;
        field_count++;
      }
      if (this.form_profile.mobile_no._value.trim().length < 1){
        this.form_profile.mobile_no._validation_show = true;
        field_count++;
      }
      return (field_count < 1);

    },
    updateCustomerProfile(){
      this.form_profile._validation_show = true;
      if (this.customerProfileValidation()){
        this.form_profile.button_submit._visible = false;
        this.form_profile.button_loader._visible = true;
        let formData = new FormData();
        for (let k in this.form_profile){
          if (typeof this.form_profile[k]._value !== 'undefined'){
            formData.append(k, this.form_profile[k]._value);
          }
        }
        UserService.postCustomerProfileUpdate(formData).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                this.form_profile.alert_message._text = jsonResponse.message;
                this.form_profile.alert_message._show = true;
                this.form_profile.alert_message._type = "alert-success";
                setTimeout(
                    ()=> {
                      this.form_profile.alert_message._show = false;
                    }, 3000
                );
              }else{
                this.form_profile.alert_message._text = jsonResponse.message;
                this.form_profile.alert_message._show = true;
                this.form_profile.alert_message._type = "alert-warning";
              }
              this.form_profile.button_submit._visible = true;
              this.form_profile.button_loader._visible = false;
            }
        );


      }
    },
    customerPasswordValidation(){
      this.form_change_password.confirm_password._message = "This field is required.";
      let field_count = 0;
      if (this.form_change_password.old_password._value.trim().length < 1){
        this.form_change_password.old_password._validation_show = true;
        field_count++;
      }
      if (this.form_change_password.new_password._value.trim().length < 1){
        this.form_change_password.new_password._validation_show = true;
        field_count++;
      }
      if (this.form_change_password.confirm_password._value.trim().length < 1){
        this.form_change_password.confirm_password._validation_show = true;
        field_count++;
      }
      if (this.form_change_password.new_password._value !== this.form_change_password.confirm_password._value){
        this.form_change_password.confirm_password._message = "Confirm password not matched.";
        this.form_change_password.confirm_password._validation_show = true;
        field_count++;
      }
      return (field_count < 1);
    },
    updateCustomerPassword(){
      this.form_change_password._validation_show = true;
      if (this.customerPasswordValidation()){
        this.form_change_password.button_submit._visible = false;
        this.form_change_password.button_loader._visible = true;
        let formData = new FormData();
        for (let k in this.form_change_password){
          if (typeof this.form_change_password[k]._value !== 'undefined'){
            formData.append(k, this.form_change_password[k]._value);
          }
        }
        UserService.postCustomerPasswordUpdate(formData).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                this.form_change_password.alert_message._text = jsonResponse.message;
                this.form_change_password.alert_message._show = true;
                this.form_change_password.alert_message._type = "alert-success";
                setTimeout(
                    ()=> {
                      this.form_change_password.alert_message._show = false;
                      this.form_change_password.old_password._value = "";
                      this.form_change_password.new_password._value = "";
                      this.form_change_password.confirm_password._value = "";
                      this.form_change_password._validation_show = false;
                    }, 3000
                );
              }else{
                this.form_change_password.alert_message._text = jsonResponse.message;
                this.form_change_password.alert_message._show = true;
                this.form_change_password.alert_message._type = "alert-warning";
              }
              this.form_change_password.button_submit._visible = true;
              this.form_change_password.button_loader._visible = false;
            }
        );
      }

    },
    showStripeCardForm(){
      this.customer_credit_card_change = true;
    },
    async updateCustomerStripeCard(){
      if (!this.customer_credit_card_change){
        this.customer_credit_card_change = true;
        return;
      }
      this.card_form.button_loader._visible = true;
      this.card_form.button_submit._visible = false;
      let stripeResponse = await this.$refs.elmStripeCard.getStripeTokenResponse();
      await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
      if (stripeResponse === null){
        this.card_form.button_loader._visible = false;
        this.card_form.button_submit._visible = true;
        return false;
      }

      let formData = new FormData();
      formData.append("spgt", JSON.stringify(stripeResponse));

      UserService.postCustomerStripeCardUpdate(formData).then(
          (response) => {
            let jsonResponse = response.data;
            this.card_form.button_loader._visible = false;
            this.card_form.button_submit._visible = true;
            if (jsonResponse.status){
              this.card_form.button_submit._visible = false;
              this.card_form.alert_message._text = jsonResponse.message;
              this.card_form.alert_message._show = true;
              this.card_form.alert_message._type = "alert-success";
            }else{
              this.card_form.alert_message._text = jsonResponse.message;
              this.card_form.alert_message._show = true;
              this.card_form.alert_message._type = "alert-warning";
            }
          },
          (error) =>{
            this.card_form.alert_message._show = true;
            this.card_form.alert_message._type = "alert-danger";
            this.card_form.alert_message._text = error.message;
            this.card_form.button_submit._visible = true;
            this.card_form.button_loader._visible = false;
          }
      );
    },
  },
  mounted() {
    this.doAjax();
  },
  watch:{
    'form_profile.first_name._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_profile.first_name._validation_show = true;
        this.form_profile.first_name._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_profile.first_name._validation_show = true;
        this.form_profile.first_name._validate = false;
      }else{
        this.form_profile.first_name._validation_show = false;
        this.form_profile.first_name._validate = true;
      }
    },
    'form_profile.last_name._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_profile.last_name._validation_show = true;
        this.form_profile.last_name._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_profile.last_name._validation_show = true;
        this.form_profile.last_name._validate = false;
      }else{
        this.form_profile.last_name._validation_show = false;
        this.form_profile.last_name._validate = true;
      }
    },
    'form_profile.mobile_no._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_profile.mobile_no._validation_show = true;
        this.form_profile.mobile_no._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_profile.mobile_no._validation_show = true;
        this.form_profile.mobile_no._validate = false;
      }else{
        this.form_profile.mobile_no._validation_show = false;
        this.form_profile.mobile_no._validate = true;
      }
    },

    'form_change_password.old_password._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_change_password.old_password._validation_show = true;
        this.form_change_password.old_password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_change_password.old_password._validation_show = true;
        this.form_change_password.old_password._validate = false;
      }else{
        this.form_change_password.old_password._validation_show = false;
        this.form_change_password.old_password._validate = true;
      }
    },
    'form_change_password.new_password._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_change_password.new_password._validation_show = true;
        this.form_change_password.new_password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_change_password.new_password._validation_show = true;
        this.form_change_password.new_password._validate = false;
      }else{
        this.form_change_password.new_password._validation_show = false;
        this.form_change_password.new_password._validate = true;
      }
    },
    'form_change_password.confirm_password._value':function(newVal, oldVal){
      this.form_change_password.confirm_password._message = "This field is required.";
      if (newVal === '') {
        this.form_change_password.confirm_password._validation_show = true;
        this.form_change_password.confirm_password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_change_password.confirm_password._validation_show = true;
        this.form_change_password.confirm_password._validate = false;
      }else if (newVal !== this.form_change_password.new_password._value) {
        this.form_change_password.confirm_password._message = "Confirm password not matched.";
        this.form_change_password.confirm_password._validation_show = true;
        this.form_change_password.confirm_password._validate = false;
      }else{
        this.form_change_password.confirm_password._validation_show = false;
        this.form_change_password.confirm_password._validate = true;
      }
    },
  },
}
</script>

<style scoped>

</style>