<template>
  <div class="order-confirmed">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Order Confirmed</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li><router-link to="/">Order</router-link></li>
                <li>Order Confirmed</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <div v-if="order_content._content_loaded" class="pricing-plan-section section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-0 mb-xs-0">
              <p v-html="order_content._content.order_message"></p>
            </div>
          </div>
        </div>
        <div hidden="hidden" class="row no-gutters">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="single-team mb-30">
              <div class="team-image" style="border-radius: 0px;">
                <img src="assets/images/icons/message-on-phone.png" alt="">
              </div>
              <div class="team-content">
                <h4 class="team-title"><a href="#">WE'LL TEXT YOU </a></h4>
                <p class="team-headline">Please keep your phone on in case we have any issues finding you. We’ll send a message 1 hour before we arrive.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="single-team mb-30">
              <div class="team-image" style="border-radius: 0px;">
                <img src="assets/images/icons/pound-bill.png" alt="">
              </div>
              <div class="team-content">
                <h4 class="team-title"><a href="#">MINIMUM £20</a></h4>
                <p class="team-headline">We have a minimum order value of £20. So if you submit less than £20 we will round the order up to £20.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
          <div class="single-team mb-30">
            <div class="team-image" style="border-radius: 0px;">
              <img src="assets/images/payment/shirt.png" alt="">
            </div>
            <div class="team-content">
              <h4 class="team-title"><a href="#">SEPARATE ITEMS</a></h4>
              <p class="team-headline">Keep categorised items separate ie. Dry clean, wash and press, press only, service wash (wash, tumble dry and fold).</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!---->

  </div>

</template>

<script>
import TokenService from "@/services/token.service";
import UserService from "@/services/user.service";

export default {
  name: "OrderConfirmed",
  components: {

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      order_content:{
        _content_loaded: false,
        _content: {
          order_id:0,
          order_prefix:"",
          order_message:"",
        },

      },
      web_page_head:{
        page_title:"Order Confirmed",
        meta_title:"Order Confirmed",
        meta_description:"Order Confirmed"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
    script: function() {
      return [
        { type: 'text/javascript', inner: 'gtag("event", "conversion", { "send_to": "AW-10815897418/nN6zCJm99YgYEMr-taUo", "transaction_id": "'+this.order_content._content.order_prefix + this.order_content._content.order_id+'" });', body: false, id:'gTag'},
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {

    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (Object.keys(localUser).length){
        if (Object.keys(localUser.order).length && Object.keys(localUser.user)){
          let order = localUser.order;
          let user = localUser.user;
          this.order_content._content_loaded = true;
          this.order_content._content.order_message = "Thanks "+user.first_name + " " + user.last_name+". Your order has been placed.";
          let _self = this;
          window.setTimeout(function () {
            _self.order_content._content.order_id = parseInt(order.id);
            _self.order_content._content.order_prefix = order.prefix;
            _self.$emit('updateHead')
          }, 300)
        }
      }
    }
  },
  methods:{
    doAjax(){
      UserService.getMyAccount().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                }
              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {}
      );
    },
    addCode(){

    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>