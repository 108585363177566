<template>
<div>
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase">Pricing</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Pricing</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pricing-plan-section section pt-55 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-two mb-45 mb-xs-30">
            <h3 class="title" v-html="pricing_content._content.web_title"></h3>
            <p style="white-space: pre-wrap;" v-html="pricing_content._content.web_description"></p>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-12">
          <tabs :options="{ defaultTabHash: 'most&nbsp;popular' }" ref="myTabs">
            <tab v-for="(item, idx) in pricing_content._content.service_list" :key="idx" :ref="'refTab' + idx" :id="'tab' + idx" :name="item.srv_title">
              <div class="section-title-two mt-30 mb-30 mb-xs-30">
                <h3 v-html="item.srv_title"></h3>
              </div>
                <ul>
                  <li v-for="(sub_item, i) in item.pricing_list">
                    <div>{{sub_item.inp_title}}
                      <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{sub_item.inp_price}}</span>
                    </div>
                  </li>
                </ul>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import UserService from "@/services/user.service";
import {Tabs, Tab} from 'vue-tabs-component';

export default {
  name: "Pricing",
  components:{
    Tab,
    Tabs,
  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      pricing_content:{
        _content_loaded: false,
        _content:{service_list:[]},
      },
      web_page_head:{
        page_title:"Pricing",
        meta_title:"Pricing",
        meta_description:"Pricing"
      },



    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getPricingContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.service_content !== 'undefined'){
                this.pricing_content._content = jsonResponse.data.service_content;
                this.pricing_content._content_loaded = true;
                setTimeout(()=>{
                  let els = document.querySelectorAll("a[href='#tab0']");
                  if (typeof els[0] !== 'undefined'){
                    els[0].click();
                  }
                }, parseInt(process.env.VUE_APP_LOADER_HIDE_DELAY) - 100);
              }

            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );

    },
    selectFirstTab(){

    },

  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>