import Vue from 'vue'
import App from "@/App";
import './registerServiceWorker'
import router from "@/router";
import store from "@/store";
import Vuex from 'vuex';
import VueHead from "vue-head";
import setupInterceptors from "@/services/setupInterceptors";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

Vue.config.productionTip = false
Vue.use(VueHead);
Vue.use(Vuex);

setupInterceptors(store);

Vue.mixin({
  data: function() {
    return {
      app_page_loader:true,
      page_loader_hide_delay:500,
      globalVar:'global',
      app_currency_symbol:'&pound;'
    }
  }
});
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


