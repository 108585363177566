import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue'
import ServicesView from '../views/Services';
import ServiceView from '../views/Service';
import PricingView from '../views/Pricing';
import ContactView from '../views/Contact';
import OrderBookView from "@/views/OrderBook";
import DisclaimerView from "@/views/Disclaimer";
import PrivacyPolicyView from "@/views/PrivacyPolicy";
import OrderConfirmed from "@/views/OrderConfirmed";
//import CustomerProfileView from "@/views/CustomerProfile";
import MyOrders from "@/views/MyOrders";
import OrderDetail from "@/views/OrderDetail";
import OrderReschedule from "@/views/OrderReschedule";
import LoginView from "@/views/Login";
import ForgotPasswordView from "@/views/ForgotPassword";
import ResetPasswordView from "@/views/ResetPassword";
import BestDryCleaningView from "@/views/BestDryCleaning";
import AllAreasView from "@/views/AllAreas";
import BlogView from "@/views/Blog";
import BlogCategoryView from "@/views/BlogCategory";
import BlogArchiveView from "@/views/BlogArchive";
import BlogDetailView from "@/views/BlogDetail";
import SitemapView from "@/views/Sitemap";
import CustomerProfile from "@/views/CustomerProfile";
import AboutView from "@/views/AboutView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {path: '/index.html', component: HomeView},
  { path: '/area/:areaSlug', name :'homeSelectedArea', component: HomeView},
  {
    path: '/about-us',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  },
  { path: '/services', name:'services', component: ServicesView},
  { path: '/service/:slug', name:'service', component: ServiceView},
  { path: '/pricing', name:'pricing', component: PricingView},
  { path: '/contact', name:'contact', component: ContactView},
  { path: '/order-book', name:'OrderBook', component: OrderBookView},
  { path: '/disclaimer', name:'disclaimer', component: DisclaimerView},
  { path: '/privacy-policy', name:'PrivacyPolicy', component: PrivacyPolicyView},
  { path: '/order-confirmed', name:'OrderConfirmed', component: OrderConfirmed},
  { path: '/my-orders', name:'MyOrders', component: MyOrders},
  { path: '/my-orders/order/:orderNo', name:'OrderDetail', component: OrderDetail},
  { path: '/order-reschedule/:orderNo', name:'OrderReschedule', component: OrderReschedule},
  { path: '/login', name:'Login', component: LoginView},
  { path: '/profile', name:'CustomerProfile', component: CustomerProfile},
  { path: '/forgot-password', name:'ForgotPassword', component: ForgotPasswordView},
  { path: '/reset-password/:restKey', name:'ResetPassword', component: ResetPasswordView},
  { path: '/best-dry-cleaning-london', name: 'BestDryCleaning', component: BestDryCleaningView},
  { path: '/areas', name: 'AllAreas', component: AllAreasView},
  { path: '/blog', name: 'Blog', component: BlogView},
  { path: '/blog/category/:category_slug', name: 'BlogCategory', component: BlogCategoryView},
  { path: '/blog/archive/:archive_slug', name: 'BlogArchive', component:BlogArchiveView },
  { path: '/blog/detail/:blog_slug', name: 'BlogDetail', component: BlogDetailView},
  { path: '/sitemap', name: 'Sitemap', component: SitemapView}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*
router.beforeEach((to, from, next) => {
  //if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  if (to.name === 'OrderBook' || to.name === 'CustomerProfile'){

    if (!document.getElementById('stripeApi')) {
      let head = document.getElementsByTagName("head")[0];
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v3/";
      s.id = "stripeApi";
      head.appendChild(s);
    }
    setTimeout(()=>{
      next();
    }, 300);

  }else{
    next()
  }
})
*/
export default router
