<template>
  <div class="whats-app-widget">
    <a target="_blank" :href="'https://api.whatsapp.com/send?l=en&phone='+MobileNo+'&text=Hi,%0a ...'"><span>Chat</span></a>
  </div>
</template>

<script>
export default {
  name: "WhatsApp",
  props:{
    MobileNo:String
  }
}
</script>

<style scoped>

</style>