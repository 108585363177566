<template>
  <div class="OrderDetail">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">Order No: {{selected_order_no}}</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li><router-link to="/my-orders">My Orders</router-link></li>
                <li>Order {{selected_order_no}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selected_order._order_loaded" class="orders-section section pt-55 pb-60">
      <div v-if="selected_order._order_found && selected_order._order_reschedule" class="container">
        <div class="table-responsive">
          <table class="table table-striped table-my-orders">
            <thead class="thead-dark">
            <tr>
              <th >Address</th>
              <th style="width: 160px;">Order Date</th>
              <th style="width: 160px;">Pick Up</th>
              <th style="width: 160px;">Drop Off</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{selected_order._order.order_address}}<span class="text-uppercase">, {{selected_order._order.order_address_descp}}</span></td>
              <td>{{selected_order._order.submit_date}}</td>
              <td>{{selected_order._order.pick_up}}</td>
              <td>{{selected_order._order.drop_off}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-8 d-flex justify-content-start">
                <div v-if="alert_message._show" :class="'alert col-md-12 mt-0 ' + alert_message._type" v-html="alert_message._text"></div>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button class="ht-btn text-center mr-10" :disabled="order_cancel_button._cancel._disabled" @click="onClickReschedule" style="width: 150px;">Reschedule</button>
                <button class="ht-btn text-center" :disabled="order_cancel_button._cancel._disabled" @click="showModal" style="width: 150px;">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center mt-20">
          <div v-if="rescheduled_order._alert._show" :class="'alert col-md-8 mt-0 ' + rescheduled_order._alert._type" v-html="rescheduled_order._alert._text"></div>
        </div>




      </div>
    </div>
    <div v-if="selected_order._order_loaded && !selected_order._order_reschedule" class="order-detail section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center order-header">
              <div class="col-6">
                <h4>Pickup / Delivery Address</h4>
                <p>{{selected_order._order.order_address}}<br/>{{selected_order._order.order_address_descp}}</p>
                <h4>Pickup Date &amp; Time</h4>
                <p class="d-none d-xl-block d-lg-block d-md-block d-sm-block">{{selected_order._order.pick_up_date}} {{selected_order._order.pick_up_time}}</p>
                <p class="d-block d-xl-none d-lg-none d-md-none d-sm-none">{{selected_order._order.pick_up_date}}<br/>{{selected_order._order.pick_up_time}}</p>
              </div>
              <div class="col-6 text-right">
                <h4>Order Status</h4>
                <p>{{selected_order._order.order_status_text}}</p>
                <h4>Payment Method</h4>
                <p>{{selected_order._order.order_paymentMethod}}</p>
                <h4>Delivery Date &amp; Time</h4>
                <p class="d-none d-xl-block d-lg-block d-md-block d-sm-block">{{selected_order._order.drop_off_date}} {{selected_order._order.drop_off_time}}</p>
                <p class="d-block d-xl-none d-lg-none d-md-none d-sm-none">{{selected_order._order.drop_off_date}}<br/>{{selected_order._order.drop_off_time}}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selected_order._order.items.length" class="row no-gutters">
          <div class="col-12">
            <table class="table order-items">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Item Name</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(itemRow, idx) in selected_order._order.items">
                <td>{{idx + 1}}</td>
                <td>{{itemRow.item_name}} <span class="badge badge-light" v-html="itemRow.category_name"></span></td>
                <td class="text-right"><span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{itemRow.item_price}}</span></td>
                <td class="text-center" v-html="itemRow.item_qty"></td>
                <td class="text-right"><span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{itemRow.item_total}}</span></td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="2" class="text-right">Item Total</td>
                <td></td>
                <td></td>
                <td class="text-right">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_item_total}}</span>
                </td>
              </tr>
              <tr v-if="parseInt(selected_order._order.order_adjustment_amount) > 0">
                <td colspan="2" class="text-right text-warning">Round Off</td>
                <td></td>
                <td></td>
                <td class="text-right text-warning">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_adjustment_amount}}</span>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-right">Sub Total</td>
                <td></td>
                <td></td>
                <td class="text-right">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_sub_total}}</span>
                  </td>
              </tr>
              <tr v-if="parseInt(selected_order._order.order_coupon_amount) > 0">
                <td colspan="2" class="text-right text-success" v-html="'Coupon ('+selected_order._order.order_coupon_code+')'"></td>
                <td></td>
                <td></td>
                <td class="text-right text-success">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_coupon_amount}}</span>
                </td>
              </tr>
              <tr v-if="parseInt(selected_order._order.order_discount) > 0">
                <td colspan="2" class="text-right text-info">Discount</td>
                <td></td>
                <td></td>
                <td class="text-right text-info">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_discount}}</span>
                </td>
              </tr>
              <tr v-if="parseInt(selected_order._order.order_delivery_charges) < 1">
                <td colspan="2" class="text-right">Delivery Charges</td>
                <td></td>
                <td></td>
                <td class="text-right"><span class="badge badge-success">Free</span></td>
              </tr>
              <tr v-if="parseInt(selected_order._order.order_delivery_charges) > 0">
                <td colspan="2" class="text-right">Delivery Charges</td>
                <td></td>
                <td></td>
                <td class="text-right">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_shipping_charges}}</span>
                  </td>
              </tr>




              <tr v-if="parseInt(selected_order._order.order_tax_amount) > 0">
                <td colspan="2" class="text-right text-warning">{{selected_order._order.order_tax_title}} {{selected_order._order.order_tax_rate}}</td>
                <td></td>
                <td></td>
                <td class="text-right text-warning">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_tax_amount}}</span>
                  </td>
              </tr>

              <tr>
                <td colspan="2" class="text-right fw-600">Grand Total</td>
                <td></td>
                <td></td>
                <td class="text-right fw-600">
                  <span class="price"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{selected_order._order.order_grand_total}}</span>
                  </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>



        <div v-if="selected_order._order.order_status_list.length" class="row no-gutters">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h3 class="title">Order Statuses</h3>
            </div>
          </div>
          <div class="col-12">
            <table class="table order-status">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date/Time</th>
                <th>Message</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in selected_order._order.order_status_list">
                <td class="align-middle">{{item.sno}}</td>
                <td class="align-middle">{{item.ords_datetime}}</td>
                <td v-html="item.ords_message">{{item.ords_message}}</td>
                <td class="align-middle">{{item.ords_status_text}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div :class="'modal' + ((isModalShow) ? ' fadeIn show' : ' fadeOut')" ref="modal_center" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
    :style="{'display':((isModalShow) ? 'block' : '')}">
      <div class="modal-dialog modal-dialog-centered modal-block-danger" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Order Cancel</h5>
            <button type="button" class="close" :disabled="order_cancel_button._cancel._disabled" data-dismiss="modal" @click="hideModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-icon"><i class="fa fa-question-circle"></i></div>
            <div class="modal-text"><p>Are you sure you want to cancel your order?</p></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" :disabled="order_cancel_button._cancel._disabled" @click="hideModal" data-dismiss="modal">No</button>
            <button v-if="order_cancel_button._cancel._visible && !order_cancel_button._loader._visible" :disabled="order_cancel_button._cancel._disabled" type="button" @click="onClickCancel" class="btn btn-danger" v-html="order_cancel_button._cancel._text">Yes</button>
            <button v-if="!order_cancel_button._cancel._visible && order_cancel_button._loader._visible" :disabled="order_cancel_button._loader._disabled" type="button" @click="onClickCancel" class="btn btn-primary" v-html="order_cancel_button._loader._text">Yes</button>

          </div>
        </div>
      </div>
    </div>



  </div>

</template>

<script>
import TokenService from "@/services/token.service";
import UserService from "@/services/user.service";

export default {
  name: "OrderDetail",
  components:{},

  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      selected_order:{
        _order_loaded: false,
        _order_found:false,
        _order_reschedule:false,
        _order: {order_status_list:[]},

      },
      selected_order_no:"",
      isModalShow:false,
      order_cancel_button:{
        _cancel:{_text:"Yes", _visible:true, _disabled:false,},
        _loader:{_text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,}
      },
      alert_message:{_type:"", _text:"", _show:false},
      rescheduled_order:{
        id:0,
        prefix:"",
        reschedule:false,
        _alert:{_type:"", _text:"", _show:false},
      },
      web_page_head:{
        page_title:"Order Detail",
        meta_title:"Order Detail",
        meta_description:"Order Detail"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    this.selected_order_no = this.$route.params.orderNo;
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (Object.keys(localUser).length){
        if (Object.keys(localUser.order).length && Object.keys(localUser.user)){
          if (typeof localUser.order_reschedule !== 'undefined'){
            if (localUser.order_reschedule.reschedule){
              let order = localUser.order_reschedule;
              let user = localUser.user;
              localUser.order_reschedule.reschedule = false;
              //Your order DDC-213 has been rescheduled.
              this.rescheduled_order._alert._text = "Thanks "+user.first_name + " " + user.last_name+". Your order "+order.prefix + order.id+" has been rescheduled.";
              this.rescheduled_order._alert._type = "alert-success";
              this.rescheduled_order._alert._show = true;
              setTimeout(() => {
                this.rescheduled_order._alert._show = false;
              }, 3000);
              TokenService.setUser(localUser);
            }
          }
        }
      }
    }
  },
  methods:{
    doAjax(){
      UserService.getOrderDetailById(this.selected_order_no).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = this.selected_order_no + " | " +  jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.order !== 'undefined' && jsonResponse.data.order !== null){
                this.selected_order._order_loaded = true;
                this.selected_order._order = jsonResponse.data.order;
                if(Object.keys(this.selected_order._order).length){
                  this.selected_order._order_found = true;
                  this.selected_order._order_reschedule = jsonResponse.data.order.order_reschedule;
                }
              }else{
                this.$router.push("/login")
              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    onClickReschedule(){
      this.$router.push("/order-reschedule/" + this.selected_order_no);

    },
    onClickCancel(){
      this.order_cancel_button._cancel._disabled = true;
      this.order_cancel_button._cancel._visible = false;
      this.order_cancel_button._loader._visible = true;
      UserService.postOrderCancelByOrderId(this.selected_order_no).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              this.alert_message._show = true;
              this.alert_message._text = jsonResponse.message;
              this.alert_message._type = "alert-success";
              this.isModalShow = false;
              setTimeout(()=>{
                this.$router.push("/my-orders");
              }, 1500);
            }else{
              this.alert_message._show = true;
              this.alert_message._text = jsonResponse.message;
              this.alert_message._type = "alert-warning";
              this.order_cancel_button._cancel._disabled = false;
              this.order_cancel_button._cancel._visible = true;
              this.order_cancel_button._loader._visible = false;
              this.isModalShow = false;
            }
          },
          (error) => {
            this.alert_message._show = true;
            this.alert_message._text = error.message;
            this.alert_message._type = "alert-danger";
            this.order_cancel_button._cancel._disabled = false;
            this.order_cancel_button._cancel._visible = true;
            this.order_cancel_button._loader._visible = false;
            this.isModalShow = false;
          }
      );


    },
    showModal(){
      this.isModalShow = true;
    },
    hideModal(){
      this.isModalShow = false;
    },
    resetOrderReschedule(){

    },
  },
  mounted() {
    this.doAjax();

  }
}
</script>

<style scoped>

</style>