<template>
  <div class="services">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase" v-html="services_content._content.web_title"></h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>Services</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="team-section-start section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <p v-html="services_content._content.web_description" style="white-space: pre-wrap;"></p>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div v-for="(item, idx) in services_content._content.services_list" class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="single-team mb-30">
              <div class="team-image" style="border-radius: 0px;">
                <img :src="item.srv_icon" :alt="item.srv_title">
              </div>
              <div class="team-content">
                <h4 class="team-title"><router-link :to="{ path:'/service/' + item.srv_slug, name: 'service', params: { slug: item.srv_slug } }" class="" v-html="item.srv_title"></router-link></h4>
                <p class="team-headline" v-html="item.srv_detail"></p>
                <p class="team-price">FROM <span class="currency-default"><i v-html="app_currency_symbol"></i> </span>{{item.srv_amount}}</p>
                <router-link :to="{ path:'/service/' + item.srv_slug, name: 'service', params: { slug: item.srv_slug } }" class="ht-btn" style="width: auto; text-transform: uppercase;">Pricing</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
export default {
  name: "Services",
  components:{

  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      services_content:{
        _content_loaded : false,
        _content:{services_list:[]}
      },
      web_page_head:{
        page_title:"Services",
        meta_title:"Services",
        meta_description:"Services"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getServicesContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.services !== 'undefined'){
                this.services_content._content = jsonResponse.data.services;
                this.services_content._content_loaded = true;
              }

            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );

    },

  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>