<template>
<div class="forgotPassword">
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase">Forgot Password</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Forgot Password</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="login-register-section section bg_color--3 pt-55 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 offset-xl-3 offset-lg-3 offset-md-2">
          <div class="login-register-form-area">
            <div class="login-tab-menu">
              <ul class="nav">
                <li><a data-toggle="tab" class="active show"> Reset Your Password</a></li>
              </ul>
            </div>
            <div class="tab-content">
              <div id="login" class="tab-pane fade show active">
                <div class="login-register-form">
                  <div class="row">
                    <div class="col-12 pb-10">
                      <div class="single-input  validation-error">
                        <label class="control-label">Username / Email:</label>
                        <input type="text" v-model="form_forgot_password.email_address._value" placeholder="Email Address" name="name">
                        <span class="required-message" v-if="form_forgot_password.email_address._validation_show && !form_forgot_password.email_address._validate && form_forgot_password._validation_show" v-html="form_forgot_password.email_address._message"></span>
                      </div>
                    </div>
                    <div class="col-12 pb-15 pt-1 text-right">
                      <router-link to="/login" class="">Back to login</router-link>
                    </div>
                    <div class="col-12">
                      <button type="button" v-if="form_forgot_password.button_submit._visible && !form_forgot_password.button_loader._visible" @click="submitForgotPasswordForm" :class="'ht-btn submit fz-18' + ((form_forgot_password.button_submit._disabled) ? ' disabled' : '')" :disabled="form_forgot_password.button_submit._disabled" v-html="form_forgot_password.button_submit._text"></button>
                      <button type="button" v-if="!form_forgot_password.button_submit._visible && form_forgot_password.button_loader._visible" :class="'ht-btn submit fz-18' + ((form_forgot_password.button_loader._disabled) ? ' disabled' : '')" :disabled="form_forgot_password.button_loader._disabled" v-html="form_forgot_password.button_loader._text"></button>
                    </div>
                    <div class="col-12">
                      <div role="alert" v-if="form_forgot_password.alert_message._show" :class="'alert col-md-12 mt-20 ' + ((form_forgot_password.alert_message._type != '') ? form_forgot_password.alert_message._type : 'alert-warning')" v-html="form_forgot_password.alert_message._text"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="register" class="tab-pane fade">
                <div class="login-register-form">
                  <a href="#login" data-toggle="tab" style="position: absolute; top: 50px; right: 60px;">Back To Login</a>
                  <h2 style="font-size: 30px; font-weight: 700; line-height: 26px; height: 40px; position: absolute; left: 60px; top: 50px; background: rgb(255, 255, 255); display: inline-block;">Forget Password</h2>
                  <form action="#" method="post">
                    <div class="row row-5">
                      <div class="col-12">
                        <div class="single-input">
                          <input type="email" placeholder="Your Email Address" name="emain">
                        </div>
                      </div>
                      <div class="col-12 mb-0 mt-10">
                        <button class="ht-btn">Reset Password</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
</template>

<script>
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
import UserService from "@/services/user.service";
import TokenService from "@/services/token.service";

export default {
  name: "ForgotPassword",
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      form_forgot_password:{
        email_address:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Reset Password", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      web_page_head:{
        page_title:"Forgot Password",
        meta_title:"Forgot Password",
        meta_description:"Forgot Password"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getLoginContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    checkFormValidation(){
      let field_count = 0;
      if (this.form_forgot_password.email_address._value.trim().length < 1){
        this.form_forgot_password.email_address._validation_show = true;
        field_count++;
      }else if (!EMAIL_REGEX.test(this.form_forgot_password.email_address._value)){
        this.form_forgot_password.email_address._validation_show = true;
        this.form_forgot_password.email_address._message = "Please enter valid email address.";
        field_count++;
      }
      return (field_count < 1);

    },
    submitForgotPasswordForm(){
      this.form_forgot_password._validation_show = true;
      if (this.checkFormValidation()){
        this.form_forgot_password.button_submit._visible = false;
        this.form_forgot_password.button_loader._visible = true;
        let formData = new FormData();
        formData.append('email_address', this.form_forgot_password.email_address._value);

        UserService.postForgotPassword(formData).then(
            (response) => {
              let jsonResponse = response.data;
              this.form_forgot_password._validation_show = false;
              if (jsonResponse.status){

                this.form_forgot_password.alert_message._type = "alert-success";
                this.form_forgot_password.alert_message._show = true;
                this.form_forgot_password.alert_message._text = jsonResponse.message;
                setTimeout(()=>{
                  this.form_forgot_password.alert_message._show = false;
                }, 5000);
              }else{
                this.form_forgot_password.alert_message._type = "alert-warning";
                this.form_forgot_password.alert_message._show = true;
                this.form_forgot_password.alert_message._text = jsonResponse.message;


              }
              this.form_forgot_password.button_submit._visible = true;
              this.form_forgot_password.button_loader._visible = false;

            }
        );
      }
    },
  },
  mounted() {
    this.doAjax();
  },
  watch:{
    'form_forgot_password.email_address._value':function(newVal, oldVal){
      this.form_forgot_password.email_address._message = "This field is required.";
      if (newVal === '') {
        this.form_forgot_password.email_address._validation_show = true;
        this.form_forgot_password.email_address._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_forgot_password.email_address._validation_show = true;
        this.form_forgot_password.email_address._validate = false;
      }else if (!EMAIL_REGEX.test(newVal)){
        this.form_forgot_password.email_address._message = "Please enter valid email address.";
        this.form_forgot_password.email_address._validation_show = true;
        this.form_forgot_password.email_address._validate = false;
      }else{
        this.form_forgot_password.email_address._validation_show = false;
        this.form_forgot_password.email_address._validate = true;
      }
    },
  },
}
</script>

<style scoped>

</style>