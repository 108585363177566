<template>
<div class="reset-password">
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase">Reset Password</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Reset Password</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="login-register-section section bg_color--3 pt-55 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 offset-xl-3 offset-lg-3 offset-md-2">
          <div class="login-register-form-area">
            <div class="login-tab-menu">
              <ul class="nav">
                <li><a data-toggle="tab" class="active show"> Enter A New Password</a></li>
              </ul>
            </div>
            <div class="tab-content">
              <div id="login" class="tab-pane fade show active">
                <div class="login-register-form">
                  <div class="row">
                    <div class="col-12 pb-10">
                      <div class="single-input">
                        <label class="control-label">New Password:</label>
                        <input type="password" v-model="form_reset_password.password._value" placeholder="New Password" name="password">
                        <span class="required-message" v-if="form_reset_password.password._validation_show && !form_reset_password.password._validate && form_reset_password._validation_show" v-html="form_reset_password.password._message"></span>
                      </div>
                    </div>
                    <div class="col-12 pb-10">
                      <div class="single-input">
                        <label class="control-label">Confirm New Password:</label>
                        <input type="password" v-model="form_reset_password.confirm_password._value" placeholder="Confirm New Password" name="confirmpassword">
                        <span class="required-message" v-if="form_reset_password.confirm_password._validation_show && !form_reset_password.confirm_password._validate && form_reset_password._validation_show" v-html="form_reset_password.confirm_password._message"></span>
                      </div>
                    </div>

                    <div class="col-12 mt-15">
                      <button type="button" v-if="form_reset_password.button_submit._visible && !form_reset_password.button_loader._visible" @click="submitResetPasswordForm" :class="'ht-btn submit fz-18' + ((form_reset_password.button_submit._disabled) ? ' disabled' : '')" :disabled="form_reset_password.button_submit._disabled" v-html="form_reset_password.button_submit._text"></button>
                      <button type="button" v-if="!form_reset_password.button_submit._visible && form_reset_password.button_loader._visible" :class="'ht-btn submit fz-18' + ((form_reset_password.button_loader._disabled) ? ' disabled' : '')" :disabled="form_reset_password.button_loader._disabled" v-html="form_reset_password.button_loader._text"></button>
                    </div>
                    <div class="col-12">
                      <div role="alert" v-if="form_reset_password.alert_message._show" :class="'alert col-md-12 mt-20 ' + ((form_reset_password.alert_message._type != '') ? form_reset_password.alert_message._type : 'alert-warning')" v-html="form_reset_password.alert_message._text"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "ResetPassword",
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      user_reset_key:"",
      form_reset_password:{
        password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        confirm_password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Change Password", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      web_page_head:{
        page_title:"Reset Password",
        meta_title:"Reset Password",
        meta_description:"Reset Password"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  created() {
    this.user_reset_key = this.$route.params.restKey;
  },
  methods:{
    doAjax(){
      UserService.getLoginContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }

              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    checkFormValidation(){
      let field_count = 0;
      if (this.form_reset_password.password._value.trim().length < 1){
        this.form_reset_password.password._validation_show = true;
        field_count++;
      }
      if (this.form_reset_password.confirm_password._value.trim().length < 1){
        this.form_reset_password.confirm_password._validation_show = true;
        field_count++;
      }
      if (this.form_reset_password.password._value !== this.form_reset_password.confirm_password._value){
        this.form_reset_password.confirm_password._validation_show = true;
        this.form_reset_password.confirm_password._message = "Confirm password not matched.";
        field_count++;
      }
      return (field_count < 1);

    },
    submitResetPasswordForm(){
      this.form_reset_password._validation_show = true;
      if (this.checkFormValidation()){
        this.form_reset_password.button_submit._visible = false;
        this.form_reset_password.button_loader._visible = true;
        let formData = new FormData();
        formData.append("user_key", this.user_reset_key);
        formData.append('new_password', this.form_reset_password.password._value);
        formData.append('confirm_password', this.form_reset_password.confirm_password._value);

        UserService.postResetPassword(formData).then(
            (response) => {
              let jsonResponse = response.data;
              this.form_reset_password._validation_show = false;
              if (jsonResponse.status){

                this.form_reset_password.alert_message._type = "alert-success";
                this.form_reset_password.alert_message._show = true;
                this.form_reset_password.alert_message._text = jsonResponse.message + "<br/>Please wait...";
                setTimeout(()=>{
                  this.$router.push("/login");
                }, 5000);
              }else{
                this.form_reset_password.alert_message._type = "alert-warning";
                this.form_reset_password.alert_message._show = true;
                this.form_reset_password.alert_message._text = jsonResponse.message;


              }
              this.form_reset_password.button_submit._visible = true;
              this.form_reset_password.button_loader._visible = false;

            }
        );
      }
    },
  },
  mounted() {
    this.doAjax();
  },
  watch:{
    'form_reset_password.password._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_reset_password.password._validation_show = true;
        this.form_reset_password.password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_reset_password.password._validation_show = true;
        this.form_reset_password.password._validate = false;
      }else{
        this.form_reset_password.password._validation_show = false;
        this.form_reset_password.password._validate = true;
      }
    },
    'form_reset_password.confirm_password._value':function(newVal, oldVal){
      this.form_reset_password.confirm_password._message = "This field is required.";
      if (newVal === '') {
        this.form_reset_password.confirm_password._validation_show = true;
        this.form_reset_password.confirm_password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_reset_password.confirm_password._validation_show = true;
        this.form_reset_password.confirm_password._validate = false;
      }else if (newVal !== this.form_reset_password.password._value) {
        this.form_reset_password.confirm_password._message = "Confirm password not matched.";
        this.form_reset_password.confirm_password._validation_show = true;
        this.form_reset_password.confirm_password._validate = false;
      }else{
        this.form_reset_password.confirm_password._validation_show = false;
        this.form_reset_password.confirm_password._validate = true;
      }
    },
  },
}
</script>

<style scoped>

</style>