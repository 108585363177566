<template>
<div class="single-service">
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase" v-html="services_content._content.srv_title"></h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li>Services</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="services_content._content_loaded" class="pricing-plan-section section pt-55 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-content">
            <p class="text-bold"></p>
            <p class="normal-text pb-15 text-center" style="border: 0px none; white-space: pre-wrap;" v-html="services_content._content.srv_detail"></p>
          </div>
        </div>
      </div>
      <div v-if="services_content._content.pricing.length" class="row no-gutters">
        <div class="col-12">
          <div class="service-list">
            <ul>
              <li v-for="(item, idx) in services_content._content.pricing">
                <div>{{item.inp_title}}<span class="price"><i class="currency-default" v-html="app_currency_symbol"></i>{{item.inp_price}}</span></div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "Service",
  data(){
    return{
      service_slug:this.$route.params.slug,
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      services_content:{
        _content_loaded : false,
        _content:{pricing:[]}
      },
      web_page_head:{
        page_title:"Service",
        meta_title:"Service",
        meta_description:"Service"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  created() {

  },
  methods:{
    doAjax($slug){
      UserService.getServiceContentBySlug($slug).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.service !== 'undefined'){
                this.services_content._content = jsonResponse.data.service;
                this.services_content._content_loaded = true;
              }
            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.doAjax(this.service_slug);
  }
}
</script>

<style scoped>

</style>