<template>
  <div>
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">ORDER BOOKING</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><a href="/" class="router-link-active">Home</a></li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="job-listing-section section bg_color--3 pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h3 class="title">{{user_selected_location.Text}}<small style="font-size: 16px; letter-spacing: 0.4px; text-transform: none; margin-left: 10px;">
                <router-link to="/" class="router-link-active">(change address)</router-link></small>
              </h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h3 class="title">Choose Services <small style="font-size: 16px; letter-spacing: 0.4px; text-transform: none;">(Optional)</small></h3>
            </div>
          </div>
        </div>


        <div class="row no-gutters mb-4">
          <div class="col-lg-12 order-lg-2 order-1">
            <div class="row no-gutters-sm">
              <div v-for="(item) in services_content._content.services_list" class="col-3 col-md-3 col-lg-2 col-xl-2 mb-10 job-section-item">
                <div id="service_1ab" :class="'single-job-grid-two ' + item.srv_class">
                  <div class="job-image">
                    <a @click="onClickServiceItem(item.srv_id)"><img :src="item.srv_icon" :alt="item.srv_title"></a>
                  </div>
                  <div class="job-info">
                    <div class="job-info-top">
                      <div class="title-name">
                        <h3 class="job-title">
                          <a href="#" class="d-block text-truncate" v-html="item.srv_title"></a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="offers_content._content_loaded" class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h3 class="title">Available Offers <small style="font-size: 16px; letter-spacing: 0.4px; text-transform: none;">(Optional)</small></h3>
            </div>
          </div>
        </div>

        <div v-if="offers_content._content_loaded" class="row no-gutters available-offers">
          <div class="col-lg-12 order-lg-2 order-1">
            <div class="row">
              <div v-for="(offer, key) in offers_content._content.offers_list" class="col-xl-3 col-lg-3 col-md-3 col-12 mb-3 single-offer">
                <ul class="d-flex d-lg-block justify-content-between justify-content-lg-start">
                  <li>
                    <div class="custom-control custom-checkbox"> <input type="checkbox" v-model="offers_content._content.offers_list[key].selected" class="custom-control-input" :id="'checkbox_offer_' + offer.off_id">
                    <label class="custom-control-label" :for="'checkbox_offer_' + offer.off_id">{{offer.off_title}}</label>
                    </div>
                  </li>
                  <li class="d-none d-lg-block d-xl-block d-md-block" v-html="offer.off_detail"></li>
                  <li v-html="app_currency_symbol + ' ' + offer.off_price"></li>
                </ul>

                <div class="d-block d-lg-none d-xl-none d-md-none"><p v-html="offer.off_detail"></p></div>
              </div>
              <div v-for="(offer, key) in offers_content._content.offers_list" class="custom-control custom-checkbox my-1 ml-1 mr-1 d-none">
                <input type="checkbox" v-model="offers_content._content.offers_list[key].selected" class="custom-control-input" :id="'checkbox_offer_' + offer.off_id">
                <label class="custom-control-label" :for="'checkbox_offer_' + offer.off_id">{{offer.off_title}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="job-listing-section bg_color--3 section pb-60">
      <div class="container p-0">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-12 mb-30">
              <div class="section-title-two mb-30 mb-xs-30">
                <h3 class="title">First time with us?</h3>
              </div>
            </div>
          </div>
          <div class="row mb-35">
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6 offset-xl-2 offset-lg-1 offset-md-1 offset-sm-0 mb-30 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0">
              <div class="bg_color--1 p-4 border">
                <div class="section-title-two mb-30 mb-xs-30">
                  <h4 class="title mb-0">ITEM LIST</h4>
                </div>
                <ul class="list-unstyled">
                  <li>▪ No need to list your items, we will do it for you once we collected your bag.</li>
                  <li>▪ Then we'll generate your invoice and your payment will be processed after delivery.</li>
                </ul>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0">
              <div class="bg_color--1 p-4 border">
                <div class="section-title-two mb-30 mb-xs-30">
                  <h4 class="title mb-0">PRICES</h4>
                </div>
                <ul class="list-unstyled">
                  <li>▪ You can review our price list to estimate the amount.</li>
                  <li>▪ Pickup Charges <span class=""><i class="" v-html="app_currency_symbol"></i> {{order_config._pickup_charges}}</span></li>
                  <li>▪ Delivery Charges <span class=""><i class="" v-html="app_currency_symbol"></i> {{order_config._delivery_charges}}</span></li>
                  <li>▪ If you have any questions regarding your payment, our lovely customer care team will be happy to help you.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="job-listing-section bg_color--3 section pb-60">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lp-12 col-lg-12">

            <div class="job-map-wrapper">
              <div class="job-filter">
                <div class="content-search">
                  <div class="section-filter filter-advance">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-title-two mb-45 mb-xs-30">
                          <h3 class="title">Select Service Type </h3>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-4 col-xl-3 col-lg-3 col-md-3 pl-0 pr-0">
                        <div class="filter-radio">
                          <div class="filter-icon" @click="onClickOrderTypeImage(1)">
                            <img src="../assets/img/lorry.png">
                          </div>
                          <div class="filter-input">
                            <input type="radio" value="1" v-model="form_book_order.order_type._value" @change="onChangeOrderType" name="order_type" id="order_type_normal" class="mr-1">
                            <label for="order_type_normal"> Normal</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 col-xl-3 col-lg-3 col-md-3 pl-0 pr-0">
                        <div class="filter-radio">
                          <div class="filter-icon" @click="onClickOrderTypeImage(2)">
                            <img src="../assets/img/delivery-truck.png">
                          </div>
                          <div class="filter-input">
                            <input value="2" v-model="form_book_order.order_type._value" type="radio" @change="onChangeOrderType" name="order_type" id="order_type_urgent" class="mr-1">
                            <label for="order_type_urgent"> Urgent </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="form_book_order._validation_show && form_book_order.order_type._validation_show" class="row justify-content-center">
                      <div class="col-8 col-xl-8 col-lg-8 col-md-8 text-center required-message" v-html="form_book_order.order_type._message"></div>
                    </div>
                    <div class="row mt-20">
                      <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                        <div class="single-input datepicker">
                          <label>Pickup Date<span class="required pl-1">*</span></label>
                          <vue-date-picker v-model="form_book_order.pickup_date._value"
                              :placeholder="config_pickup_datepicker._placeholder"
                              :input-class="config_pickup_datepicker._input_class"
                              :calendar-button="config_pickup_datepicker._calendar_button_icon.length > 3"
                              :calendar-button-icon="config_pickup_datepicker._calendar_button_icon"
                              :clear-button="((config_pickup_datepicker._clear_button_icon.length > 3))"
                              :clear-button-icon="config_pickup_datepicker._clear_button_icon"
                              :disabled-dates="config_pickup_datepicker._disabled_dates"
                              :disabled="parseInt(form_book_order.order_type._value) < 1"
                              @selected="onSelectPickupDate" @cleared="onClearPickupDate"
                          ></vue-date-picker>
                          <span class="required-message" v-if="form_book_order.pickup_date._validation_show && !form_book_order.pickup_date._validate && form_book_order._validation_show" v-html="form_book_order.pickup_date._message"></span>
                        </div>
                      </div>
                      <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                        <div class="single-input">
                          <label>Pickup Time<span class="required pl-1">*</span></label>
                          <select class="form-control" v-model="form_book_order.pickup_time._value" :disabled="form_book_order.pickup_time._disabled" name="sel_pickuptime" @change="onSelectPickupTime">
                            <option value="">Select Pickup Time</option>
                            <option v-for="(item) in config_pickup_datepicker.date_time_slot" :value="item.start_time + '-' + item.end_time">{{item.start_time + ' - ' + item.end_time}}</option>
                          </select>
                          <span class="required-message" v-if="form_book_order.pickup_time._validation_show && !form_book_order.pickup_time._validate && form_book_order._validation_show" v-html="form_book_order.pickup_time._message"></span>
                        </div>
                      </div>
                      <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                        <div class="single-input datepicker">
                          <label>Delivery Date<span class="required pl-1">*</span></label>
                          <vue-date-picker v-model="form_book_order.delivery_date._value"
                              :placeholder="config_delivery_datepicker._placeholder"
                              :input-class="config_delivery_datepicker._input_class"
                              :calendar-button="config_delivery_datepicker._calendar_button_icon.length > 3"
                              :calendar-button-icon="config_delivery_datepicker._calendar_button_icon"
                              :clear-button="((config_delivery_datepicker._clear_button_icon.length > 3))"
                              :clear-button-icon="config_delivery_datepicker._clear_button_icon"
                              :disabled-dates="config_delivery_datepicker._disabled_dates"
                              :disabled="form_book_order.delivery_date._disabled"
                              @selected="onSelectDeliveryDate" @cleared="onClearDeliveryDate"
                          ></vue-date-picker>
                          <span class="required-message" v-if="form_book_order.delivery_date._validation_show && !form_book_order.delivery_date._validate && form_book_order._validation_show" v-html="form_book_order.delivery_date._message"></span>
                        </div>
                      </div>
                      <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                        <div class="single-input">
                          <label>Delivery Time<span class="required pl-1">*</span></label>
                          <select class="form-control" v-model="form_book_order.delivery_time._value" :disabled="form_book_order.delivery_time._disabled">
                            <option value="">Select Delivery Time</option>
                            <option v-for="(item) in config_delivery_datepicker.date_time_slot" :value="item.start_time + '-' + item.end_time">{{item.start_time + ' - ' + item.end_time}}</option>
                          </select>
                          <span class="required-message" v-if="form_book_order.delivery_time._validation_show && !form_book_order.delivery_time._validate && form_book_order._validation_show" v-html="form_book_order.delivery_time._message"></span>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-20">
                      <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15 offset-xl-3 offset-md-3">
                        <div class="single-input"><label data-v-2284792a="">Order Note:</label>
                          <textarea placeholder="Order Details" v-model="form_book_order.order_note._value" rows="3" name="order_note"></textarea>
                          <span class="required-message" v-if="form_book_order.order_note._validation_show" v-html="form_book_order.order_note._message"></span>
                        </div>
                      </div>
                    </div>
                    <div v-if="!is_customer_logged_in > 0" class="row mt-20">
                      <div class="col-xl-8 offset-xl-2">
                        <div class="row mt-40">
                          <div class="col-lg-12">
                            <div class="section-title-two mb-45 mb-xs-30">
                              <h3 class="title">Personal Detail</h3>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input">
                              <label>First Name<span class="required">*</span></label>
                              <input type="text" v-model="form_book_order.first_name._value" placeholder="First Name" name="name">
                              <span class="required-message" v-if="form_book_order.first_name._validation_show && !form_book_order.first_name._validate && form_book_order._validation_show" v-html="form_book_order.first_name._message"></span>
                            </div>
                          </div>
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input">
                              <label>Last Name<span class="required">*</span></label>
                              <input type="text" v-model="form_book_order.last_name._value" placeholder="Last Name" name="name">
                              <span class="required-message" v-if="form_book_order.last_name._validation_show  && !form_book_order.last_name._validate && form_book_order._validation_show" v-html="form_book_order.last_name._message"></span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input position-relative">
                              <label>Username / Email<span class="required">*</span></label>
                              <input type="text" v-model="form_book_order.email_address._value" placeholder="Email Address" name="password">
                              <span class="required-message" v-if="form_book_order.email_address._validation_show && !form_book_order.email_address._validate && form_book_order._validation_show" v-html="form_book_order.email_address._message"></span>
                            </div>
                          </div>
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input">
                              <label>Mobile No<span class="required">*</span></label>
                              <input type="text" v-model="form_book_order.mobile_no._value" placeholder="Mobile No" name="password">
                              <span class="required-message" v-if="form_book_order.mobile_no._validation_show && !form_book_order.mobile_no._validate && form_book_order._validation_show" v-html="form_book_order.mobile_no._message"></span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input">
                              <label>Password<span class="required">*</span></label>
                              <input type="password" v-model="form_book_order.password._value" placeholder="Password" name="password">
                              <span class="required-message" v-if="form_book_order.password._validation_show && !form_book_order.password._validate && form_book_order._validation_show" v-html="form_book_order.password._message"></span>
                            </div>
                          </div>
                          <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="single-input">
                              <label>Confirm Password<span class="required">*</span></label>
                              <input type="password" v-model="form_book_order.confirm_password._value" placeholder="Confirm Password" name="password">
                              <span class="required-message" v-if="form_book_order.confirm_password._validation_show && !form_book_order.confirm_password._validate && form_book_order._validation_show" v-html="form_book_order.confirm_password._message"></span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row mt-20" v-if="coupon_form.show_form">
                      <div class="col-xl-8 offset-xl-2">
                        <div class="row">
                          <div class="col-xl-12 col-md-12 col-sm-12 pl-xs-15 pr-xs-15">
                            <div class="d-flex flex-column p-3 pb-4 register-coupon">
                              <div class="single-input d-flex justify-content-center">
                                <label>If you have a coupon code, please apply it below.</label>
                              </div>
                              <div class="d-flex flex-column">
                                <div class="d-flex flex-row justify-content-center">
                                  <div class="single-input float-none w-50">
                                    <input type="text" v-model="coupon_form.coupon_code._value" :disabled="coupon_form.coupon_code._disabled" class="mb-0 input-coupon" placeholder="Coupon code">
                                  </div>
                                  <button v-if="coupon_form.button_submit._visible" :disabled="coupon_form.button_submit._disabled" type="button" @click="applyCoupon" class="btn-coupon" v-html="coupon_form.button_submit._text"></button>
                                  <button v-if="coupon_form.button_loader._visible" :disabled="coupon_form.button_loader._disabled" type="button" class="btn-loader" v-html="coupon_form.button_loader._text"></button>
                                </div>
                                <div v-if="coupon_form.coupon_code._validation_show" class="d-flex flex-row justify-content-center">
                                  <div class="w-75">
                                    <p class="validation-error" v-html="coupon_form.coupon_code._message"></p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-20">
                      <div class="col-xl-8 offset-xl-2">
                        <div class="row">
                          <div class="col-xl-12 col-md-12 col-sm-12 mt-30">
                            <div class="section-title-two mb-45 mb-xs-30">
                              <h3 class="title">Payment</h3>
                            </div>
                            <div class="row mt-1 mb-10">
                              <div class="col-xl-8 col-md-8 col-sm-12 pl-xs-15 pr-xs-15 offset-xl-2 offset-md-2 text-center">
                                <ul class="list-inline">
                                  <li class="list-inline-item fw-600">
                                    <input type="radio" value="1" v-model="form_book_order.payment_type._value" @change="onChangePaymentType" name="payment_type" id="payment_type_cod">
                                    <label for="payment_type_cod" class="pl-2">Cash On Delivery</label>
                                  </li>
                                  <li class="list-inline-item ml-lg-15 fw-600">
                                    <input type="radio" value="2" v-model="form_book_order.payment_type._value" @change="onChangePaymentType" name="payment_type" id="payment_type_card">
                                    <label for="payment_type_card" class="pl-2">Credit / Debit Card</label>
                                  </li>
                                </ul>
                                <span class="required-message" v-if="form_book_order.payment_type._validation_show && !form_book_order.payment_type._validate && form_book_order._validation_show" v-html="form_book_order.payment_type._message"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="form_book_order.payment_type._value == 2 && is_customer_card_required" class="row ml-0 mr-0 mb-40">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <div class="col-xl-8 offset-xl-2 bg-white">
                          <div class="row pt-4 pb-4">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-1">
                              <stripe-card-elements ref="elmStripeCard" :reset_all="true"></stripe-card-elements>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!is_customer_logged_in > 0" class="row">
                      <div class="col-xl-8 offset-xl-2">
                        <p class="text-center fw-600 mb-30">Note: We have a minimum order value of <i class="price-symbol">£</i>15.00. <br/> So if you submit less than <i class="price-symbol">£</i>15.00 we will round the order up to <i class="price-symbol">£</i>15.00.</p>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4">
                          <button type="button" v-if="form_book_order.button_submit._visible && !form_book_order.button_loader._visible" @click="submitOrderForm" :class="'ht-btn submit fz-18' + ((form_book_order.button_submit._disabled) ? ' disabled' : '')" :disabled="form_book_order.button_submit._disabled" v-html="form_book_order.button_submit._text"></button>
                          <button type="button" v-if="!form_book_order.button_submit._visible && form_book_order.button_loader._visible" :class="'ht-btn submit fz-18' + ((form_book_order.button_loader._disabled) ? ' disabled' : '')" :disabled="form_book_order.button_loader._disabled" v-html="form_book_order.button_loader._text"></button>
                        </div>
                        <div role="alert" v-if="form_book_order.alert_message._show" :class="'alert col-md-12 mt-30 ' + ((form_book_order.alert_message._type != '') ? form_book_order.alert_message._type : 'alert-warning')" v-html="form_book_order.alert_message._text"></div>
                      </div>
                    </div>
                    <div v-if="is_customer_logged_in > 0" class="row">
                      <div class="col-xl-8 offset-xl-2">
                        <p class="text-center fw-600 mb-30">USER_LOGGED Note: We have a minimum order value of <i class="price-symbol">£</i>15.00. <br/> So if you submit less than <i class="price-symbol">£</i>15.00 we will round the order up to <i class="price-symbol">£</i>15.00.</p>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4">
                          <button type="button" v-if="form_book_order.button_my_order_submit._visible && !form_book_order.button_loader._visible" @click="submitMyOrderForm" :class="'ht-btn submit fz-18' + ((form_book_order.button_my_order_submit._disabled) ? ' disabled' : '')" :disabled="form_book_order.button_my_order_submit._disabled" v-html="form_book_order.button_my_order_submit._text"></button>
                          <button type="button" v-if="!form_book_order.button_my_order_submit._visible && form_book_order.button_loader._visible" :class="'ht-btn submit fz-18' + ((form_book_order.button_loader._disabled) ? ' disabled' : '')" :disabled="form_book_order.button_loader._disabled" v-html="form_book_order.button_loader._text"></button>
                        </div>
                        <div role="alert" v-if="form_book_order.alert_message._show" :class="'alert col-md-12 mt-30 ' + ((form_book_order.alert_message._type != '') ? form_book_order.alert_message._type : 'alert-warning')" v-html="form_book_order.alert_message._text"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import TokenService from "@/services/token.service";
import VueDatePicker from 'vuejs-datepicker';
import StripeCardElements from "@/components/StripeCardElements";
import EventBus from "@/common/EventBus";
export default {
  name: "OrderBook",
  components:{
    StripeCardElements,
    VueDatePicker,
  },
  data(){
    return{
      state:{
        disabledDates: {
          to: new Date(2022, 3, 10), // Disable all dates up to specific date
          from: new Date(2022, 3, 20), // Disable all dates after specific date
        }
      },
      week_days_name:["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      config_pickup_datepicker:{
        _placeholder:"Select pickup date",
        _input_class:"form-control",
        _calendar_button_icon:"lnr lnr-calendar-full",
        _clear_button_icon:"lnr lnr-cross",
        _date_with_time_slots:[],
        _disabled_dates:{
          to:"",//StartDate
          from:"",//EndDate
          dates:[],
        },
        _time_slot:[],
      },
      config_delivery_datepicker:{
        _placeholder: "Select delivery date",
        _input_class: "form-control",
        _calendar_button_icon:"lnr lnr-calendar-full",
        _clear_button_icon:"lnr lnr-cross",
        _date_with_time_slots:[],
        _disabled_dates:{
          to:"",//StartDate
          from:"",//EndDate
          dates:[],
        },
        _time_slot:[],
      },
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      services_content:{
        _content_loaded: false,
        _content:{services_list:[]},
      },
      offers_content:{
        _content_loaded: false,
        _content:{offers_list:[]}
      },
      form_book_order:{
        order_type:{
          _value:1, _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        pickup_date:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        pickup_time:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        delivery_date:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        delivery_time:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        order_note:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        first_name:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        last_name:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        email_address:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        mobile_no:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        confirm_password:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        payment_type:{
          _value:0, _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Place Order", _visible:true, _disabled:false,
        },
        button_my_order_submit:{
          _text:"Place My Order", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      order_config:{
        _pickup_charges:0,
        _delivery_charges:0,
        _urgent_charges:0,
        _minimum_order_limit:0,
        _schedule_days:[],
      },
      user_selected_location:{
        Id:"", Type:"", Text:"", Description:""
      },
      selected_service_list:[],
      is_customer_logged_in:0,
      web_page_head:{
        page_title:"Book Order",
        meta_title:"Book Order",
        meta_description:"Book Order"
      },
      is_customer_card_required:true,
      show_coupon_box:false,
      coupon_form:{
        coupon_code:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Apply", _visible:true, _disabled:false
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message: {_type:"", _text:"", _show:false},
        show_form:false,
        applied:false,
        logged_user_email:"",
      },
      get_stripe_token:{},
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    addScript(){
      let scripts = [
        "https://js.stripe.com/v3/",
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    isDateValid(dateStr) {
      return !isNaN(new Date(dateStr).getDate());
    },
    getUkCurrentDateTime(){
      let config_date_time = {default_date_time:"", start_object:{}, end_object:{}};
      let event = new Date();
      let _tmp_dt = event.toLocaleString('en-GB', { timeZone: 'Europe/London' });//08/04/2022, 19:38:27
      //_tmp_dt = "09/04/2022, 19:38:27";

      let _parts = _tmp_dt.split(',');
      let _d = _parts[0], _t = _parts[1].toString().trim();
      let _date_parts = _d.split("/");//"08/04/2022"
      let _time_parts = _t.split(":");//"19:33:16"
      let _date = parseInt(_date_parts[0]), _month = parseInt(_date_parts[1]), _year = parseInt(_date_parts[2]);
      let _hour = parseInt(_time_parts[0]), _minute = parseInt(_time_parts[1]), _second = parseInt(_time_parts[2]);
      let now_uk_date_time = new Date(_year, _month - 1, _date, _hour, _minute, 0);
      config_date_time.default_date_time = new Date(_year, _month - 1, _date, _hour, _minute, 0);
      let objStartDate = {_year:_year, _month:_month - 1, _date:_date, _hour:_hour, _minute:_minute, _second:_second};
      let objEndDate = {_year:_year, _month:_month, _date:_date, _hour:_hour, _minute:_minute, _second:_second};
      config_date_time.start_object = objStartDate;
      config_date_time.end_object = objEndDate;

      return config_date_time;
    },
    form_order_reset(){


    },
    onClickServiceItem(serviceId){
      for(let k in this.services_content._content.services_list){
        if (this.services_content._content.services_list[k].srv_id == serviceId){
          if (this.services_content._content.services_list[k].srv_class == 'active'){
            this.services_content._content.services_list[k].srv_class = "";
          }else{
            this.services_content._content.services_list[k].srv_class = "active";
          }
        }
      }
    },
    setPickupDateTimeSchedule(){
      //console.log("******************** PICKUP SCHEDULE START ********************");
      let objDateTime = this.getUkCurrentDateTime();
      let _pickup_start_date = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, 0,0,0);
      let _pickup_end_date = new Date(objDateTime.end_object._year, objDateTime.end_object._month, objDateTime.end_object._date, 0,0,0);
      this.config_pickup_datepicker._disabled_dates.to = new Date(_pickup_start_date);
      this.config_pickup_datepicker._disabled_dates.from = new Date(_pickup_end_date);
      let _pickup_start_hour = objDateTime.start_object._hour + 1;
      let _tmp_pickup_start_hour = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, _pickup_start_hour, objDateTime.start_object._minute,0);
      let _loop_start = new Date(_pickup_start_date);
      let sno = 1;
      let _tmp_schedule = this.order_config._schedule_days;
      let _tmp_today = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, 0,0,0);
      let _pickup_date_with_time_slot = [];
      let _pickup_disable_dates = [];
      while (_loop_start <= _pickup_end_date){
        let _tmp_year = _loop_start.getFullYear(), _tmp_month = _loop_start.getMonth(), _tmp_date = _loop_start.getDate();
        let _formatted_date = _tmp_year + "-" + ((_tmp_month.toString().length < 2) ? "0" + _tmp_month.toString() : _tmp_month) + "-" + ((_tmp_date.toString().length < 2) ? "0" + _tmp_date.toString() : _tmp_date);
        let _tmp_day = _loop_start.getDay();
        let _obj_pickup_time_slot = {date:_formatted_date, time_slot:[]};
        if (_tmp_today.toString() == _loop_start.toString()){
          let _today_slot = [];
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if(_tmp_schedule[_tmp_day].pickup.length){
              let _ps = _tmp_schedule[_tmp_day].pickup;
              for (let k in _ps){
                let _slot = _ps[k].split('-')[0].trim();
                let _st = parseInt(_slot.split(":")[0]);
                let _stdt = new Date(_tmp_year, _tmp_month, _tmp_date, _st, objDateTime.start_object._minute,0);
                let _difference_in_hour = Math.floor((_stdt.getTime() - _tmp_pickup_start_hour.getTime()) / (1000 * 60 * 60));
                /*if (_stdt.getTime() >= _tmp_pickup_start_hour.getTime()){
                  _today_slot.push(_ps[k]);
                }*/
                if (_difference_in_hour > 1){
                  _today_slot.push(_ps[k]);
                }
              }
            }
          }
          if (_today_slot.length){
            _obj_pickup_time_slot.time_slot = _today_slot;
          }
        }else{
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if (_tmp_schedule[_tmp_day].pickup.length){
              _obj_pickup_time_slot.time_slot = _tmp_schedule[_tmp_day].pickup;
            }
          }
        }
        if (_obj_pickup_time_slot.time_slot.length){
          _pickup_date_with_time_slot.push(_obj_pickup_time_slot);
        }else{
          _pickup_disable_dates.push(new Date(_tmp_year, _tmp_month, _tmp_date, 0,0,0));
        }
        let newDate = _loop_start.setDate(_loop_start.getDate() + 1);
        if (_pickup_disable_dates.length){
          this.config_pickup_datepicker._disabled_dates.dates = _pickup_disable_dates;
        }
        sno++;
      }
      this.config_pickup_datepicker._date_with_time_slots = _pickup_date_with_time_slot;
      //console.log('Pickup Start Date => ', _pickup_start_date);
      //console.log('Pickup End Date => ', _pickup_end_date);
      //console.log('Pickup Start Hour => ', _pickup_start_hour);
      //console.log("******************** PICKUP SCHEDULE END ********************");
    },
    setDeliveryDateTimeSchedule(){
      //console.log("******************** DELIVERY SCHEDULE START ********************");
      let order_hours = ((parseInt(this.form_book_order.order_type._value) == 2) ? 24 : 48);
      let _tmp_pickup_date = new Date(this.form_book_order.pickup_date._value);
      let _tmp_pickup_time_slot = this.form_book_order.pickup_time._value.split("-");
      let _tmp_pickup_start_hour = parseInt(_tmp_pickup_time_slot[0].split(":")[0]);
      let _tmp_pickup_order_date = new Date(_tmp_pickup_date.getFullYear(), _tmp_pickup_date.getMonth(), _tmp_pickup_date.getDate(), _tmp_pickup_start_hour, 0,0);
      let pickup_order_date = new Date(_tmp_pickup_order_date.setHours(_tmp_pickup_order_date.getHours() + order_hours));
      let _delivery_start_date = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth(), pickup_order_date.getDate(), 0,0,0);
      let _delivery_end_date = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth() + 1, pickup_order_date.getDate(), 0,0,0);
      this.config_delivery_datepicker._disabled_dates.to = new Date(_delivery_start_date);
      this.config_delivery_datepicker._disabled_dates.from = new Date(_delivery_end_date);
      let _tmp_schedule = this.order_config._schedule_days;
      let _tmp_delivery_start_hour = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth(), pickup_order_date.getDate(), pickup_order_date.getHours(),0,0);
      let _loop_start = new Date(_delivery_start_date);
      let _delivery_date_with_time_slot = [];
      let _delivery_disable_dates = [];
      while (_loop_start <= _delivery_end_date){
        let _tmp_year = _loop_start.getFullYear(), _tmp_month = _loop_start.getMonth(), _tmp_date = _loop_start.getDate();
        let _formatted_date = _tmp_year + "-" + ((_tmp_month.toString().length < 2) ? "0" + _tmp_month.toString() : _tmp_month) + "-" + ((_tmp_date.toString().length < 2) ? "0" + _tmp_date.toString() : _tmp_date);
        let _tmp_day = _loop_start.getDay();
        let _obj_delivery_time_slot = {date:_formatted_date, time_slot:[]};
        if (_delivery_start_date.toString() == _loop_start.toString()){
          let _today_slot = [];
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            let _ds = _tmp_schedule[_tmp_day].delivery;
            if (_ds.length){
              for (let k in _ds){
                let _slot = _ds[k].split('-')[0].trim();
                let _st = parseInt(_slot.split(":")[0]);
                let _stdt = new Date(_tmp_year, _tmp_month, _tmp_date, _st, 0,0);
                if (_stdt.getTime() >= _tmp_delivery_start_hour.getTime()){
                  _today_slot.push(_ds[k]);
                }
              }
            }
          }
          if(_today_slot.length){
            _obj_delivery_time_slot.time_slot = _today_slot;
          }
        }else{
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if (_tmp_schedule[_tmp_day].delivery.length){
              _obj_delivery_time_slot.time_slot = _tmp_schedule[_tmp_day].delivery;
            }
          }
        }
        if (_obj_delivery_time_slot.time_slot.length){
          _delivery_date_with_time_slot.push(_obj_delivery_time_slot);
        }else{
          _delivery_disable_dates.push(new Date(_tmp_year, _tmp_month, _tmp_date, 0,0,0));
        }
        let newDate = _loop_start.setDate(_loop_start.getDate() + 1);
      }
      if (_delivery_disable_dates.length){
        this.config_delivery_datepicker._disabled_dates.dates = _delivery_disable_dates;
      }
      this.config_delivery_datepicker._date_with_time_slots = _delivery_date_with_time_slot;
      //console.log("******************** DELIVERY SCHEDULE END ********************");
    },
    onSelectPickupDate(date){
      if (date === null){
        return false;
      }
      if (date.toString() == parseInt(date.toString())){
        alert('Pickup date is not valid');
        return false;
      }

      let _start_year = date.getFullYear(), _start_month = date.getMonth(), _start_date = date.getDate();
      let _formatted_date = _start_year + "-" + ((_start_month.toString().length < 2) ? "0" + _start_month.toString() : _start_month.toString()) + "-" + ((_start_date.toString().length < 2) ? "0" + _start_date.toString() : _start_date.toString());
      let objTimeSlot = [];
      for (let k in this.config_pickup_datepicker._date_with_time_slots){
        if (_formatted_date == this.config_pickup_datepicker._date_with_time_slots[k].date){
          objTimeSlot = this.config_pickup_datepicker._date_with_time_slots[k].time_slot;
        }
      }
      let arrTimeSlot = [];
      for (let k in objTimeSlot){
        let _slot = objTimeSlot[k].split(' - ');
        arrTimeSlot.push({start_time:_slot[0], end_time:_slot[1]});
      }
      this.config_pickup_datepicker.date_time_slot = arrTimeSlot;
      this.form_book_order.pickup_time._disabled = false;
      this.form_book_order.pickup_time._value = "";
      this.form_book_order.delivery_date._disabled = true;
      this.form_book_order.delivery_date._value = "";
      this.form_book_order.delivery_time._disabled = true;
      this.form_book_order.delivery_time._value = "";
    },
    onClearPickupDate(){
      this.config_pickup_datepicker.date_time_slot = [];
      this.form_book_order.pickup_time._disabled = true;
      this.form_book_order.pickup_time._value = "";
      this.form_book_order.delivery_date._disabled = true;
      this.form_book_order.delivery_date._value = "";
      this.form_book_order.delivery_time._disabled = true;
      this.form_book_order.delivery_time._value = "";
    },
    onSelectPickupTime(){
      let pickupTime = this.form_book_order.pickup_time._value;
      if (pickupTime.length < 2){
        this.form_book_order.delivery_date._value = "";
        this.form_book_order.delivery_time._value = "";
        this.form_book_order.delivery_date._disabled = true;
        this.form_book_order.delivery_time._disabled = true;

        return false;
      }
      this.form_book_order.delivery_date._disabled = false;
      this.setDeliveryDateTimeSchedule();
    },
    onSelectDeliveryDate(date){
      if (date === null){
        return false;
      }
      if (date.toString() == parseInt(date.toString())){
        alert('Pickup date is not valid');
        return false;
      }
      let _start_year = date.getFullYear(), _start_month = date.getMonth(), _start_date = date.getDate();
      let _formatted_date = _start_year + "-" + ((_start_month.toString().length < 2) ? "0" + _start_month.toString() : _start_month.toString()) + "-" + ((_start_date.toString().length < 2) ? "0" + _start_date.toString() : _start_date.toString());
      let objTimeSlot = [];
      for (let k in this.config_delivery_datepicker._date_with_time_slots){
        if (_formatted_date == this.config_delivery_datepicker._date_with_time_slots[k].date){
          objTimeSlot = this.config_delivery_datepicker._date_with_time_slots[k].time_slot;
        }
      }
      let arrTimeSlot = [];
      for (let k in objTimeSlot){
        let _slot = objTimeSlot[k].split(' - ');
        arrTimeSlot.push({start_time:_slot[0], end_time:_slot[1]});
      }
      this.config_delivery_datepicker.date_time_slot = arrTimeSlot;
      this.form_book_order.delivery_time._disabled = false;
      this.form_book_order.delivery_time._value = "";
    },
    onClearDeliveryDate(){
      this.form_book_order.delivery_date._value = "";
      this.form_book_order.delivery_time._value = "";
      this.form_book_order.delivery_time._disabled = true;
    },
    onClickOrderTypeImage(val){
      this.form_book_order.order_type._value = val;
      this.onChangeOrderType();
    },
    onChangeOrderType(){
      this.form_book_order.pickup_date._disabled = false;
      this.form_book_order.pickup_date._value = "";
      this.form_book_order.pickup_time._disabled = true;
      this.form_book_order.pickup_time._value = "";
      this.form_book_order.delivery_date._disabled = true;
      this.form_book_order.delivery_date._value = "";
      this.form_book_order.delivery_time._disabled = true;
      this.form_book_order.delivery_time._value = "";
    },
    onChangePaymentType(){
      setTimeout(() => {


      }, 1000);
    },
    orderFormValidationCheck(){
      let field_count = 0;
      if (parseInt(this.form_book_order.order_type._value) < 1 && parseInt(this.form_book_order.order_type._value) > 2){
        this.form_book_order.order_type._validation_show = true;
        this.form_book_order.order_type._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_book_order.pickup_date._value)){
        this.form_book_order.pickup_date._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.pickup_time._value.length < 2){
        this.form_book_order.pickup_time._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_book_order.delivery_date._value)){
        this.form_book_order.delivery_date._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.delivery_time._value.length < 2){
        this.form_book_order.delivery_time._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.first_name._value.trim().length < 1){
        this.form_book_order.first_name._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.last_name._value.trim().length < 1){
        this.form_book_order.last_name._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.email_address._value.trim().length < 1){
        this.form_book_order.email_address._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.mobile_no._value.trim().length < 1){
        this.form_book_order.mobile_no._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.password._value.trim().length < 1){
        this.form_book_order.password._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.confirm_password._value.trim().length < 1){
        this.form_book_order.confirm_password._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.password._value !== this.form_book_order.confirm_password._value){
        this.form_book_order.confirm_password._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.payment_type._value < 1){
        this.form_book_order.payment_type._validation_show = true;
        field_count++;
      }
      if (parseInt(this.form_book_order.payment_type._value) == 2){
        if (this.token === null){
          field_count++;
        }
      }
      return (field_count < 1);
    },
    customerOrderFormValidationCheck(){
      let field_count = 0;
      if (parseInt(this.form_book_order.order_type._value) < 1 && parseInt(this.form_book_order.order_type._value) > 2){
        this.form_book_order.order_type._validation_show = true;
        this.form_book_order.order_type._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_book_order.pickup_date._value)){
        this.form_book_order.pickup_date._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.pickup_time._value.length < 2){
        this.form_book_order.pickup_time._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_book_order.delivery_date._value)){
        this.form_book_order.delivery_date._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.delivery_time._value.length < 2){
        this.form_book_order.delivery_time._validation_show = true;
        field_count++;
      }
      if (this.form_book_order.payment_type._value < 1){
        this.form_book_order.payment_type._validation_show = true;
        field_count++;
      }
      return (field_count < 1);
    },
    async submitOrderForm(){



      /*this.form_book_order.button_submit._visible = false;
      this.form_book_order.button_submit._disabled = true;
      this.form_book_order.button_loader._visible = true;
      this.form_book_order.button_loader._disabled = true;*/
      this.form_book_order._validation_show = true;
      if (this.orderFormValidationCheck()){
        this.form_book_order.button_submit._visible = false;
        this.form_book_order.button_submit._disabled = true;
        this.form_book_order.button_loader._visible = true;
        this.form_book_order.button_loader._disabled = true;
        let StripeToken = null;
        if (parseInt(this.form_book_order.payment_type._value) == 2){
          this.$refs.elmStripeCard.createToken().then(
              (response) => {
                if (response.status){
                  StripeToken = response.token;
                }else{
                  this.form_book_order.button_submit._visible = true;
                  this.form_book_order.button_submit._disabled = false;
                  this.form_book_order.button_loader._visible = false;
                  this.form_book_order.button_loader._disabled = false;
                  console.log(response);
                }
              },
          ).catch(
              (err) => {
                this.form_book_order.button_submit._visible = true;
                this.form_book_order.button_submit._disabled = false;
                this.form_book_order.button_loader._visible = false;
                this.form_book_order.button_loader._disabled = false;
              }
          );
          await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
          if (!Object.keys(StripeToken).length){
            await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
            if (!Object.keys(StripeToken).length){
              alert('Credit card verification failed.');
              return false;
            }
          }
          if (!Object.keys(StripeToken).length){
            return false;
          }
        }
        let formData = new FormData();
        this.selected_service_list = [];
        for(let k in this.form_book_order){
          if (typeof this.form_book_order[k]._value !== 'undefined'){
            //var today = new Date().toISOString().slice(0, 10);
            let data = this.form_book_order[k]._value;
            if (k == 'pickup_date'){
              data = new Date(this.form_book_order[k]._value).toISOString().slice(0, 10);
            }else if (k == 'delivery_date'){
              data = new Date(this.form_book_order[k]._value).toISOString().slice(0, 10);
            }
            formData.append(k, data);
          }
        }
        for (let s in this.services_content._content.services_list){
          if (this.services_content._content.services_list[s].srv_class == 'active'){
            this.selected_service_list.push(parseInt(this.services_content._content.services_list[s].srv_id))
          }
        }
        for(let u in this.user_selected_location){
          formData.append('location_' + u, this.user_selected_location[u]);
        }

        if (this.coupon_form.coupon_code._value.trim().length && this.coupon_form.applied){
          formData.append("coupon_code", this.coupon_form.coupon_code._value);
        }
        let selected_offers = [];
        for (let k in this.offers_content._content.offers_list){
          if (this.offers_content._content.offers_list[k].selected){
            selected_offers.push(this.offers_content._content.offers_list[k].off_id);
          }
        }

        formData.append('spgt', JSON.stringify(StripeToken));
        formData.append('selected_service_list', this.selected_service_list);
        formData.append('selected_offers_list', selected_offers);
        UserService.postOrderBookWithRegistration(formData).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                this.form_book_order.alert_message._show = true;
                this.form_book_order.alert_message._type = "alert-success";
                this.form_book_order.alert_message._text = jsonResponse.message + '<br/>Please wait...';
                let localUser = TokenService.getUser();
                localUser.user_id = jsonResponse.data.user_id;
                localUser.user = jsonResponse.data.user;
                localUser.order = jsonResponse.data.order;
                TokenService.setUser(localUser);
                setTimeout(() => {
                  this.$router.push("/order-confirmed");
                }, 1500);
              }else{
                this.form_book_order.alert_message._show = true;
                this.form_book_order.alert_message._type = "alert-warning";
                this.form_book_order.alert_message._text = jsonResponse.message;
                this.form_book_order.button_submit._visible = true;
                this.form_book_order.button_submit._disabled = false;
                this.form_book_order.button_loader._visible = false;
              }
            }
        ).catch((err)=>{
          this.form_book_order.button_submit._visible = true;
          this.form_book_order.button_submit._disabled = false;
          this.form_book_order.button_loader._visible = false;
          this.form_book_order.button_loader._disabled = false;
        });
      }else{
        return false;
      }


    },
    async submitMyOrderForm(){
      this.form_book_order._validation_show = true;
      if (this.customerOrderFormValidationCheck()){
        this.form_book_order.button_my_order_submit._visible = false;
        this.form_book_order.button_my_order_submit._disabled = true;
        this.form_book_order.button_loader._visible = true;
        this.form_book_order.button_loader._disabled = true;

        let StripeToken = null;

        if (parseInt(this.form_book_order.payment_type._value) == 2 && this.is_customer_card_required){


          await this.$refs.elmStripeCard.createToken().then(
              (response) => {
                if (!response.status){
                  this.form_book_order.button_my_order_submit._visible = true;
                  this.form_book_order.button_my_order_submit._disabled = false;
                  this.form_book_order.button_loader._visible = false;
                  this.form_book_order.button_loader._disabled = false;
                  StripeToken = {};
                  return false;
                }
                StripeToken = response.token;
              }
          );
          if (!Object.keys(StripeToken).length){
            return false;
          }
        }
        let formData = new FormData();
        formData.append("order_type", this.form_book_order.order_type._value);
        formData.append("pickup_date", new Date(this.form_book_order.pickup_date._value).toISOString().slice(0, 10));
        formData.append("pickup_time", this.form_book_order.pickup_time._value);
        formData.append("delivery_date", new Date(this.form_book_order.delivery_date._value).toISOString().slice(0, 10))
        formData.append("delivery_time", this.form_book_order.delivery_time._value);
        formData.append("order_note", this.form_book_order.order_note._value);
        formData.append("payment_type", this.form_book_order.payment_type._value);
        if (!this.is_customer_card_required){
          formData.append("cc_required", false);
        }
        this.selected_service_list = [];
        for (let s in this.services_content._content.services_list){
          if (this.services_content._content.services_list[s].srv_class == 'active'){
            this.selected_service_list.push(parseInt(this.services_content._content.services_list[s].srv_id))
          }
        }
        let selected_offers = [];
        for (let k in this.offers_content._content.offers_list){
          if (this.offers_content._content.offers_list[k].selected){
            selected_offers.push(this.offers_content._content.offers_list[k].off_id);
          }
        }
        for(let u in this.user_selected_location){
          formData.append('location_' + u, this.user_selected_location[u]);
        }
        if (this.coupon_form.coupon_code._value.trim().length && this.coupon_form.applied){
          formData.append("coupon_code", this.coupon_form.coupon_code._value);
        }
        formData.append('spgt', JSON.stringify(StripeToken));
        formData.append('selected_service_list', this.selected_service_list);
        formData.append('selected_offers_list', selected_offers);
        UserService.postCustomerOrderBook(formData).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                this.form_book_order.alert_message._show = true;
                this.form_book_order.alert_message._type = "alert-success";
                this.form_book_order.alert_message._text = jsonResponse.message + '<br/>Please wait...';
                let localUser = TokenService.getUser();
                localUser.order = jsonResponse.data.order;
                TokenService.setUser(localUser);
                setTimeout(() => {
                  this.$router.push("/order-confirmed");
                }, 1500);
              }else{
                this.form_book_order.alert_message._show = true;
                this.form_book_order.alert_message._type = "alert-warning";
                this.form_book_order.alert_message._text = jsonResponse.message;
                this.form_book_order.button_my_order_submit._visible = true;
                this.form_book_order.button_my_order_submit._disabled = false;
                this.form_book_order.button_loader._visible = false;
              }
            },
            (error) => {
              this.form_book_order.alert_message._show = true;
              this.form_book_order.alert_message._type = "alert-danger";
              this.form_book_order.alert_message._text = error.message;
              this.form_book_order.button_my_order_submit._visible = true;
              this.form_book_order.button_my_order_submit._disabled = false;
              this.form_book_order.button_loader._visible = false;
            }
        );
      }else{
        return false;
      }
    },
    doAjax(){
      UserService.getOrderBook().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  if (typeof jsonResponse.data.coupon_available !== 'undefined'){
                    this.coupon_form.show_form = jsonResponse.data.coupon_available;
                  }
                  if (typeof jsonResponse.data.offers !== 'undefined'){
                    this.offers_content._content.offers_list = jsonResponse.data.offers.map( (obj) => (
                        {
                          ...obj,
                          selected:false,
                        }
                    ) );
                    this.offers_content._content_loaded = true;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.services !== 'undefined'){
                this.services_content._content = jsonResponse.data.services;
                this.services_content._content_loaded = true;
              }
              if (typeof jsonResponse.data.pickup_charges !== 'undefined'){
                this.order_config._pickup_charges = parseFloat(jsonResponse.data.pickup_charges);
              }
              if (typeof jsonResponse.data.delivery_charges !== 'undefined'){
                this.order_config._delivery_charges = parseFloat(jsonResponse.data.delivery_charges);
              }
              if (typeof jsonResponse.data.urgent_charges !== 'undefined'){
                this.order_config._urgent_charges = parseFloat(jsonResponse.data.urgent_charges);
              }
              if (typeof jsonResponse.data.minimum_order_limit !== 'undefined'){
                this.order_config._minimum_order_limit = parseFloat(jsonResponse.data.minimum_order_limit);
              }
              if (typeof jsonResponse.data.order_schedule !== 'undefined'){
                this.order_config._schedule_days = jsonResponse.data.order_schedule;
                this.setPickupDateTimeSchedule();
              }
              if (typeof jsonResponse.data.credit_card_required !== 'undefined'){
                this.is_customer_card_required = jsonResponse.data.credit_card_required;
              }
            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
    applyCoupon(){
      if (!this.coupon_form.coupon_code._value.trim().length){
        this.coupon_form.coupon_code._validation_show = true;
        return ;
      }
      this.coupon_form.button_submit._visible = false;
      this.coupon_form.button_loader._visible = true;
      this.coupon_form.button_loader._disabled = true;
      let formData = new FormData();
      formData.append("coupon_code", this.coupon_form.coupon_code._value);
      if (this.is_customer_logged_in){
        formData.append("email_address", this.logged_user_email);
      }else{
        formData.append("email_address", this.form_book_order.email_address._value);
      }

      UserService.postCouponVerify(formData).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              this.coupon_form.coupon_code._disabled = true;
              this.coupon_form.button_submit._disabled = true;
              this.coupon_form.button_submit._visible = true;
              this.coupon_form.button_loader._visible = false;
              this.coupon_form.button_loader._disabled = false;
              this.coupon_form.button_submit._text = "Applied";
              this.coupon_form.coupon_code._validation_show = false;
              this.coupon_form.applied = true;
            }else{
              this.coupon_form.coupon_code._validation_show = true;
              this.coupon_form.coupon_code._message = jsonResponse.message;
              this.coupon_form.coupon_code._disabled = false;
              this.coupon_form.button_submit._disabled = false;
              this.coupon_form.button_submit._visible = true;
              this.coupon_form.button_loader._visible = false;
              this.coupon_form.button_loader._disabled = false;
              this.coupon_form.button_submit._text = "Apply";
            }
          },
          (error) => {
            this.coupon_form.button_submit._visible = true;
            this.coupon_form.button_loader._visible = false;
            this.coupon_form.button_loader._disabled = false;
            this.coupon_form.coupon_code._message = error.text;
            this.coupon_form.button_submit._text = "Apply";
          }
      );

    },
  },
  mounted() {
    this.doAjax();
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      let user = TokenService.getUser();
      if (typeof user.location !== 'undefined'){
        this.user_selected_location = user.location;
      }
      if (typeof user.user_id !== 'undefined'){
        this.logged_user_email = user.user.email_address;
        this.is_customer_logged_in = parseInt(user.user_id);
      }
    }
    if (this.user_selected_location.Text.length < 3){
      this.$router.push('/');
    }
    EventBus.on("evtStripeToken", (data) => {
      console.log(data);
    });

  },
  watch:{
    'form_book_order.pickup_date._value':function(newVal, oldVal){
      if (newVal === null) {
        this.form_book_order.pickup_date._validation_show = true;
        this.form_book_order.pickup_date._validate = false;
      }else if(newVal === ''){
        this.form_book_order.pickup_date._validation_show = true;
        this.form_book_order.pickup_date._validate = false;
      }else if(!this.isDateValid(newVal)){
        this.form_book_order.pickup_date._validation_show = true;
        this.form_book_order.pickup_date._validate = false;
      }else if (this.isDateValid(newVal)){
        this.form_book_order.pickup_date._validation_show = false;
        this.form_book_order.pickup_date._validate = true;
      }
    },
    'form_book_order.pickup_time._value':function(newVal, oldVal){
      if (newVal === ''){
        this.form_book_order.pickup_time._validation_show = true;
        this.form_book_order.pickup_time._validate = false;
      }else{
        this.form_book_order.pickup_time._validation_show = false;
        this.form_book_order.pickup_time._validate = true;
      }
    },
    'form_book_order.delivery_date._value':function(newVal, oldVal){
      if (newVal === null) {
        this.form_book_order.delivery_date._validation_show = true;
        this.form_book_order.delivery_date._validate = false;
      }else if(newVal === ''){
        this.form_book_order.delivery_date._validation_show = true;
        this.form_book_order.delivery_date._validate = false;
      }else if(!this.isDateValid(newVal)){
        this.form_book_order.delivery_date._validation_show = true;
        this.form_book_order.delivery_date._validate = false;
      }else if (this.isDateValid(newVal)){
        this.form_book_order.delivery_date._validation_show = false;
        this.form_book_order.delivery_date._validate = true;
      }
    },
    'form_book_order.delivery_time._value':function(newVal, oldVal){
      if (newVal === ''){
        this.form_book_order.delivery_time._validation_show = true;
        this.form_book_order.delivery_time._validate = false;
      }else{
        this.form_book_order.delivery_time._validation_show = false;
        this.form_book_order.delivery_time._validate = true;
      }
    },
    'form_book_order.first_name._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_book_order.first_name._validation_show = true;
        this.form_book_order.first_name._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.first_name._validation_show = true;
        this.form_book_order.first_name._validate = false;
      }else{
        this.form_book_order.first_name._validation_show = false;
        this.form_book_order.first_name._validate = true;
      }
    },
    'form_book_order.last_name._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_book_order.last_name._validation_show = true;
        this.form_book_order.last_name._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.last_name._validation_show = true;
        this.form_book_order.last_name._validate = false;
      }else{
        this.form_book_order.last_name._validation_show = false;
        this.form_book_order.last_name._validate = true;
      }
    },
    'form_book_order.email_address._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_book_order.email_address._validation_show = true;
        this.form_book_order.email_address._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.email_address._validation_show = true;
        this.form_book_order.email_address._validate = false;
      }else{
        this.form_book_order.email_address._validation_show = false;
        this.form_book_order.email_address._validate = true;
      }
    },
    'form_book_order.mobile_no._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_book_order.mobile_no._validation_show = true;
        this.form_book_order.mobile_no._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.mobile_no._validation_show = true;
        this.form_book_order.mobile_no._validate = false;
      }else{
        this.form_book_order.mobile_no._validation_show = false;
        this.form_book_order.mobile_no._validate = true;
      }
    },
    'form_book_order.password._value':function(newVal, oldVal){
      if (newVal === '') {
        this.form_book_order.password._validation_show = true;
        this.form_book_order.password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.password._validation_show = true;
        this.form_book_order.password._validate = false;
      }else{
        this.form_book_order.password._validation_show = false;
        this.form_book_order.password._validate = true;
      }
    },
    'form_book_order.confirm_password._value':function(newVal, oldVal){
      this.form_book_order.confirm_password._message = "This field is required.";
      if (newVal === '') {
        this.form_book_order.confirm_password._validation_show = true;
        this.form_book_order.confirm_password._validate = false;
      }else if (newVal.toString().trim().length < 2){
        this.form_book_order.confirm_password._validation_show = true;
        this.form_book_order.confirm_password._validate = false;
      }else if (newVal !== this.form_book_order.password._value) {
        this.form_book_order.confirm_password._message = "Confirm password not matched.";
        this.form_book_order.confirm_password._validation_show = true;
        this.form_book_order.confirm_password._validate = false;
      }else{
        this.form_book_order.confirm_password._validation_show = false;
        this.form_book_order.confirm_password._validate = true;
      }
    },
    'form_book_order.payment_type._value':function(newVal, oldVal){
      if (newVal > 0 && newVal < 3){
        this.form_book_order.payment_type._validation_show = false;
        this.form_book_order.payment_type._validate = true;
      }
    },
  },
}
</script>

<style scoped>
.custom-checkbox .custom-control-label{
  cursor: pointer;
}

</style>