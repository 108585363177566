<template>
  <div class="about">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase">About</h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <blog-post></blog-post>
    <div v-if="about_content._content_loaded" class="about-content-section section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-content">
              <p class="text-bold"></p>
              <p class="normal-text pb-15 text-center" style="border: 0px none;" v-html="about_content._content.web_description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="about_content._content.content_multiple.length" class="personal-skill-section section pt-0 pb-60">
      <div class="container">
        <div class="row no-gutters border-top-left">
          <div v-for="(item, idx) in about_content._content.content_multiple" class="col-lg-4 col-md-6 col-sm-6 d-flex">
            <div class="single-personal-skill">
              <div class="skill-icon">
                <img :src="item.web_image" :alt="item.web_heading">
              </div>
              <div class="personal-skill-content">
                <h4 class="title" v-html="item.web_heading"></h4>
                <p style="white-space: pre-wrap;" v-html="item.web_pera"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserService from "@/services/user.service";

export default {
  name: 'AboutView',
  components: {},
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      about_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      web_page_head:{
        page_title:"About",
        meta_title:"About",
        meta_description:"About"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getAboutContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }

              }
              if (typeof jsonResponse.data.about_content !== 'undefined'){
                this.about_content._content = jsonResponse.data.about_content;
                this.about_content._content_loaded = true;
              }
              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>
