<template>
<div class="orderReschedule">
  <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
          <div class="page-breadcrumb-content color-white text-center">
            <h1 class="text-uppercase">Order Reschedule: {{selected_order_no}}</h1>
            <ul class="page-breadcrumb color-white justify-content-center">
              <li><router-link to="/" class="router-link-active">Home</router-link></li>
              <li><router-link to="/my-orders">My Orders</router-link></li>
              <li>Order Reschedule</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="job-listing-section section bg_color--1 pt-60 pb-120 pb-lg-100 pb-md-80 pb-sm-60 pb-xs-50">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-two mb-45 mb-xs-30">
            <h3 class="title">Choose Services <small style="font-size: 16px; letter-spacing: 0.4px; text-transform: none;">(Optional)</small></h3>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-12 order-lg-2 order-1">
          <div class="row">
            <div v-for="(item) in services_content._content.services_list" class="col-6 col-md-3 col-lg-2 col-xl-2 mb-20">
              <div id="service_1ab" :class="'single-job-grid-two ' + item.srv_class" @click="onClickServiceItem(item.srv_id)">
                <div class="job-image">
                  <a><img :src="item.srv_icon" :alt="item.srv_title"></a>
                </div>
                <div class="job-info">
                  <div class="job-info-top">
                    <div class="title-name">
                      <h3 class="job-title">
                        <a href="#" class="d-block text-truncate" v-html="item.srv_title"></a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>






  <div class="job-listing-section bg_color--3 section pb-60">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lp-12 col-lg-12">

          <div class="job-map-wrapper">
            <div class="job-filter">
              <div class="content-search">
                <div class="section-filter filter-advance">
                  <div class="row mt-20">
                    <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                      <div class="single-input datepicker">
                        <label>Pickup Date<span class="required pl-1">*</span></label>
                        <vue-date-picker v-model="form_reschedule.pickup_date._value"
                                         :placeholder="config_pickup_datepicker._placeholder"
                                         :input-class="config_pickup_datepicker._input_class"
                                         :calendar-button="config_pickup_datepicker._calendar_button_icon.length > 3"
                                         :calendar-button-icon="config_pickup_datepicker._calendar_button_icon"
                                         :clear-button="((config_pickup_datepicker._clear_button_icon.length > 3))"
                                         :clear-button-icon="config_pickup_datepicker._clear_button_icon"
                                         :disabled-dates="config_pickup_datepicker._disabled_dates"
                                         @selected="onSelectPickupDate" @cleared="onClearPickupDate"
                        ></vue-date-picker>
                        <span class="required-message" v-if="form_reschedule.pickup_date._validation_show && !form_reschedule.pickup_date._validate && form_reschedule._validation_show" v-html="form_reschedule.pickup_date._message"></span>
                      </div>
                    </div>
                    <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                      <div class="single-input">
                        <label>Pickup Time<span class="required pl-1">*</span></label>
                        <select class="form-control" v-model="form_reschedule.pickup_time._value" :disabled="form_reschedule.pickup_time._disabled" name="sel_pickuptime" @change="onSelectPickupTime">
                          <option value="">Select Pickup Time</option>
                          <option v-for="(item) in config_pickup_datepicker.date_time_slot" :value="item.start_time + '-' + item.end_time">{{item.start_time + ' - ' + item.end_time}}</option>
                        </select>
                        <span class="required-message" v-if="form_reschedule.pickup_time._validation_show && !form_reschedule.pickup_time._validate && form_reschedule._validation_show" v-html="form_reschedule.pickup_time._message"></span>
                      </div>
                    </div>
                    <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                      <div class="single-input datepicker">
                        <label>Delivery Date<span class="required pl-1">*</span></label>
                        <vue-date-picker v-model="form_reschedule.delivery_date._value"
                                         :placeholder="config_delivery_datepicker._placeholder"
                                         :input-class="config_delivery_datepicker._input_class"
                                         :calendar-button="config_delivery_datepicker._calendar_button_icon.length > 3"
                                         :calendar-button-icon="config_delivery_datepicker._calendar_button_icon"
                                         :clear-button="((config_delivery_datepicker._clear_button_icon.length > 3))"
                                         :clear-button-icon="config_delivery_datepicker._clear_button_icon"
                                         :disabled-dates="config_delivery_datepicker._disabled_dates"
                                         :disabled="form_reschedule.delivery_date._disabled"
                                         @selected="onSelectDeliveryDate" @cleared="onClearDeliveryDate"
                        ></vue-date-picker>
                        <span class="required-message" v-if="form_reschedule.delivery_date._validation_show && !form_reschedule.delivery_date._validate && form_reschedule._validation_show" v-html="form_reschedule.delivery_date._message"></span>
                      </div>
                    </div>
                    <div class="col-6 col-xl-3 col-lg-3 col-lp-3 col-md-3">
                      <div class="single-input">
                        <label>Delivery Time<span class="required pl-1">*</span></label>
                        <select class="form-control" v-model="form_reschedule.delivery_time._value" :disabled="form_reschedule.delivery_time._disabled">
                          <option value="">Select Delivery Time</option>
                          <option v-for="(item) in config_delivery_datepicker.date_time_slot" :value="item.start_time + '-' + item.end_time">{{item.start_time + ' - ' + item.end_time}}</option>
                        </select>
                        <span class="required-message" v-if="form_reschedule.delivery_time._validation_show && !form_reschedule.delivery_time._validate && form_reschedule._validation_show" v-html="form_reschedule.delivery_time._message"></span>
                      </div>
                    </div>


                  </div>
                  <div class="row mt-20">
                    <div class="col-xl-6 col-md-6 col-sm-12 pl-xs-15 pr-xs-15 offset-xl-3 offset-md-3">
                      <div class="single-input"><label data-v-2284792a="">Order Note:</label>
                        <textarea placeholder="Order Details" v-model="form_reschedule.order_note._value" rows="3" name="order_note"></textarea>
                        <span class="required-message" v-if="form_reschedule.order_note._validation_show" v-html="form_reschedule.order_note._message"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-8 offset-xl-2 mt-40">
                      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4">
                        <button type="button" v-if="form_reschedule.button_submit._visible && !form_reschedule.button_loader._visible" @click="submitOrderReschedule" :class="'ht-btn submit fz-18' + ((form_reschedule.button_submit._disabled) ? ' disabled' : '')" :disabled="form_reschedule.button_submit._disabled" v-html="form_reschedule.button_submit._text"></button>
                        <button type="button" v-if="!form_reschedule.button_submit._visible && form_reschedule.button_loader._visible" :class="'ht-btn submit fz-18' + ((form_reschedule.button_loader._disabled) ? ' disabled' : '')" :disabled="form_reschedule.button_loader._disabled" v-html="form_reschedule.button_loader._text"></button>
                      </div>
                      <div role="alert" v-if="form_reschedule.alert_message._show" :class="'alert col-md-12 mt-30 ' + ((form_reschedule.alert_message._type != '') ? form_reschedule.alert_message._type : 'alert-warning')" v-html="form_reschedule.alert_message._text"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>









</div>
</template>

<script>
import VueDatePicker from 'vuejs-datepicker';
import TokenService from "@/services/token.service";
import UserService from "@/services/user.service";

export default {
  name: "OrderReschedule",
  components:{
    VueDatePicker,
  },
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      selected_order_no:"",
      services_content:{
        _content_loaded: false,
        _content:{services_list:[]},
      },
      order_content:{
        _content_loaded: false,
        _content: {
          order_id:0,
          order_prefix:"",
          order_message:"",
        },

      },
      config_pickup_datepicker:{
        _placeholder:"Select pickup date",
        _input_class:"form-control",
        _calendar_button_icon:"lnr lnr-calendar-full",
        _clear_button_icon:"lnr lnr-cross",
        _date_with_time_slots:[],
        _disabled_dates:{
          to:"",//StartDate
          from:"",//EndDate
          dates:[],
        },
        _time_slot:[],
      },
      config_delivery_datepicker:{
        _placeholder: "Select delivery date",
        _input_class: "form-control",
        _calendar_button_icon:"lnr lnr-calendar-full",
        _clear_button_icon:"lnr lnr-cross",
        _date_with_time_slots:[],
        _disabled_dates:{
          to:"",//StartDate
          from:"",//EndDate
          dates:[],
        },
        _time_slot:[],
      },
      form_reschedule:{
        order_type:{
          _value:1, _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        pickup_date:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        pickup_time:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        delivery_date:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        delivery_time:{
          _value:"", _validate:false, _disabled:true, _message:"This field is required.", _validation_show:false,
        },
        order_note:{
          _value:"", _validate:false, _disabled:false, _message:"This field is required.", _validation_show:false,
        },
        button_submit:{
          _text:"Reschedule Order", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      selected_order_data:{},
      order_config:{
        _pickup_charges:0,
        _delivery_charges:0,
        _urgent_charges:0,
        _minimum_order_limit:0,
        _schedule_days:[],
      },
      selected_service_list:[],
      web_page_head:{
        page_title:"Order Reschedule",
        meta_title:"Order Reschedule",
        meta_description:"Order Reschedule"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    this.selected_order_no = this.$route.params.orderNo;


  },
  methods:{
    isDateValid(dateStr) {
      //console.log('Date Validation => ', !isNaN(new Date(dateStr).getDate()));
      return !isNaN(new Date(dateStr).getDate());
    },
    getUkCurrentDateTime(){
      let config_date_time = {default_date_time:"", start_object:{}, end_object:{}};
      let event = new Date();
      let _tmp_dt = event.toLocaleString('en-GB', { timeZone: 'Europe/London' });//08/04/2022, 19:38:27
      //_tmp_dt = "09/04/2022, 19:38:27";
      //sconsole.log("******************** UK NOW DATE TIME ["+_tmp_dt+"] ********************");
      let _parts = _tmp_dt.split(',');
      let _d = _parts[0], _t = _parts[1].toString().trim();
      let _date_parts = _d.split("/");//"08/04/2022"
      let _time_parts = _t.split(":");//"19:33:16"
      let _date = parseInt(_date_parts[0]), _month = parseInt(_date_parts[1]), _year = parseInt(_date_parts[2]);
      let _hour = parseInt(_time_parts[0]), _minute = parseInt(_time_parts[1]), _second = parseInt(_time_parts[2]);
      let now_uk_date_time = new Date(_year, _month - 1, _date, _hour, _minute, 0);
      config_date_time.default_date_time = new Date(_year, _month - 1, _date, _hour, _minute, 0);
      let objStartDate = {_year:_year, _month:_month - 1, _date:_date, _hour:_hour, _minute:_minute, _second:_second};
      let objEndDate = {_year:_year, _month:_month, _date:_date, _hour:_hour, _minute:_minute, _second:_second};
      config_date_time.start_object = objStartDate;
      config_date_time.end_object = objEndDate;
      return config_date_time;
    },
    setPickupDateTimeSchedule(){
      //console.log("******************** PICKUP SCHEDULE START ********************");
      let objDateTime = this.getUkCurrentDateTime();
      let _pickup_start_date = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, 0,0,0);
      let _pickup_end_date = new Date(objDateTime.end_object._year, objDateTime.end_object._month, objDateTime.end_object._date, 0,0,0);
      this.config_pickup_datepicker._disabled_dates.to = new Date(_pickup_start_date);
      this.config_pickup_datepicker._disabled_dates.from = new Date(_pickup_end_date);
      let _pickup_start_hour = objDateTime.start_object._hour + 1;
      let _tmp_pickup_start_hour = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, _pickup_start_hour, objDateTime.start_object._hour,0);
      let _loop_start = new Date(_pickup_start_date);
      let sno = 1;
      let _tmp_schedule = this.order_config._schedule_days;
      let _tmp_today = new Date(objDateTime.start_object._year, objDateTime.start_object._month, objDateTime.start_object._date, 0,0,0);
      let _pickup_date_with_time_slot = [];
      let _pickup_disable_dates = [];
      while (_loop_start <= _pickup_end_date){
        let _tmp_year = _loop_start.getFullYear(), _tmp_month = _loop_start.getMonth(), _tmp_date = _loop_start.getDate();
        let _formatted_date = _tmp_year + "-" + ((_tmp_month.toString().length < 2) ? "0" + _tmp_month.toString() : _tmp_month) + "-" + ((_tmp_date.toString().length < 2) ? "0" + _tmp_date.toString() : _tmp_date);
        let _tmp_day = _loop_start.getDay();
        let _obj_pickup_time_slot = {date:_formatted_date, time_slot:[]};
        if (_tmp_today.toString() == _loop_start.toString()){
          let _today_slot = [];
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if(_tmp_schedule[_tmp_day].pickup.length){
              let _ps = _tmp_schedule[_tmp_day].pickup;
              for (let k in _ps){
                let _slot = _ps[k].split('-')[0].trim();
                let _st = parseInt(_slot.split(":")[0]);
                let _stdt = new Date(_tmp_year, _tmp_month, _tmp_date, _st, objDateTime.start_object._minute,0);
                let _difference_in_hour = Math.floor((_stdt.getTime() - _tmp_pickup_start_hour.getTime()) / (1000 * 60 * 60));
                //if (_stdt.getTime() >= _tmp_pickup_start_hour.getTime()){
                if (_difference_in_hour > 1){
                  _today_slot.push(_ps[k]);
                }
              }
            }
          }
          if (_today_slot.length){
            _obj_pickup_time_slot.time_slot = _today_slot;
          }
        }else{
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if (_tmp_schedule[_tmp_day].pickup.length){
              _obj_pickup_time_slot.time_slot = _tmp_schedule[_tmp_day].pickup;
            }
          }
        }
        if (_obj_pickup_time_slot.time_slot.length){
          _pickup_date_with_time_slot.push(_obj_pickup_time_slot);
        }else{
          _pickup_disable_dates.push(new Date(_tmp_year, _tmp_month, _tmp_date, 0,0,0));
        }
        let newDate = _loop_start.setDate(_loop_start.getDate() + 1);
        if (_pickup_disable_dates.length){
          this.config_pickup_datepicker._disabled_dates.dates = _pickup_disable_dates;
        }
        sno++;
      }
      this.config_pickup_datepicker._date_with_time_slots = _pickup_date_with_time_slot;
      //console.log("******************** PICKUP SCHEDULE END ********************");
    },
    setDeliveryDateTimeSchedule(){
      //console.log("******************** DELIVERY SCHEDULE START ********************");
      let order_hours = ((parseInt(this.form_reschedule.order_type._value) == 2) ? 24 : 48);
      let _tmp_pickup_date = new Date(this.form_reschedule.pickup_date._value);
      let _tmp_pickup_time_slot = this.form_reschedule.pickup_time._value.split("-");
      let _tmp_pickup_start_hour = parseInt(_tmp_pickup_time_slot[0].split(":")[0]);
      let _tmp_pickup_order_date = new Date(_tmp_pickup_date.getFullYear(), _tmp_pickup_date.getMonth(), _tmp_pickup_date.getDate(), _tmp_pickup_start_hour, 0,0);
      let pickup_order_date = new Date(_tmp_pickup_order_date.setHours(_tmp_pickup_order_date.getHours() + order_hours));
      let _delivery_start_date = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth(), pickup_order_date.getDate(), 0,0,0);
      let _delivery_end_date = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth() + 1, pickup_order_date.getDate(), 0,0,0);
      this.config_delivery_datepicker._disabled_dates.to = new Date(_delivery_start_date);
      this.config_delivery_datepicker._disabled_dates.from = new Date(_delivery_end_date);
      let _tmp_schedule = this.order_config._schedule_days;
      let _tmp_delivery_start_hour = new Date(pickup_order_date.getFullYear(), pickup_order_date.getMonth(), pickup_order_date.getDate(), pickup_order_date.getHours(),0,0);
      let _loop_start = new Date(_delivery_start_date);
      let _delivery_date_with_time_slot = [];
      let _delivery_disable_dates = [];
      while (_loop_start <= _delivery_end_date){
        let _tmp_year = _loop_start.getFullYear(), _tmp_month = _loop_start.getMonth(), _tmp_date = _loop_start.getDate();
        let _formatted_date = _tmp_year + "-" + ((_tmp_month.toString().length < 2) ? "0" + _tmp_month.toString() : _tmp_month) + "-" + ((_tmp_date.toString().length < 2) ? "0" + _tmp_date.toString() : _tmp_date);
        let _tmp_day = _loop_start.getDay();
        let _obj_delivery_time_slot = {date:_formatted_date, time_slot:[]};
        if (_delivery_start_date.toString() == _loop_start.toString()){
          let _today_slot = [];
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            let _ds = _tmp_schedule[_tmp_day].delivery;
            if (_ds.length){
              for (let k in _ds){
                let _slot = _ds[k].split('-')[0].trim();
                let _st = parseInt(_slot.split(":")[0]);
                let _stdt = new Date(_tmp_year, _tmp_month, _tmp_date, _st, 0,0);
                if (_stdt.getTime() >= _tmp_delivery_start_hour.getTime()){
                  _today_slot.push(_ds[k]);
                }
              }
            }
          }
          if(_today_slot.length){
            _obj_delivery_time_slot.time_slot = _today_slot;
          }
        }else{
          if (typeof _tmp_schedule[_tmp_day] !== 'undefined'){
            if (_tmp_schedule[_tmp_day].delivery.length){
              _obj_delivery_time_slot.time_slot = _tmp_schedule[_tmp_day].delivery;
            }
          }
        }
        if (_obj_delivery_time_slot.time_slot.length){
          _delivery_date_with_time_slot.push(_obj_delivery_time_slot);
        }else{
          _delivery_disable_dates.push(new Date(_tmp_year, _tmp_month, _tmp_date, 0,0,0));
        }
        let newDate = _loop_start.setDate(_loop_start.getDate() + 1);
      }
      if (_delivery_disable_dates.length){
        this.config_delivery_datepicker._disabled_dates.dates = _delivery_disable_dates;
      }
      this.config_delivery_datepicker._date_with_time_slots = _delivery_date_with_time_slot;
      //console.log("******************** DELIVERY SCHEDULE END ********************");
    },
    onClickServiceItem(serviceId){
      for(let k in this.services_content._content.services_list){
        if (this.services_content._content.services_list[k].srv_id == serviceId){
          if (this.services_content._content.services_list[k].srv_class == 'active'){
            this.services_content._content.services_list[k].srv_class = "";
          }else{
            this.services_content._content.services_list[k].srv_class = "active";
          }
        }
      }
    },
    onSelectPickupDate(date){
      if (date === null){
        return false;
      }
      if (date.toString() == parseInt(date.toString())){
        alert('Pickup date is not valid');
        return false;
      }

      let _start_year = date.getFullYear(), _start_month = date.getMonth(), _start_date = date.getDate();
      let _formatted_date = _start_year + "-" + ((_start_month.toString().length < 2) ? "0" + _start_month.toString() : _start_month.toString()) + "-" + ((_start_date.toString().length < 2) ? "0" + _start_date.toString() : _start_date.toString());
      let objTimeSlot = [];
      for (let k in this.config_pickup_datepicker._date_with_time_slots){
        if (_formatted_date == this.config_pickup_datepicker._date_with_time_slots[k].date){
          objTimeSlot = this.config_pickup_datepicker._date_with_time_slots[k].time_slot;
        }
      }
      let arrTimeSlot = [];
      for (let k in objTimeSlot){
        let _slot = objTimeSlot[k].split(' - ');
        arrTimeSlot.push({start_time:_slot[0], end_time:_slot[1]});
      }
      this.config_pickup_datepicker.date_time_slot = arrTimeSlot;
      this.form_reschedule.pickup_time._disabled = false;
      this.form_reschedule.pickup_time._value = "";
      this.form_reschedule.delivery_date._value = "";
      this.form_reschedule.delivery_date._disabled = true;
      this.form_reschedule.delivery_time._value = "";
      this.form_reschedule.delivery_time._disabled = true;
    },
    onClearPickupDate(){
      this.form_reschedule.pickup_date._value = "";
      this.form_reschedule.pickup_time._value = "";
      this.form_reschedule.pickup_time._disabled = true;
      this.form_reschedule.delivery_date._value = "";
      this.form_reschedule.delivery_date._disabled = true;
      this.form_reschedule.delivery_time._value = "";
      this.form_reschedule.delivery_time._disabled = true;

    },
    onSelectPickupTime(){
      let pickupTime = this.form_reschedule.pickup_time._value;
      if (pickupTime.length < 2){
        this.form_reschedule.delivery_date._value = "";
        this.form_reschedule.delivery_time._value = "";
        this.form_reschedule.delivery_date._disabled = true;
        this.form_reschedule.delivery_time._disabled = true;
        return false;
      }
      this.form_reschedule.delivery_date._disabled = false;
      this.form_reschedule.delivery_date._value = "";
      this.form_reschedule.delivery_time._disabled = true;
      this.form_reschedule.delivery_time._value = "";
      this.setDeliveryDateTimeSchedule();
    },
    onSelectDeliveryDate(date){
      if (date === null){
        return false;
      }
      if (date.toString() == parseInt(date.toString())){
        alert('Pickup date is not valid');
        return false;
      }
      let _start_year = date.getFullYear(), _start_month = date.getMonth(), _start_date = date.getDate();
      let _formatted_date = _start_year + "-" + ((_start_month.toString().length < 2) ? "0" + _start_month.toString() : _start_month.toString()) + "-" + ((_start_date.toString().length < 2) ? "0" + _start_date.toString() : _start_date.toString());
      let objTimeSlot = [];
      for (let k in this.config_delivery_datepicker._date_with_time_slots){
        if (_formatted_date == this.config_delivery_datepicker._date_with_time_slots[k].date){
          objTimeSlot = this.config_delivery_datepicker._date_with_time_slots[k].time_slot;
        }
      }
      let arrTimeSlot = [];
      for (let k in objTimeSlot){
        let _slot = objTimeSlot[k].split(' - ');
        arrTimeSlot.push({start_time:_slot[0], end_time:_slot[1]});
      }
      this.config_delivery_datepicker.date_time_slot = arrTimeSlot;
      this.form_reschedule.delivery_time._disabled = false;
    },
    onClearDeliveryDate(){
      this.form_reschedule.delivery_date._value = "";
      this.form_reschedule.delivery_time._value = "";
      this.form_reschedule.delivery_time._disabled = true;
    },
    formValidationCheck(){
      let field_count = 0;
      if (parseInt(this.form_reschedule.order_type._value) < 1 && parseInt(this.form_reschedule.order_type._value) > 2){
        this.form_reschedule.order_type._validation_show = true;
        this.form_reschedule.order_type._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_reschedule.pickup_date._value)){
        this.form_reschedule.pickup_date._validation_show = true;
        field_count++;
      }
      if (this.form_reschedule.pickup_time._value.length < 2){
        this.form_reschedule.pickup_time._validation_show = true;
        field_count++;
      }
      if (!this.isDateValid(this.form_reschedule.delivery_date._value)){
        this.form_reschedule.delivery_date._validation_show = true;
        field_count++;
      }
      if (this.form_reschedule.delivery_time._value.length < 2){
        this.form_reschedule.delivery_time._validation_show = true;
        field_count++;
      }
      return (field_count < 1);

    },
    submitOrderReschedule(){
      this.form_reschedule._validation_show = true;
      if (this.formValidationCheck()) {
        this.form_reschedule.button_submit._visible = false;
        this.form_reschedule.button_submit._disabled = true;
        this.form_reschedule.button_loader._visible = true;
        this.form_reschedule.button_loader._disabled = true;

        let formData = new FormData();
        for(let k in this.form_reschedule){
          if (typeof this.form_reschedule[k]._value !== 'undefined'){
            //var today = new Date().toISOString().slice(0, 10);
            let data = this.form_reschedule[k]._value;
            if (k == 'pickup_date'){
              data = new Date(this.form_reschedule[k]._value).toISOString().slice(0, 10);
            }else if (k == 'delivery_date'){
              data = new Date(this.form_reschedule[k]._value).toISOString().slice(0, 10);
            }
            formData.append(k, data);
          }
        }
        for (let s in this.services_content._content.services_list){
          if (this.services_content._content.services_list[s].srv_class == 'active'){
            this.selected_service_list.push(parseInt(this.services_content._content.services_list[s].srv_id))
          }
        }
        formData.append("order_no", this.selected_order_no);
        formData.append('selected_service_list', this.selected_service_list);
        UserService.postOrderReschedule(formData).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                this.form_reschedule.alert_message._show = true;
                this.form_reschedule.alert_message._type = "alert-success";
                this.form_reschedule.alert_message._text = jsonResponse.message + '<br/>Please wait...';
                let localUser = TokenService.getUser();
                localUser.order_reschedule = jsonResponse.data.order_reschedule;
                TokenService.setUser(localUser);
                setTimeout(() => {
                  this.$router.push("/my-orders/order/" + this.selected_order_no);
                }, 1500);
              }else{
                this.form_reschedule.alert_message._show = true;
                this.form_reschedule.alert_message._type = "alert-warning";
                this.form_reschedule.alert_message._text = jsonResponse.message;
                this.form_reschedule.button_submit._visible = true;
                this.form_reschedule.button_submit._disabled = false;
                this.form_reschedule.button_loader._visible = false;
              }

            }
        );
      }

    },
    doAjax(){
      UserService.getOrderDetailById(this.selected_order_no).then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.services !== 'undefined'){
                this.services_content._content = jsonResponse.data.services;
                this.services_content._content_loaded = true;
              }
              if (typeof jsonResponse.data.pickup_charges !== 'undefined'){
                this.order_config._pickup_charges = parseFloat(jsonResponse.data.pickup_charges);
              }
              if (typeof jsonResponse.data.delivery_charges !== 'undefined'){
                this.order_config._delivery_charges = parseFloat(jsonResponse.data.delivery_charges);
              }
              if (typeof jsonResponse.data.urgent_charges !== 'undefined'){
                this.order_config._urgent_charges = parseFloat(jsonResponse.data.urgent_charges);
              }
              if (typeof jsonResponse.data.minimum_order_limit !== 'undefined'){
                this.order_config._minimum_order_limit = parseFloat(jsonResponse.data.minimum_order_limit);
              }
              if (typeof jsonResponse.data.order_schedule !== 'undefined'){
                this.order_config._schedule_days = jsonResponse.data.order_schedule;
                this.setPickupDateTimeSchedule();

              }
              if (typeof jsonResponse.data.order !== 'undefined'){
                this.selected_order_data = jsonResponse.data.order;
                this.form_reschedule.pickup_date._value = new Date(this.selected_order_data.order_pickupDate);
                this.onSelectPickupDate(new Date(this.selected_order_data.order_pickupDate));
                this.form_reschedule.pickup_time._value = this.selected_order_data.order_pickupTime;
                this.form_reschedule.pickup_time._disabled = false;
                this.setDeliveryDateTimeSchedule();
                this.form_reschedule.delivery_date._value = new Date(this.selected_order_data.order_deliveryDate);
                this.form_reschedule.delivery_date._disabled = false;

                this.onSelectDeliveryDate(new Date(this.selected_order_data.order_deliveryDate));
                if (jsonResponse.data.order.order_service_type == 'Normal'){
                  this.form_reschedule.order_type._value = 1;
                }else{
                  this.form_reschedule.order_type._value = 2;
                }
                this.form_reschedule.delivery_time._value = this.selected_order_data.order_deliveryTime;
                this.form_reschedule.order_note._value = this.selected_order_data.order_note;
                if (this.selected_order_data.order_selected_services.length){
                  for (let s in this.services_content._content.services_list){
                    for (let k in this.selected_order_data.order_selected_services){
                        if (parseInt(this.services_content._content.services_list[s].srv_id) == parseInt(this.selected_order_data.order_selected_services[k])){
                          this.services_content._content.services_list[k].srv_class = "active";
                        }
                    }
                  }
                }
              }


              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );

    },

  },
  mounted() {
    this.doAjax();
  },
  watch:{
    'form_reschedule.pickup_date._value':function(newVal, oldVal){
      if (newVal === null) {
        this.form_reschedule.pickup_date._validation_show = true;
        this.form_reschedule.pickup_date._validate = false;
      }else if(newVal === ''){
        this.form_reschedule.pickup_date._validation_show = true;
        this.form_reschedule.pickup_date._validate = false;
      }else if(!this.isDateValid(newVal)){
        this.form_reschedule.pickup_date._validation_show = true;
        this.form_reschedule.pickup_date._validate = false;
      }else if (this.isDateValid(newVal)){
        this.form_reschedule.pickup_date._validation_show = false;
        this.form_reschedule.pickup_date._validate = true;
      }
    },
    'form_reschedule.pickup_time._value':function(newVal, oldVal){
      if (newVal === ''){
        this.form_reschedule.pickup_time._validation_show = true;
        this.form_reschedule.pickup_time._validate = false;
      }else{
        this.form_reschedule.pickup_time._validation_show = false;
        this.form_reschedule.pickup_time._validate = true;
      }
    },
    'form_reschedule.delivery_date._value':function(newVal, oldVal){
      if (newVal === null) {
        this.form_reschedule.delivery_date._validation_show = true;
        this.form_reschedule.delivery_date._validate = false;
      }else if(newVal === ''){
        this.form_reschedule.delivery_date._validation_show = true;
        this.form_reschedule.delivery_date._validate = false;
      }else if(!this.isDateValid(newVal)){
        this.form_reschedule.delivery_date._validation_show = true;
        this.form_reschedule.delivery_date._validate = false;
      }else if (this.isDateValid(newVal)){
        this.form_reschedule.delivery_date._validation_show = false;
        this.form_reschedule.delivery_date._validate = true;
      }
    },
    'form_reschedule.delivery_time._value':function(newVal, oldVal){
      if (newVal === ''){
        this.form_reschedule.delivery_time._validation_show = true;
        this.form_reschedule.delivery_time._validate = false;
      }else{
        this.form_reschedule.delivery_time._validation_show = false;
        this.form_reschedule.delivery_time._validate = true;
      }
    },
  },
}
</script>

<style scoped>

</style>