<template>
  <div id="home-wrapper">
    <!--slider section start-->
    <div v-if="home_slider_content._content_loaded" class="hero-section section position-relative">
      <vue-slick-carousel v-bind="home_banner_setting" ref="vsc_home_banner">
        <!--Hero Item start-->
        <div v-for="(_banner, idx) in home_slider_content._slider_list" :key="idx">
          <div class="hero-item hero-item-three bg_image-v3" :style="{ backgroundImage: `url(${_banner.ban_image})` }">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">

                  <!--Hero Content start-->
                  <div class="hero-content-4 left">
                    <h2 class="title" v-html="_banner.ban_title[0]"></h2>
                    <h3 class="sub-title" v-html="_banner.ban_title[1]"></h3>
                    <p v-html="_banner.ban_title[2]"></p>



                  </div>
                  <!--Hero Content end-->

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Hero Item end-->
      </vue-slick-carousel>
      <div class="job-search-container">
        <div class="row">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-9 col-lg-9 col-xl-9 d-flex justify-content-start align-items-end">
                <div class="job-search-wrap-three mt-sm-30 mt-xs-30">
                  <div class="job-search-form">
                    <p class="ca" v-if="customer_selected_area._area_content_load">{{customer_selected_area._area_content.area_name}}</p>
                    <form action="">
                      <div :class="'single-field-item keyword' + ((location_search._validation_show) ? ' validation-error' : '')">
                        <label for="app_keyword">Postal Code</label>
                        <input id="app_keyword" placeholder="E.g: SW20" v-model="location_search._keyword" name="app_keyword" type="text">
                        <span v-if="location_search._loader" class="input-loader"><i class="fa fa-spinner fa-spin"></i></span>
                        <span v-if="location_search._validation_show" class="required-message">This field is required.</span>
                        <div v-if="location_search._show" ref="addressSuggestionList" class="address_list">
                          <ul>
                            <li v-for="(item, idx) in location_search._suggestion_list">
                              <a @click="setAddress(item)"><span>{{item.Text}}</span><i v-if="item.Type == 'Postcode'" class="fa fa-chevron-right"></i></a>
                            </li>
                          </ul>
                        </div>


                      </div>

                      <div class="submit-btn"><button type="button" class="ht-btn fz-18" @click="submitLocation"> Place Order </button></div>
                    </form>

                  </div>
                  <div class="order-service-note">Services available in London & Surrounding Area's</div>
                  <div class="trending-keywords mt-0 mt-xs-10 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    <div class="keywords style-three">
                      <span class="title">Trending Services</span>
                      <ul>
                        <li>Dry Cleaning</li>
                        <li>Laundry</li>
                        <li>Ironing</li>
                        <li>House Hold Textiles</li>
                        <li>Leather</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 d-none d-md-block d-lg-block d-xl-block">
                <div v-if="home_offer_slider._content_loaded" class="single-offer-slider">
                  <vue-slick-carousel v-bind="banner_offer_slider_setting" ref="vsc_banner_offer_slider">
                    <div v-for="(item, idx) in home_offer_slider._content" class="col" :key="idx">
                      <!-- Single Job Location Start -->
                      <div class="single-job-location bg-image" :style="{backgroundImage: `url(${item.off_image})`}">
                        <div class="overlay-gradient"></div>
                        <div class="location-info">

                          <a v-if="item.off_link.length < 2" href="javascript:void(0)" class="city-name" v-html="item.off_title"></a>

                          <router-link v-if="item.off_link.length > 2" class="city-name" :to="item.off_link" v-html="item.off_title"></router-link>
                          <p class="detail" v-html="item.off_detail"></p>
                          <span class="count-job"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{item.off_price}}</span>

                        </div>
                      </div>
                      <!-- Single Job Location End -->
                    </div>
                  </vue-slick-carousel>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--slider section end-->

    <!-- Offer Slider Section Start -->
    <div v-if="home_offer_slider._content_loaded" class="job-location section d-block d-md-none d-lg-none d-xl-none pt-80 pt-lg-80 pt-md-80 pt-sm-55 pt-xs-45 pb-80 pb-lg-80 pb-md-80 pb-sm-60 pb-xs-50" style="background-color:#f1f1f1;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrap mb-45">
              <div class="section-title">
                <span>On Going</span>
                <h3 class="title text-uppercase">Offers</h3>
              </div>
              <div class="jetapo-link hide" hidden>
                <router-link to="/services">Browse All Offers <i class="lnr lnr-chevron-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <vue-slick-carousel v-bind="home_offer_slider._setting" ref="vsc_d_offer_slider" v-if="home_offer_slider._content.length">
              <div v-for="(item, idx) in home_offer_slider._content" class="col" :key="idx">
                <!-- Single Job Location Start -->
                <div class="single-job-location bg-image" :style="{backgroundImage: `url(${item.off_image})`}">
                  <div class="overlay-gradient"></div>
                  <div class="location-info">

                    <a v-if="item.off_link.length < 2" href="javascript:void(0)" class="city-name" v-html="item.off_title"></a>

                    <router-link v-if="item.off_link.length > 2" class="city-name" :to="item.off_link" v-html="item.off_title"></router-link>
                    <p class="detail" v-html="item.off_detail"></p>
                    <span class="count-job"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{item.off_price}}</span>

                  </div>
                </div>
                <!-- Single Job Location End -->
              </div>
            </vue-slick-carousel>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-danger text-center mt-3">Note: Offer will apply automatically when you place order.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Offer Slider Section End -->


    <!-- See How It Works Section Start  -->
    <div v-if="see_how_it_works_content._content_loaded" class="work-section section pb-80 pb-lg-80 pb-md-60 pb-sm-40 pb-xs-30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mt-80">
            <div class="section-title-two mb-45 mb-sm-30 mb-xs-20">
              <div class="section-title">
                <h3 class="title text-uppercase" v-html="see_how_it_works_content._content.web_title"></h3>
              </div>
            </div>
            <p v-html="see_how_it_works_content._content.web_description"></p>
          </div>
        </div>

        <div v-if="see_how_it_works_content._content.web_multiples.length" class="row">

          <div v-for="(item, idx) in see_how_it_works_content._content.web_multiples" class="col-lg-4 col-md-6">
            <!-- Single Work Start  -->
            <div class="single-work">
              <div class="work-image">
                <span hidden class="step">01</span>
                <img :src="item.web_image" :alt="item.web_heading">
              </div>
              <div class="work-content">
                <h3 class="title"><a href="#" v-html="item.web_heading"></a></h3>
                <p v-html="item.web_pera"></p>
              </div>
            </div>
            <!-- Single Work End  -->
          </div>
        </div>
      </div>
    </div>
    <!-- See How It Works Section End  -->
    <!-- Services Slider Section Start -->
    <div v-if="services_slider_content._content_loaded" class="job-location section pt-80 pt-lg-80 pt-md-80 pt-sm-55 pt-xs-45 pb-80 pb-lg-80 pb-md-80 pb-sm-60 pb-xs-50" style="background-color:#f1f1f1;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrap mb-45">
              <div class="section-title">
                <span>We Clean Everything</span>
                <h3 class="title text-uppercase">Always the Best Service</h3>
              </div>
              <div class="jetapo-link">
                <router-link to="/services">Browse All Services <i class="lnr lnr-chevron-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <vue-slick-carousel v-bind="home_services_setting" ref="vsc_services" v-if="services_slider_content._slider_list.length">
              <div v-for="(item, idx) in services_slider_content._slider_list" class="col" :key="idx">
                <!-- Single Job Location Start -->
                <div class="single-job-location bg-image" :style="{backgroundImage: `url(${item.srv_image})`}">
                  <div class="overlay-gradient"></div>
                  <div class="location-info">
                    <router-link class="city-name" :to="'/service/' + item.srv_slug" v-html="item.srv_title"></router-link>
                    <span class="count-job">Pricing</span>
                  </div>
                </div>
                <!-- Single Job Location End -->
              </div>
            </vue-slick-carousel>
          </div>
        </div>
      </div>
    </div>
    <!-- Services Slider Section End -->


    <!-- Pricing Section Start -->
    <div v-if="pricing_content._content_loaded" class="team-section-start section pt-80 pt-lg-80 pt-md-80 pt-sm-20 pt-xs-45 pb-50 pb-lg-50 pb-md-50 pb-sm-55 pb-xs-45">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-two mb-45 mb-xs-30">
              <h3 class="title" v-html="pricing_content._content.web_title"></h3>
              <p v-html="pricing_content._content.web_description"></p>
            </div>
          </div>
        </div>
        <div v-if="pricing_content._content.pricing.length" class="row no-gutters">
          <div v-for="(item, idx) in pricing_content._content.pricing" class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
            <!-- Single Team Start -->
            <div class="single-team mb-30">
              <div class="team-image" style="border-radius:0;">
                <img :src="item.srv_icon" :alt="item.srv_title">
              </div>
              <div class="team-content">
                <h4 class="team-title">
                  <a href="#" v-html="item.srv_title"> </a>
                </h4>
                <p class="team-headline" v-html="item.srv_detail"></p>
                <p class="team-price">FROM <span><i v-html="app_currency_symbol"></i> </span> {{item.srv_amount}}</p>
              </div>
            </div>
            <!-- Single Team End -->
          </div>
        </div>

      </div>
    </div>
    <!-- Pricing Section End -->

    <!-- Offer Slider Section Start -->
    <div v-if="home_offer_slider._content_loaded" class="job-location section d-none d-md-block d-lg-block d-xl-block pt-80 pt-lg-80 pt-md-80 pt-sm-55 pt-xs-45 pb-80 pb-lg-80 pb-md-80 pb-sm-60 pb-xs-50" style="background-color:#f1f1f1;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrap mb-45">
              <div class="section-title">
                <span>On Going</span>
                <h3 class="title text-uppercase">Offers</h3>
              </div>
              <div class="jetapo-link hide" hidden>
                <router-link to="/services">Browse All Offers <i class="lnr lnr-chevron-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <vue-slick-carousel v-bind="home_offer_slider._setting" ref="vsc_offer_slider" v-if="home_offer_slider._content.length">
              <div v-for="(item, idx) in home_offer_slider._content" class="col" :key="idx">
                <!-- Single Job Location Start -->
                <div class="single-job-location bg-image" :style="{backgroundImage: `url(${item.off_image})`}">
                  <div class="overlay-gradient"></div>
                  <div class="location-info">

                    <a v-if="item.off_link.length < 2" href="javascript:void(0)" class="city-name" v-html="item.off_title"></a>

                    <router-link v-if="item.off_link.length > 2" class="city-name" :to="item.off_link" v-html="item.off_title"></router-link>
                    <p class="detail" v-html="item.off_detail"></p>
                    <span class="count-job"><i class="price-symbol pr-5" v-html="app_currency_symbol"></i>{{item.off_price}}</span>

                  </div>
                </div>
                <!-- Single Job Location End -->
              </div>
            </vue-slick-carousel>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-danger text-center mt-3">Note: Offer will apply automatically when you place order.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Offer Slider Section End -->

    <div v-if="customer_selected_area._area_content_load" class="area-section section pt-80 pt-lg-80 pt-md-80 pt-sm-20 pt-xs-45 pb-50 pb-lg-50 pb-md-50 pb-sm-55 pb-xs-45">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-2">
            <div class="section-title-one mb-45 mb-xs-30"><h1 v-html="customer_selected_area._area_content.area_name + ' Dry Cleaning & Laundry Services'"></h1></div>
          </div>
          <div v-if="customer_selected_area._area_content.page_content.length" v-for="(item, idx) in customer_selected_area._area_content.page_content" class="col-xl-8 col-lg-8 col-md-8 col-sm-8 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-2">
            <div v-if="item.arc_heading != ''" class="section-title-two mt-45 mb-30 mb-xs-30"><h3 v-html="item.arc_heading"></h3></div>
            <p v-html="item.arc_detail"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="corporate-clients section pt-80 pt-lg-80 pt-md-80 pt-sm-55 pt-xs-45 pb-80 pb-lg-80 pb-md-80 pb-sm-60 pb-xs-50">
      <div class="container">
        <div class="row">

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 offset-xl-2 offset-lg-2 offset-md-1">
            <div class="d-flex justify-content-center align-items-center">



              <div class="client-heading pl-2 pr-4 text-nowrap">
                <div class="section-title-wrap">
                  <div class="section-title">
                    <span>Corporate </span>
                    <h3 class="title text-uppercase">Clients</h3>
                  </div>
                </div>
              </div>

              <div class="client-logo-one pl-2 pr-2">
                <img src="../assets/img/TalkTV_logo.png" alt="" class="img-fluid">
              </div>
              <div class="client-logo-two pl-2 pr-2">
                <img src="../assets/img/Launcelot-logo.png" alt="" class="img-fluid">
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="reviews-section section pt-80 pt-lg-80 pt-md-80 pt-sm-20 pt-xs-45 pb-50 pb-lg-50 pb-md-50 pb-sm-55 pb-xs-45">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="elfsight-app-11b3a667-ccc9-4d55-859e-a715870e071d"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import UserService from "@/services/user.service";
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import TokenService from "@/services/token.service";
import User from "@/models/user";
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    VueSlickCarousel,
  },
  data(){
    return{
      locate_api_key: process.env.VUE_APP_LOQATE_API_KEY,
      location_search:{
        _keyword:"",
        _id:"",
        _text:"",
        _description:"",
        _suggestion_list:[],
        _show:false,
        _selected:"",
        _validation_show:false,
        _loader:false,
      },
      home_banner_setting:{
        "arrows": false,
        "dots": false,
        "infinite": true,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "speed": 2000,
        "autoplaySpeed": 4000,
        "fade": true,
      },
      home_services_setting:{
        "arrows": true,
        "dots": false,
        "infinite": true,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "speed": 2000,
        "responsive":[
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": false
            }
          },
          {
            "breakpoint": 960,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ],
      },
      home_slider_content:{
        _content_loaded: false,
        _slider_list:[],
      },
      see_how_it_works_content:{
        _content_loaded: false,
        _content:{web_multiples:[]}
      },
      services_slider_content:{
        _content_loaded:false,
        _slider_list:[],
      },
      pricing_content:{
        _content_loaded:false,
        _content: {pricing:[]}
      },
      selected_area:"",
      customer_selected_area:{
        _area_slug:"",
        _area_content:{page_content:[]},
        _area_content_load:false
      },
      web_page_head:{
        page_title:"Digital Dry Cleaners",
        meta_title:"Digital Dry Cleaners",
        meta_description:"Digital Dry Cleaners"
      },
      home_offer_slider:{
        _content_loaded:false,
        _content:[],
        _setting:{
          "arrows": true,
          "dots": false,
          "infinite": true,
          "slidesToShow": 4,
          "slidesToScroll": 1,
          "speed": 2000,
          "responsive":[
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "infinite": true,
                "dots": false
              }
            },
            {
              "breakpoint": 960,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 1,
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "initialSlide": 2
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
              }
            }
          ],
        }
      },
      banner_offer_slider_setting:{
        "dots": true,
        "arrows": false,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_title },
        { name: 'title', content: this.web_page_head.meta_description }
      ]
    },
  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (Object.keys(localUser).length){
        if (Object.keys(localUser.order).length && Object.keys(localUser.location)){
          let userLocation = localUser.location;
          this.location_search._text = userLocation.Text;
          this.location_search._id = userLocation.Id;
          this.location_search._description = userLocation.Description;
          this.location_search._show = false;
          this.location_search._selected = userLocation.Text;
          this.location_search._keyword = userLocation.Text;
        }
      }
    }
    if (typeof this.$route.params.areaSlug !== 'undefined' ){
      this.selected_area = this.$route.params.areaSlug;
    }
    document.addEventListener('click', this.documentClick);
  },
  methods:{
    documentClick(e){
      if (this.location_search._show){
        this.location_search._show = false;
      }
    },
    doAjax(){
      UserService.getHomeContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.main_banners !== 'undefined'){
                this.home_slider_content._slider_list = jsonResponse.data.main_banners;
                this.home_slider_content._content_loaded = true;
              }
              if (typeof jsonResponse.data.shiw_content !== 'undefined'){
                this.see_how_it_works_content._content_loaded = true;
                this.see_how_it_works_content._content = jsonResponse.data.shiw_content;
              }
              if (typeof jsonResponse.data.services !== 'undefined'){
                this.services_slider_content._content_loaded = true;
                this.services_slider_content._slider_list = jsonResponse.data.services;
              }
              if (typeof jsonResponse.data.pricing_content !== 'undefined'){
                this.pricing_content._content_loaded = true;
                this.pricing_content._content = jsonResponse.data.pricing_content;
              }
              if (typeof jsonResponse.data.offer_slider !== 'undefined'){
                this.home_offer_slider._content_loaded = true;
                this.home_offer_slider._content = jsonResponse.data.offer_slider;
              }
              if (typeof jsonResponse.data.webpage_content.webpage_title !== 'undefined' && !this.selected_area.length){
                this.web_page_head.page_title = jsonResponse.data.webpage_content.webpage_title;
                if (typeof jsonResponse.data.webpage_content.meta_title !== 'undefined'){
                  this.web_page_head.meta_title = jsonResponse.data.webpage_content.meta_title;
                }
                if (typeof jsonResponse.data.webpage_content.meta_description !== 'undefined'){
                  this.web_page_head.meta_title = jsonResponse.data.webpage_content.meta_description;
                }
                this.$emit('updateHead');
              }
            }
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
      if (this.selected_area.length){
        UserService.getAreaContentByAreaSlug(this.selected_area).then(
            (response) => {
              let jsonResponse = response.data;
              if (jsonResponse.status){
                if (typeof jsonResponse.data.area_content !== 'undefined'){
                  this.customer_selected_area._area_content_load = true;
                  this.customer_selected_area._area_content = jsonResponse.data.area_content;
                  this.customer_selected_area._area_slug = this.selected_area;
                  this.web_page_head.meta_title = this.customer_selected_area._area_content.area_meta_title;
                  this.web_page_head.meta_description = this.customer_selected_area._area_content.area_meta_description;
                  this.$emit('updateHead');
                }
              }
            },
            (error) => {

            }
        );
      }

    },
    async getSuggestionList(keyword){
      this.location_search._loader = true;
      const url = 'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key='+this.locate_api_key+'&Text='+keyword+'&Countries=GB&Origin=52.182,-2.222&Language=en';
      await axios.get(url).then(
          (response) => {
            let jsonResponse = response.data;
            if (typeof jsonResponse.Items[0].Error !== 'undefined' ){
              alert(jsonResponse.Items[0].Error + ' ' + jsonResponse.Items[0].Description);
              this.location_search._loader = false;
            }else{
                this.location_search._suggestion_list = jsonResponse.Items;
                this.location_search._show = true;
              this.location_search._loader = false;
            }

          },
          (error) => {
            console.log('Error');
            console.log(error);
          },
          ).catch(error =>{
            console.log('Catch');
            console.log(error);
      });



    },
    async getSuggestionDrillDown(postCode, Id){
      this.location_search._loader = true;
      const url = 'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key='+this.locate_api_key+'&Text='+postCode+'&Container='+Id+'&Countries=GB&Language=en';
      await axios.get(url).then(
          (response) => {
            let jsonResponse = response.data;
            if (typeof jsonResponse.Items[0].Error !== 'undefined' ){
              alert(jsonResponse.Items[0].Error + ' ' + jsonResponse.Items[0].Description);
            }else{
              this.location_search._suggestion_list = jsonResponse.Items;
              this.location_search._show = true;
              this.location_search._loader = false;
            }

          },
          (error) => {
            console.log('Error');
            console.log(error);
          },
      ).catch(error =>{
        console.log('Catch');
        console.log(error);
      });



    },
    setAddress(item){
      if (item.Type == 'Postcode'){
        this.getSuggestionDrillDown(item.Type, item.Id);
      }else{
        this.location_search._selected = item.Text;
        this.location_search._keyword = item.Text;
        TokenService.updateUserLocation(item);
        this.location_search._show = false;
        this.location_search._suggestion_list = [];
      }
    },
    submitLocation(){
      if (this.location_search._selected.length < 3){
        this.location_search._validation_show = true;
        return false;
      }
      this.$router.push('/order-book');

    },
  },
  mounted() {

    this.doAjax();



    setTimeout(()=>{
      let tpScript = document.createElement("script");
      let jsCodeLine1 = document.createTextNode("document.addEventListener('DOMContentLoaded', function() {");
      let jsCodeLine2 = document.createTextNode("const trustpilot_invitation = { recipientEmail: 'john@gmail.com', recipientName: 'John', referenceId: 'Order_123', source: 'InvitationScript', };");
      let jsCodeLine3 = document.createTextNode("tp('createInvitation', trustpilot_invitation); });");
      tpScript.appendChild(jsCodeLine1);
      tpScript.appendChild(jsCodeLine2);
      tpScript.appendChild(jsCodeLine3);
      //<div class="elfsight-app-11b3a667-ccc9-4d55-859e-a715870e071d"></div>
      //document.getElementById("reviews").appendChild(tpScript);
    }, 1500);




  },
  watch:{
    'location_search._keyword':function(newVal, oldVal){
      if (newVal != "" && newVal.length > 2 && (this.location_search._selected != newVal)){
        this.location_search._validation_show = false;
        this.getSuggestionList(newVal);
      }
    }
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  }
}
</script>
