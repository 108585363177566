<template>
  <div class="privacy-policy">
    <div v-if="page_banner.banner_loaded" class="breadcrumb-section section page-heading" :style="{backgroundImage: `url(${page_banner.banner_image})`}">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 ml-auto mr-auto">
            <div class="page-breadcrumb-content color-white text-center">
              <h1 class="text-uppercase" v-html="privacy_policy_content._content.web_title"></h1>
              <ul class="page-breadcrumb color-white justify-content-center">
                <li><router-link to="/" class="router-link-active">Home</router-link></li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="privacy_policy_content._content_loaded" class="about-content-section section pt-55 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-content">
              <p class="text-bold"></p>
              <p class="normal-text pb-15 text-center" style="border: 0px none;" v-html="privacy_policy_content._content.web_description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="privacy_policy_content._content.content_multiple.length" class="company-history-section section pt-0 pt-lg-0 pt-md-0 pt-sm-0 pt-xs-0 pb-60 pb-lg-60 pb-md-60 pb-sm-60 pb-xs-60">
      <div class="container">
        <div class="row">
          <div v-for="(item, idx) in privacy_policy_content._content.content_multiple" class="col-12">
            <div class="section-title-four mb-40">
              <h4 class="title" v-html="item.web_heading">Laundry</h4>
              <p style="white-space: pre-wrap;" v-html="item.web_pera"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "PrivacyPolicy",
  data(){
    return{
      page_banner:{
        banner_loaded:false,
        banner_image:"",
        banner_color:"",
      },
      privacy_policy_content:{
        _content_loaded: false,
        _content:{content_multiple:[]},
      },
      web_page_head:{
        page_title:"Privacy Policy",
        meta_title:"Privacy Policy",
        meta_description:"Privacy Policy"
      },
    }
  },
  head:{
    title: function () {
      return {
        inner: this.web_page_head.page_title
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.web_page_head.meta_description },
        { name: 'title', content: this.web_page_head.meta_title}
      ]
    },
  },
  methods:{
    doAjax(){
      UserService.getPrivacyPolicyContent().then(
          (response) => {
            let jsonResponse = response.data;
            if (jsonResponse.status){
              if (typeof jsonResponse.data.page_banner !== 'undefined'){
                this.page_banner.banner_loaded = true;
                this.page_banner.banner_image = jsonResponse.data.page_banner.banner_image;
                this.page_banner.banner_color = jsonResponse.data.page_banner.banner_color;
                if (typeof jsonResponse.data.page_banner.webpage_title !== 'undefined'){
                  this.web_page_head.page_title = jsonResponse.data.page_banner.webpage_title;
                  if (typeof jsonResponse.data.page_banner.meta_title !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_title;
                  }
                  if (typeof jsonResponse.data.page_banner.meta_description !== 'undefined'){
                    this.web_page_head.meta_title = jsonResponse.data.page_banner.meta_description;
                  }
                  this.$emit('updateHead');
                }
              }
              if (typeof jsonResponse.data.privacy_policy_content !== 'undefined'){
                this.privacy_policy_content._content = jsonResponse.data.privacy_policy_content;
                this.privacy_policy_content._content_loaded = true;
              }
              setTimeout(() => {
                this.$parent.app_page_loader = false;
              }, process.env.VUE_APP_LOADER_HIDE_DELAY);
            }
          },
          (error) => {
            setTimeout(() => {
              this.$parent.app_page_loader = false;
            }, process.env.VUE_APP_LOADER_HIDE_DELAY);
          }
      );
    },
  },
  mounted() {
    this.doAjax();
  }
}
</script>

<style scoped>

</style>