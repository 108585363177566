<template>
  <div>
    <div v-if="currentRouteName != 'home'" class="corporate-clients section pt-80 pt-lg-80 pt-md-80 pt-sm-55 pt-xs-45 pb-80 pb-lg-80 pb-md-80 pb-sm-60 pb-xs-50">
      <div class="container">
        <div class="row">

          <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 offset-xl-2 offset-lg-2 offset-md-1">
            <div class="d-flex justify-content-center align-items-center">
              <div class="client-heading pl-2 pr-4 text-nowrap">
                <div class="section-title-wrap">
                  <div class="section-title">
                    <span>Corporate </span>
                    <h3 class="title text-uppercase">Clients</h3>
                  </div>
                </div>
              </div>

              <div class="client-logo-one pl-2 pr-2">
                <img src="../assets/img/TalkTV_logo.png" alt="" class="img-fluid">
              </div>
              <div class="client-logo-two pl-2 pr-2">
                <img src="../assets/img/Launcelot-logo.png" alt="" class="img-fluid">
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!--Footer section start-->
    <footer class="footer-section section bg-image-proparty bg_image--2">
      <!-- Footer Top Section Start -->
      <div class="footer-top-section section pb-80 pb-lg-60 pb-md-40 pb-sm-20 pb-xs-15">
        <div class="container st-border pt-115 pt-lg-95 pt-md-75 pt-sm-55 pt-xs-45">
          <div class="row">

            <div class="col-xl-4 col-lg-3 col-md-6">
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-30">
                <h6 class="title">Contact Info</h6>
                <div class="address">
                  <i class="lnr lnr-map-marker"></i>
                  <span v-html="footer_web_settings.address"></span>
                </div>
                <div v-if="footer_web_settings.email != ''" class="email">
                  <i class="lnr lnr-envelope"></i>
                  <span><a :href="'mailto:' + footer_web_settings.email" v-html="footer_web_settings.email"></a> </span>
                </div>
                <div class="phone">
                  <i class="lnr lnr-phone"></i>

                  <span><a :href="'tel:' + footer_web_settings.phone" v-html="footer_web_settings.phone"></a></span>

                </div>
                <div v-if="footer_web_settings.mobile != ''" class="phone">
                  <i class="lnr lnr-smartphone"></i>
                  <span><a :href="'tel:' + footer_web_settings.mobile" v-html="footer_web_settings.mobile"></a></span>
                </div>
                <div v-if="footer_social_link.length" class="footer-social justify-content-start mt-35">
                  <a v-for="(sl) in footer_social_link" :href="sl.link" target="_blank"><i :class="sl.icon"></i></a>
                </div>
              </div>
              <!-- Footer Widget End -->
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-30">
                <a href="https://find-and-update.company-information.service.gov.uk/company/13029154" target="_blank">
                  <img src="../assets/img/CompanyNo.jpg" :alt="footer_web_settings.company_name">
                </a>
              </div>
              <!-- Footer Widget End -->
            </div>
            <div class="col-xl-2 col-lg-2 col-md-6">
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-30">
                <h6 class="title">Company</h6>
                <div class="footer-widget-link">
                  <ul>
                    <li><router-link to="/">Order</router-link></li>
                    <li><router-link to="/about-us">About</router-link></li>
                    <li><router-link to="/services">Services</router-link></li>
                    <li><router-link to="/pricing">Pricing</router-link></li>
                    <li><router-link to="/areas">Areas</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/disclaimer">Disclaimer</router-link></li>
                    <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                  </ul>
                </div>
              </div>
              <!-- Footer Widget End -->
            </div>

            <div class="col-xl-2 col-lg-3 col-md-6">
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-30">
                <h6 class="title">My Account</h6>
                <div class="footer-widget-link">
                  <ul v-if="!isCustomerLoggedIn">
                    <li><router-link to="/login">Login</router-link></li>
                  </ul>
                  <ul v-if="isCustomerLoggedIn">
                    <li><router-link to="/profile">Profile</router-link></li>
                    <li><router-link to="/my-orders">Orders</router-link></li>
                    <li><a href="#" @click="footerLogout">Logout</a></li>
                  </ul>
                </div>
              </div>
              <!-- Footer Widget End -->
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6">
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-30">
                <h6 class="title">Newsletter</h6>
                <div class="newsletter">
                  <p>Join our email subscription now to get updates and <strong>notifications</strong>.</p>
                  <div class="newsletter-form">
                    <form id="mc-form" :class="'mc-form' + ((form_newsletter._validation_show) ? ' validation-error' : '')">
                      <input type="email" v-model="form_newsletter.email_address._value" placeholder="Enter Your email..." required="" name="EMAIL">
                      <button v-if="form_newsletter.button_submit._visible && !form_newsletter.button_loader._visible"
                              :disabled="form_newsletter.button_submit._disabled"
                              :class="'ht-btn small-btn' + ((form_newsletter.button_submit._disabled) ? ' disabled' : '')" type="button"
                              v-html="form_newsletter.button_submit._text" @click="submit_form_newsletter"></button>
                      <button v-if="form_newsletter.button_loader._visible && !form_newsletter.button_submit._visible"
                              :disabled="form_newsletter.button_loader._disabled"
                              :class="'ht-btn small-btn' + ((form_newsletter.button_loader._disabled) ? ' disabled' : '')"
                              type="button" v-html="form_newsletter.button_loader._text"></button>
                    </form>
                    <span class="required-message" v-if="form_newsletter.email_address._message._show" v-html="form_newsletter.email_address._message._text"></span>
                    <div role="alert" v-if="form_newsletter.alert_message._show" :class="'alert col-md-12 mt-10 ' + form_newsletter.alert_message._type" v-html="form_newsletter.alert_message._text"></div>
                  </div>
                  <!-- mailchimp-alerts Start -->
                  <div class="mailchimp-alerts">
                    <div class="mailchimp-submitting"></div><!-- mailchimp-submitting end -->
                    <div class="mailchimp-success"></div><!-- mailchimp-success end -->
                    <div class="mailchimp-error"></div><!-- mailchimp-error end -->
                  </div>
                  <!-- mailchimp-alerts end -->
                </div>
              </div>
              <!-- Footer Widget End -->
              <!-- Footer Widget Start -->
              <div class="footer-widget mb-10">
                <div class="row">
                  <div class="col-8">
                    <img src="../assets/img/powered_by_stripe.png" alt="Stripe">
                  </div>
                  <div class="col-4">
                    <img src="../assets/img/ICO-registered.png" alt="Registered">
                  </div>
                </div>
              </div>
              <!-- Footer Widget End -->
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Top Section End -->

      <!--Footer bottom start-->
      <div class="footer-bottom section fb-60">
        <div class="container">
          <div class="row no-gutters st-border pt-35 pb-35 align-items-center justify-content-between">
            <div class="col-lg-1 col-md-12 col-sm-12 text-center">
              <router-link to="/sitemap">Sitemap</router-link>
            </div>
            <div class="col-lg-11 col-md-12 col-sm-12">
              <div class="copyright text-right">
                <p>&copy; {{new Date().getFullYear()}} <a href="/">{{footer_web_settings.company_name}}</a>. All rights reserved.Designed & Developed By: <a href="https://vividvisionz.net" target="_blank">Vivid Visionz</a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Footer bottom end-->
    </footer>
    <!--Footer section end-->

    <!-- Modal Area Strat -->
    <div class="modal fade quick-view-modal-container" id="quick-view-modal-container" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-xl-12 col-lg-12">
              <div class="row no-gutters">

                <div class="col-lg-4">
                  <div class="login-register-form-area">
                    <div class="login-tab-menu">
                      <ul class="nav">
                        <li><a class="active show" data-toggle="tab" href="#login">Login</a></li>
                        <li><a data-toggle="tab" href="#register">Register</a></li>
                      </ul>
                    </div>
                    <div class="tab-content">
                      <div id="login" class="tab-pane fade show active">
                        <div class="login-register-form">
                          <form action="#" method="post">
                            <p>Login to Jotopa with your registered account</p>
                            <div class="row">
                              <div class="col-12">
                                <div class="single-input">
                                  <input type="text" placeholder="Username or Email" name="name">
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="single-input">
                                  <input type="password" placeholder="Password" name="password">
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="checkbox-input">
                                  <input type="checkbox" name="login-form-remember" id="login-form-remember">
                                  <label for="login-form-remember">Remember me</label>
                                </div>
                              </div>
                              <div class="col-12 mb-25"><button class="ht-btn">Login</button></div>
                            </div>
                          </form>
                          <div class="divider">
                            <span class="line"></span>
                            <span class="circle">or login with</span>
                          </div>
                          <div class="social-login">
                            <ul class="social-icon">
                              <li><a class="facebook" href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a class="twitter" href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a class="linkedin" href="#"><i class="fab fa-linkedin"></i></a></li>
                              <li><a class="google" href="#"><i class="fab fa-google-plus"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div id="register" class="tab-pane fade">
                        <div class="login-register-form">
                          <form action="#" method="post">
                            <p>Create Your account</p>
                            <div class="row row-5">
                              <div class="col-12">
                                <div class="single-input">
                                  <input type="text" placeholder="Your Username" name="name">
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="single-input">
                                  <input type="email" placeholder="Your Email Address" name="emain">
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="single-input">
                                  <input type="password" placeholder="Password" name="password">
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="single-input">
                                  <input type="password" placeholder="Confirm Password" name="conPassword">
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="checkbox-input">
                                  <input type="checkbox" name="login-form-candidate" id="login-form-candidate">
                                  <label for="login-form-candidate">I am a candidate</label>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="checkbox-input">
                                  <input type="checkbox" name="login-form-employer" id="login-form-employer">
                                  <label for="login-form-employer">I am a employer</label>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="register-account">
                                  <input id="register-terms-conditions" type="checkbox" class="checkbox" checked="" required="">
                                  <label for="register-terms-conditions">I read and agree to the <a href="#">Terms &amp; Conditions</a> and <a href="#">Privacy Policy</a></label>
                                </div>
                              </div>
                              <div class="col-12 mb-25"><button class="ht-btn">Register</button></div>
                            </div>
                          </form>
                          <div class="divider">
                            <span class="line"></span>
                            <span class="circle">or login with</span>
                          </div>
                          <div class="social-login">
                            <ul class="social-icon">
                              <li><a class="facebook" href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a class="twitter" href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a class="linkedin" href="#"><i class="fab fa-linkedin"></i></a></li>
                              <li><a class="google" href="#"><i class="fab fa-google-plus"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="login-instruction">
                    <div class="login-instruction-content">
                      <h3 class="title">Why Login To Us</h3>
                      <p>It’s important for you to have an account and login in order to have full access at Jotopa. We need to know your account details in order to allow work together</p>
                      <ul class="list-reasons">
                        <li class="reason">Be alerted to the latest jobs</li>
                        <li class="reason">Apply for jobs with a single click</li>
                        <li class="reason">Showcase your CV to thousands of employers</li>
                        <li class="reason">Keep a record of all your applications</li>
                      </ul>
                      <span class="sale-text theme-color border-color">Login today &amp; Get 15% Off Coupon for the first planning purchase</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Modal Area End -->
    <whats-app v-if="footer_web_settings.mobile !=''" :mobile-no="footer_web_settings.mobile"></whats-app>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import WhatsApp from "@/components/WhatsApp";
import TokenService from "@/services/token.service";
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};
export default {
  name: "Footer",
  props:{
    footer_web_settings:Object,
    footer_social_link:Array
  },
  components:{
    WhatsApp
  },
  data(){
    return{
      form_newsletter:{
        email_address:{_value:"", _validate:false, _message:{_text:"This field is required", _show:false}},
        button_submit:{
          _text:"Subscribe", _visible:true, _disabled:false,
        },
        button_loader:{
          _text:'<i class="fa fa-spinner fa-spin"></i> Please wait...', _visible:false, _disabled:true,
        },
        alert_message:{_type:"", _text:"", _show:false},
        _validation_show:false,
      },
      isCustomerLoggedIn:false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  created() {
    if (this.loggedIn) {
      let localUser = TokenService.getUser();
      if (parseInt(localUser.user_id) > 0 && Object.keys(localUser.user).length){
        this.isCustomerLoggedIn = true;
      }
    }
  },
  methods:{
    submit_form_newsletter(){
      if (this.form_newsletter.email_address._value.trim().length < 3){
        this.form_newsletter._validation_show = true;
        this.form_newsletter.email_address._message._show = true;
        this.form_newsletter.email_address._message._text = "This field is required.";
        return false;
      }
      if (!EMAIL_REGEX.test(this.form_newsletter.email_address._value)){
        this.form_newsletter._validation_show = true;
        this.form_newsletter.email_address._message._show = true;
        this.form_newsletter.email_address._message._text = "Please enter valid email address.";
        return false;
      }
      this.form_newsletter._validation_show = false;
      this.form_newsletter.email_address._message._show = false;
      this.form_newsletter.button_submit._visible = false;
      this.form_newsletter.button_loader._visible = true;
      let formData = new FormData();
      formData.append("email_address", this.form_newsletter.email_address._value);
      UserService.postSubscriber(formData).then(
          (response) => {
            this.form_newsletter.alert_message._type = "alert-warning";
            this.form_newsletter.alert_message._show = true;
            if (response.status){
              this.form_newsletter.email_address._value = "";
              this.form_newsletter.alert_message._type = "alert-success";
              setTimeout(()=>{
                this.form_newsletter.alert_message._show = false;
              }, 3000);
            }
            this.form_newsletter.alert_message._text = response.message;
            this.form_newsletter.button_submit._visible = true;
            this.form_newsletter.button_loader._visible = false;
          }
      );

    },
    footerLogout(){
      this.$store.dispatch('auth/logout');
      window.location.href = "/";
    }
  },
}
</script>

<style scoped>

</style>