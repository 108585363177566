import api from "@/services/api";

class UserService {
    getWebsiteInformation(){
        return api.get("/website-config");
    }
    getHomeContent(){
        return api.get('/home');
    }
    getAboutContent(){
        return api.get('/about-us');
    }
    getServicesContent(){
        return api.get('/services');
    }
    getServiceContentBySlug(slug){
        return api.get('/services/' + slug);
    }
    getPricingContent(){
        return api.get('/pricing');
    }
    postSubscriber(postData){
        return api.post('subscribe', postData);
    }
    getContactUsContent(){
        return api.get('/contact')
    }
    getAllAreas(){
        return api.get('/all-areas');
    }
    getAreaContentByAreaSlug(area_slug){
        return api.get('/area/' + area_slug);
    }
    getOrderBook(){
        return api.get('/place-order');
    }
    getDisclaimerContent(){
        return api.get('/disclaimer');
    }
    getPrivacyPolicyContent(){
        return api.get('/privacy-policy');
    }
    postOrderBookWithRegistration(postData){
        return api.post('/book-order', postData);
    }
    postCouponVerify(postData){
        return api.post("/apply-coupon", postData);
    }
    getMyAccount(){
        return api.get('/my-account');
    }
    getMyOrders(){
        return api.get('/my-orders');
    }
    getOrderDetailById(orderId){
        return api.get('/user-order-detail/' + orderId);
    }
    postOrderCancelByOrderId(orderId){
        return api.post('/user-order-cancel/' + orderId);
    }
    postOrderReschedule(postData){
        return api.post("/user-order-reschedule/", postData);
    }
    getLoginContent(){
        return api.get("/login");
    }
    postCustomerLogin(postData){
        return api.post("/user-login", postData);
    }
    postForgotPassword(postData){
        return api.post("/forgot-password", postData);
    }
    postResetPassword(postData){
        return api.post("/change-password", postData);
    }
    postCustomerProfileUpdate(postData){
        return api.post("/user-profile-update", postData);
    }
    postCustomerPasswordUpdate(postData){
        return api.post("/user-password-update", postData);
    }
    postCustomerStripeCardUpdate(postData){
        return api.post("/user-card-update", postData);
    }
    postCustomerOrderBook(postData){
        return api.post('/user-book-order', postData);
    }
    getBlogContent(){
        return api.get('/blog');
    }
    getBlogByCategorySlug(categorySlug){
        return api.get('/blog-by-category/' + categorySlug);
    }
    getBlogByArchive(slug){
        return api.get('/blog-by-archive/' + slug);
    }
    getBlogDetailBySlug(blogSlug){
        return api.get('/blog-detail/' + blogSlug);
    }
    getSitemap(){
        return api.get('/sitemap');
    }
}

export default new UserService();