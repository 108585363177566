<template>
<div class="offer-banner">
  <div class="container pl-15 pl-md-0 pr-15 pr-md-0">
    <div class="d-flex justify-content-center">
      <h2 v-html="OfferBannerData.popban_title"></h2>
      <a :href="OfferBannerData.popban_url" v-if="isShowButton(OfferBannerData.popban_button, OfferBannerData.popban_url)" class="button" v-html="OfferBannerData.popban_button"></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "OfferBanner",
  props: {
    OfferBannerData: Object,
  },
  methods:{
    isShowButton(str, url){
      return ((str.trim().length && url.trim().length) )
    }
  }
}
</script>

<style scoped>

</style>