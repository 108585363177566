<template>
  <div>
    <!--single input-->
    <div class="single-input" ref="stripe_card_element">
      <label>CREDIT OR DEBIT CARD <span class="required">*</span><br/>
        <small>(We won’t charge you until after delivery)</small></label>
      <div class="credit-card-box">
        <div class="input-card-number">
          <label>Card Number</label>
          <div id="card-number"></div>
        </div>
        <div class="input-card-expiry">
          <label>Card Expiry</label>
          <div id="card-expiry"></div>
        </div>
        <div class="input-card-cvc">
          <label>Card CVC</label>
          <div id="card-cvc"></div>
        </div>
        <div ref="sc_error" id="card-error"></div>
      </div>
    </div>
    <button ref="StripeCardTokenGenerate" style="visibility: hidden;" id="custom-button" @click="createToken">Generate Token</button>
    <div hidden v-if="stripe_response.status" class="pt-80 pb-80 border">
      <pre>{{stripe_response.token}}</pre>
    </div>
    <!--single input-->

  </div>
</template>

<script>
import Vue from "vue";
import { StripePlugin } from '@vue-stripe/vue-stripe';
import EventBus from "@/common/EventBus";
const optionsNew = { pk: process.env.VUE_APP_STRIPE_KEY,  apiVersion: process.env.VUE_APP_STRIPE_VER};
Vue.use(StripePlugin, optionsNew);
export default {
  name: "StripeCardElements",
  props:{
    reset_all:Boolean,
  },
  data () {

    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      payment_type:0,
      isTokenGet:false,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripe_response:{status:false, token:{}, message:""},
    };
  },
  created() {
    //StripeCheckout.props.pk = process.env.VUE_APP_STRIPE_KEY;
    //this.$stripe.pk = process.env.VUE_APP_STRIPE_KEY;
  },
  methods: {
    getStripeTokenResponse: async function(){
      const {token, error} = await this.$stripe.createToken(this.cardNumber);
      if (error){
        document.getElementById('card-error').innerHTML = error.message;
        return null;
      }
      document.getElementById('card-error').innerHTML = '';
      return token;
    },
    async createToken () {
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message;
        this.stripe_response.message = error.message;
        return this.stripe_response;
      }
      console.log(token);
      this.isTokenGet = true;
      this.stripe_response.status = true;
      this.stripe_response.token = token;
      this.token = token;

      return this.stripe_response;

    },


  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
  },
  mounted() {
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
}
</script>

<style scoped>
.input-card-number{
  display: inline-block;
  width: calc(100% - 180px);
}
#card-number{
  padding: 10px 13px 10px 10px;
  border: 1px solid #d1d3da!important;
  border-radius: 3px;
  margin-right: 15px;
}
#card-expiry{
  padding: 10px 13px 10px 10px;
  border: 1px solid #d1d3da!important;
  border-radius: 3px;
  margin-right: 15px;
}
#card-cvc{
  padding: 10px 13px 10px 10px;
  border: 1px solid #d1d3da!important;
  border-radius: 3px;
}
.input-card-expiry{
  display: inline-block;
  width: 100px;
}
.input-card-cvc{
  display: inline-block;
  width: 80px;
}
</style>